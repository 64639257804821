import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import Config from '../../../../config'
import FliptButton from '../../../../components/form/fliptButton'
import FliptGrid from '../../../../components/fliptGrid'
import { Creators as NavigationActions } from '../../../../redux/reducers/navigation'
import { Creators as RoleManagementActions } from '../../../../redux/reducers/roleManagement'
import { LinkContainerRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { convertDateTimeToDate, filterRowData } from '../../../../utils/utilities'

class RoleManagement extends PureComponent {
  componentDidMount() {
    const { actions } = this.props
    setTimeout(() => actions.getRoleManagementRoles(), Config.COMPONENT_DATA_LOAD_TIME)
  }

  _dataCleanup = () => {
    const { state: { data, user, user: { newHierarchyAccess } } } = this.props

    if (!newHierarchyAccess) return []
    const accessArray = Object.keys(newHierarchyAccess)

    return data.reduce((acc, curr) => {
      if (accessArray.indexOf(curr.organization) == -1) return acc

      acc.push({
        created_by: curr.created_by,
        description: curr.description,
        effective_end_date: curr.effective_end_date === '' ? '---' : convertDateTimeToDate(curr.effective_end_date),
        effective_start_date: convertDateTimeToDate(curr.effective_start_date),
        id: curr.id,
        name: curr.name,
        organization: curr.organization,
        status: curr.active ? 'Active' : 'Inactive',
      })
      return acc
    }, [])
  }

  render() {
    let gridData = this._dataCleanup()
    const headers = [
      'Action',
      'Role Name',
      'Description',
      'Created By',
      'Effective Start Date',
      'Effective End Date',
      'Status',
      'id',
    ]
    const headerMapping = {
      name: 'Role Name',
      description: 'Description',
      created_by: 'Created By',
      effective_start_date: 'Effective Start Date',
      effective_end_date: 'Effective End Date',
      status: 'Status',
    }
    gridData = gridData && gridData.length ? gridData.map((d) => ({
      ...filterRowData(d, headers, headerMapping),
    })) : []
    const cellRendererParams = {
      Action: {
        // TODO LinkContainerRenderer does not work with state
        cellRenderer: LinkContainerRenderer,
        searchArgs: [
          {
            path: '/role-management-edit',
            searchArgs: { id: 'id' },
            staticState: { editMode: false },
            label: 'View',
          },
          {
            path: '/role-management-edit',
            searchArgs: { id: 'id' },
            staticState: { editMode: true, createRole: false },
            label: 'Edit',
          },
        ],
      },
      id: {
        hide: true,
      },
    }
    return (
      <div id="roleManagement">
        <BreadCrumb {...this.props} />
        <div className="header">
          <h1>Roles</h1>
        </div>
        <div className="subhead">
          <Link to={{
            pathname: '/role-management-create',
            state: { editMode: true, createRole: true },
          }}
          >
            <FliptButton className="primary" name="Create Role" />
          </Link>
          <div className="searchForm" />
        </div>
        <div className="content">
          <FliptGrid
            headers={headers}
            data={gridData}
            cellRendererParams={cellRendererParams}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    data: state.roleManagement.roleListing,
    user: state.user
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...RoleManagementActions,
    ...NavigationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleManagement)
