export function testForOldDatabaseStruct(unTestedDatabaseStructure, criteria_id) {
  // a transitory function that probably shouldn't make it to production if our data is clean
  if (unTestedDatabaseStructure.criteria?.length > 0 && !unTestedDatabaseStructure.criteria[0]?.conditions) {
    return {
      ...unTestedDatabaseStructure,
      "criteria": [
        {
          "conditions": unTestedDatabaseStructure.criteria?.map(condition => ({
            ...condition,
            field: condition.qualifier || condition.field,
          })),
          // create a logic tree for criteria when we want to support something other than AND
        }
      ],
      "id": criteria_id,
      "is_published": !!unTestedDatabaseStructure?.is_published,
    }
  }
  return unTestedDatabaseStructure;
}

export function convertQualifierDatabaseStructToClientSideStruct(unTestedDatabaseStructure, criteria_id) {
  const databaseStructure = testForOldDatabaseStruct(unTestedDatabaseStructure, criteria_id)
  let clientStructure = [];
  if (databaseStructure.parent_criteria) {
    databaseStructure.parent_criteria.conditions.forEach(parentCondition => {
      const matchingCriteria = databaseStructure.criteria.find(criterion => criterion.parent_id === parentCondition.id);
      if (matchingCriteria) {
        const combinedObject = {
          ...parentCondition,
          parent_type: databaseStructure.parent_type || '',
          criteria: matchingCriteria.conditions,
          logic_tree: databaseStructure.parent_criteria.logic_tree,
          criteria_id: criteria_id,
          is_published: databaseStructure.is_published,
        };
        clientStructure.push(combinedObject);
      }
    });
  } else {
    // currently not accounting for:
    // 1.multiple criteria when no parent is present
    // 2 logic tree, since it's assumed to be AND for all criteria
    clientStructure = databaseStructure.criteria.map(criterion => ({
      parent_type: databaseStructure.parent_type || '',
      criteria: criterion.conditions,
      criteria_id: criteria_id,
      is_published: databaseStructure.is_published,
    }));
  }

  return clientStructure;
}

/**
 * this function only deal with QL qualifiers. if qualifier doesn't have parent_type defined,
 * it will return the same response.
 * @param {array} clientStructure - list of parent criteria objects
 * 
 */
export function convertClientSideStructToQualifierDatabaseStruct(clientStructure) {
  // build criteria list
  const databaseStruct = clientStructure.reduce((databaseCriteriaStruct, parent, index) => {
    // logic tree for criteria are AND
    const newCriteria = { conditions: [], logic_tree: ["AND"] }
    newCriteria.conditions = parent.criteria.map((condition, index) => {
      const conditionWithId = { ...condition }
      if (!condition.id) { conditionWithId.id = "condition" + (index + 1) }
      newCriteria.logic_tree.push(conditionWithId.id)
      return conditionWithId;
    })
    if (parent.id) {
      newCriteria.parent_id = parent.id
      if (!("parent_criteria" in databaseCriteriaStruct)) {
        databaseCriteriaStruct.parent_criteria = { conditions: [], logic_tree: parent.logic_tree }
        databaseCriteriaStruct.parent_type = parent.parent_type;
      }
      const parentCopy = Object.assign({}, parent)
      delete parentCopy.criteria;
      delete parentCopy.parent_type;
      delete parentCopy.criteria_id;
      delete parentCopy.logic_tree;
      databaseCriteriaStruct.parent_criteria.conditions.push(parentCopy)
    }
    databaseCriteriaStruct.criteria.push(newCriteria)
    return databaseCriteriaStruct
  },
    {
      "criteria": [],
    });
  databaseStruct.is_published = clientStructure.every((struct => struct.is_published));
  databaseStruct.id = clientStructure[0]?.criteria_id || '';
  databaseStruct.type = 'criteria_qualifier'
  // todo: build parent_criteria and its logic tree
  return databaseStruct;
}

export function getAltName(name) {
  const ALT_NAMES = {
    "chaincode": "chain code",
    "corpname": "corporate name",
    "pharmacystate": "state",
    "pharmacytype": "type",
  }
  if (name in ALT_NAMES) {
    return ALT_NAMES[name]
  }
  return (name || '').replace(/_/g, ' ');
}
