import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Accordion, Button, Icon } from 'semantic-ui-react'
import FliptGrid from '../../../../components/fliptGrid'
import FliptDropdown from '../../../../components/form/fliptDropdown'
import AddRow from '../addRow'
import { Creators as PlanManagementActions } from '../../../../redux/reducers/api/planManagement'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { DeleteRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { InputTextEditor, DropdownEditor, DatePickerEditor } from '../../../../components/fliptGrid/cellEditors'
import rowCellInfo from '../data/rowCellInfo'
import './styles.scss'
import moment from 'moment-timezone'
import { resolveMessage } from '../../../../utils/validationHelpers'

class CustomCopay extends Component {
  constructor(props) {
    super(props)
    const { state, editMode } = props
    const { programs, phases } = editMode ? state : []
    const { planLOB } = state
    const hasPhases = planLOB?.includes('Medicare D')

    const initialProgramValues = editMode ? this.initializeProgramValues(programs, hasPhases ? phases : []) : []

    this.state = {
      activeProgramLevel: 0,
      activePhaseIndex: 0,
      hasPhases,
      phases: state.phases || [],

      programStates: initialProgramValues ? [...initialProgramValues] : [],
    }
  }

  componentDidMount() {
    const { state, editMode } = this.props
    const { programsData } = state
    const { hasPhases, phases } = this.state
    const defaultTierCondition = {
      action: '',
      member_pay: null,
      copay_type: '',
      day_supply: '',
      max_copay: null,
      min_copay: null,
      network_tier: 'ALL',
      pharmacy_type: ''
    }
    if (programsData?.length) {
      this.setState((prevState) => {
        if (!editMode) {
          return {
            programStates: programsData,
          }
        }
        return {
          programStates: prevState.programStates.map(t => {
            const programData = programsData.find(d => d.program_version_id === t.program_version_id)
            let tierConditions = []
            let phaseData = []
            if (hasPhases) {
              const phaseGrouping = _.groupBy(programData?.tierConditions, 'phase')
              phaseData = phases.map(p => p.value).map((phaseName) => ({
                phase: phaseName,
                tierConditions: phaseGrouping[phaseName] || [defaultTierCondition],
              }))
            } else {
              tierConditions = programData?.tierConditions || [defaultTierCondition]
            }
            return {
              ...t,
              form: programData?.form || t.form,
              tierConditions,
              phaseData,
            }
          })
        }
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { programStates } = this.state
    const { copyFormData } = this.props
    if (copyFormData && !_.isEqual(prevState.programStates, programStates)) {
      copyFormData({ data: programStates })
    }
  }

  initializeProgramValues = (programs, phases) => {
    const { state } = this.props
    const formVars = {
      accumulate_deductible: '',
      accumulate_out_of_pocket: '',
      deductible_exempt: '',
      out_of_pocket_exempt: '',
      deductible_applies: ''
    }
    let tierConditions = []
    let phaseData = []
    const defaultTierCondition = {
      action: "",
      member_pay: null,
      copay_type: "",
      max_copay: null,
      min_copay: null,
      network_tier: "ALL",
      pharmacy_type: ''
    }
    if (phases?.length > 0) {
      phaseData = phases.map(p => {
        const tierConditions = []
        rowCellInfo.day_supply.options.map(option => {
          if (option.value !== "All") {
            tierConditions.push({
              ...defaultTierCondition,
              day_supply: option.value,
            })
          }
        })
        return {
          phase: p.value,
          tierConditions: tierConditions,
        }
      })
    } else {
      rowCellInfo.day_supply.options.map(option => {
        if (option.value !== "All") {
          tierConditions.push({
            ...defaultTierCondition,
            day_supply: option.value,
          })
        }
      })
    }
    const programStates = programs.map((program) => ({
      program_id: program.value,
      custom_ui: program?.effective_begin_date ? `${program.display_name} (${moment(program.effective_begin_date).format('MM/DD/YYYY')} - ${program.effective_end_date ? moment(program.effective_end_date).format('MM/DD/YYYY') : ''})` : program.display_name,
      program_name: program.display_name,
      program_version_id: program.program_version_id,
      form: _.cloneDeep(formVars),
      tierConditions: _.cloneDeep(tierConditions),
      phaseData: _.cloneDeep(phaseData),
    }))
    return programStates
  }

  onFilterChange = (e, dropdown) => {
    const { programStates, activeProgramLevel } = this.state
    const { name, value } = dropdown || e.currentTarget

    const programStatesCopy = _.cloneDeep(programStates)
    const activeProgramState = _.cloneDeep(programStatesCopy[activeProgramLevel])
    const prevState = activeProgramState.form
    activeProgramState.form = {
      ...prevState,
      [name]: value,
    }
    programStatesCopy[activeProgramLevel] = activeProgramState
    this.setState({
      programStates: programStatesCopy,
    })
  }

  showGenerateButton = () => {
    const { programStates, activeProgramLevel, activePhaseIndex } = this.state
    const hasPhases = programStates[activeProgramLevel]?.phaseData?.length > 0
    const { tierConditions = [] } = hasPhases ? programStates[activeProgramLevel]?.phaseData[activePhaseIndex] || {} : (programStates[activeProgramLevel] || {})
    const ele = tierConditions.find(x => x.day_supply === "All" || x.pharmacy_type === "ALL")
    return !_.isEmpty(ele)
  }

  generateGrid = () => {
    const { programStates } = this.state
    const { actions } = this.props
    const programStatesCopy = _.cloneDeep(programStates)
    try {
      programStatesCopy.forEach(formData => {
        if (formData?.phaseData?.length > 0) {
          formData.phaseData.forEach(phase => {
            this.validateCopayData(phase)
          })
        } else {
          this.validateCopayData(formData)
        }
      })
    } catch (err) {
      const transitionalPortal = {
        header: 'Validation Error',
        copy: err,
      }
      actions.displayTransitionalPortal(transitionalPortal)
      return
    }
    this.setState({ programStates: programStatesCopy })
  }

  hasDuplicates = (array, pharmacy_type) => {
    const arr = array.filter(x => x === pharmacy_type)
    return (new Set(arr)).size !== arr.length
  }

  validateCopayData = (formData) => {
    const networkTierArray = [...formData.tierConditions.map((data) => data.pharmacy_type)];
    let newTierConditions = []
    formData.tierConditions.forEach(tier => {
      if (tier?.day_supply !== "All") { newTierConditions.push(tier) }
      if (tier?.day_supply === "All") {
        if (this.hasDuplicates(networkTierArray, tier?.pharmacy_type)) {
          throw new Error(resolveMessage("val_remove_duplicate_value_from_network_tier").message)
        } else {
          rowCellInfo.day_supply.options.forEach(option => {
            if (option?.value !== "All") {
              const newTierCondition = { ...tier }
              newTierCondition.day_supply = option.value
              newTierConditions.push(newTierCondition)
            }
          })
        }
      }
    })
    let networkTierCopy = []
    newTierConditions.forEach(tier => {
      if (tier?.pharmacy_type === "ALL") {
        rowCellInfo.pharmacy_type.options.forEach(option => {
          if (option?.value !== "ALL") {
            networkTierCopy = [...networkTierCopy, { ...tier, pharmacy_type: option.value }]
          }
        })
      } else {
        networkTierCopy = [...networkTierCopy, { ...tier }]
      }
    })
    newTierConditions = networkTierCopy
    formData.tierConditions = newTierConditions
    const groupped = _.groupBy(newTierConditions, n => `${n.day_supply}${n.pharmacy_type}`)
    const duplicates = (_.uniq(_.flatten(_.filter(groupped, function (n) { return n.length > 1 }))).length > 1)
    if (duplicates) {
      throw new Error(resolveMessage("val_remove_duplicate_value_from_grid").message)
    }
  }


  addRow = () => {
    const { programStates, activeProgramLevel, activePhaseIndex } = this.state
    const hasPhases = programStates[activeProgramLevel].phaseData.length > 0
    const { tierConditions, effective_end_date, effective_start_date } = hasPhases ? programStates[activeProgramLevel].phaseData[activePhaseIndex] : programStates[activeProgramLevel]

    const programStatesCopy = _.cloneDeep(programStates)
    const activeProgramState = { ...programStatesCopy[activeProgramLevel] }
    const dataToUpdate = hasPhases ? activeProgramState.phaseData[activePhaseIndex] : activeProgramState
    dataToUpdate.tierConditions = [
      ...tierConditions,
      Object.keys(tierConditions[0]).reduce((acc, header) => {
        if (header === "effective_start_date") {
          acc[header] = effective_start_date || tierConditions[0][header] || ''
        } else if (header === "effective_end_date") {
          acc[header] = effective_end_date || tierConditions[0][header] || ''
        } else {
          acc[header] = ''
        }
        return acc
      }, {}),
    ]
    programStatesCopy[activeProgramLevel] = activeProgramState

    this.setState({
      programStates: programStatesCopy,
    })
  }

  delRow = (rowIndex) => {
    const { programStates, activeProgramLevel, activePhaseIndex } = this.state
    const hasPhases = programStates[activeProgramLevel].phaseData.length > 0
    const { tierConditions } = hasPhases ? programStates[activeProgramLevel].phaseData[activePhaseIndex] : programStates[activeProgramLevel]

    const programStatesCopy = _.cloneDeep(programStates)
    const activeProgramState = { ...programStatesCopy[activeProgramLevel] }
    const dataToUpdate = hasPhases ? activeProgramState.phaseData[activePhaseIndex] : activeProgramState

    dataToUpdate.tierConditions = tierConditions?.length <= 1 ? [] : tierConditions.filter((cond) => tierConditions.indexOf(cond) !== rowIndex)

    programStatesCopy[activeProgramLevel] = activeProgramState

    this.setState({
      programStates: programStatesCopy,
    })
  }

  handleChange = (el, dropdown, rowIndex, gridApi) => {
    const { programStates, activeProgramLevel, activePhaseIndex } = this.state

    const programStatesCopy = { ...programStates }
    const activeProgramState = { ...programStatesCopy[activeProgramLevel] }
    const hasPhases = activeProgramState.phaseData.length > 0

    const tierConditionsCopy = hasPhases ? [...activeProgramState.phaseData[activePhaseIndex].tierConditions] : [...activeProgramState.tierConditions]
    const rowToUpdate = tierConditionsCopy[rowIndex]
    const { name, value } = dropdown || el.currentTarget
    rowToUpdate[name] = value

    if (name === 'copay_type') {
      rowToUpdate['member_pay'] = ''
    }

    tierConditionsCopy[rowIndex] = rowToUpdate
    const dataToUpdate = hasPhases ? activeProgramState.phaseData[activePhaseIndex] : activeProgramState
    dataToUpdate.tierConditions = tierConditionsCopy
    programStatesCopy[activeProgramLevel] = activeProgramState
    this.setState({
      programStates: Object.values(programStatesCopy),
    })

    gridApi.refreshCells()
  }

  handleProgramClick = (idx) => {
    const { state } = this
    const { activeProgramLevel } = state
    const newIndex = activeProgramLevel === idx ? -1 : idx
    this.setState({ activeProgramLevel: newIndex, activePhaseIndex: 0 })
  }

  handlePhaseClick = (idx) => {
    const { state } = this
    const { activePhaseIndex } = state
    const newIndex = activePhaseIndex === idx ? -1 : idx
    this.setState({ activePhaseIndex: newIndex })
  }

  renderAccordianContent = (activePhaseIndex, phases) => {
    const { state } = this.props
    const { planLOB } = state
    if (planLOB?.includes('Medicare D')) {
      return this.renderNestedAccordian(phases, activePhaseIndex)
    }
    return this.renderGrid()
  }

  renderNestedAccordian = (phases, activePhaseIndex) => {
    const phasesAccordian = phases.map((phase, phaseIdx) => (
      <div className="section-phase-level">
        <Accordion.Title
          active={activePhaseIndex === phaseIdx}
          index={phaseIdx}
          onClick={() => this.handlePhaseClick(phaseIdx)}
        >
          <Icon name="dropdown" />
          {phase.display_name}
        </Accordion.Title>
        <Accordion.Content
          active={activePhaseIndex === phaseIdx}
        >
          {this.renderGrid()}
        </Accordion.Content>
      </div>
    ))
    return phasesAccordian
  }

  renderGrid = () => {
    const { programStates, activeProgramLevel, hasPhases, activePhaseIndex } = this.state
    const { editMode } = this.props
    const activeProgramState = programStates[activeProgramLevel]?.phaseData?.length > 0 ? programStates[activeProgramLevel].phaseData[activePhaseIndex] : programStates[activeProgramLevel] ?? {}
    const { tierConditions } = activeProgramState ?? {}
    const headers = [
      'day_supply',
      'network_tier',
      'copay_type',
      'member_pay',
      'min_copay',
      'max_copay',
      'action',
    ]
    const cellRendererParams = {
      day_supply: {
        width: 145,
        overrideHeader: 'Days Supply',
      },
      copay_type: {
        width: 135,
        overrideHeader: 'COPAY Type',
      },
      member_pay: {
        width: 160,
        overrideHeader: 'Member Pay',
        valueFormatter: params => {
          if (params.data.member_pay === undefined || params.data.member_pay === null) return null
          if (params.data.copay_type === 'Fixed') return '$' + `${params.data.member_pay}`
          if (params.data.copay_type === 'Co-Insurance') return `${params.data.member_pay}` + '%'
          return params.data.member_pay
        },
      },
      min_copay: {
        width: 135,
        overrideHeader: 'Min COPAY',
      },
      max_copay: {
        width: 135,
        overrideHeader: 'Max COPAY',
      },
      action: {
        cellRenderer: DeleteRowRenderer,
        state: {
          onClick: this.delRow,
        },
        width: 135,
      },
    }
    const cellEditorParams = {
      day_supply: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: this.handleChange,
      },
      network_tier: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: this.handleChange,
      },
      pharmacy_type: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: this.handleChange,
      },
      copay_type: {
        editable: true,
        cellEditor: DropdownEditor,
        onChange: this.handleChange,
      },
      member_pay: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
        validation: 'numeric',
      },
      min_copay: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
        validation: 'numeric',
      },
      max_copay: {
        editable: true,
        cellEditor: InputTextEditor,
        onChange: this.handleChange,
        validation: 'numeric',
      },
    }

    if (editMode) {
      return (
        <section className="grid-container spacing border-line shadow">
          <div className="content">
            <FliptGrid
              data={tierConditions}
              headers={headers}
              cellRendererParams={cellRendererParams}
              cellEditorParams={cellEditorParams}
              rowCellInfo={rowCellInfo}
            />
          </div>
          <div className="addRowButtonContainer">
            {this.showGenerateButton() && (<Button compact size="small" onClick={() => this.generateGrid()} color="youtube">Generate</Button>)}
            <AddRow addRow={this.addRow} />
          </div>
        </section>
      )
    }
    return (
      <div className="content">
        <FliptGrid
          data={tierConditions}
          headers={headers.slice(0, -1)}
        />
      </div>
    )
  }

  render() {
    const {
      activeProgramLevel,
      activePhaseIndex,
      programStates,
      phases,
      hasPhases
    } = this.state
    const {
      editMode,
    } = this.props

    const activeProgramState = programStates[activeProgramLevel] ?? {}
    const { form } = activeProgramState ?? {}

    const {
      accumulate_deductible,
      accumulate_out_of_pocket,
      deductible_exempt,
      out_of_pocket_exempt,
      deductible_applies
    } = form ?? {}

    const dropdownOptions = [{ value: 'Y', text: 'YES' }, { value: 'N', text: 'NO' }]

    return (
      <div className="custom_copay">
        <div className="section-programs">
          <Accordion className="section-programs-accordion" styled>
            {programStates?.map((program, idx) => (
              <div className="section-program-level" key={idx}>
                <Accordion.Title
                  active={activeProgramLevel === idx}
                  index={idx}
                  onClick={() => this.handleProgramClick(idx)}
                >
                  <Icon name="dropdown" />
                  {program.custom_ui}
                </Accordion.Title>
                <Accordion.Content
                  active={activeProgramLevel === idx}
                >
                  <div className="section">
                    <section className="copay-dropdowns-container spacing border-line shadow">
                      <div className="copay-dropdowns">
                        <FliptDropdown
                          placeholder="Select"
                          label="Accumulate Deductible"
                          name="accumulate_deductible"
                          value={accumulate_deductible}
                          options={dropdownOptions}
                          onChange={this.onFilterChange}
                          stylized
                          disabled={!editMode}
                        />
                      </div>
                      <div className="copay-dropdowns">
                        <FliptDropdown
                          placeholder="Select"
                          label="Accumulate Out of Pocket"
                          name="accumulate_out_of_pocket"
                          value={accumulate_out_of_pocket}
                          options={dropdownOptions}
                          onChange={this.onFilterChange}
                          stylized
                          disabled={!editMode}
                        />
                      </div>
                      <div className="copay-dropdowns">
                        <FliptDropdown
                          placeholder="Select"
                          label="Deductible Exempt"
                          name="deductible_exempt"
                          value={deductible_exempt}
                          options={dropdownOptions}
                          onChange={this.onFilterChange}
                          stylized
                          disabled={!editMode}
                        />
                      </div>
                      <div className="copay-dropdowns">
                        <FliptDropdown
                          placeholder="Select"
                          label="Out of Pocket Exempt"
                          name="out_of_pocket_exempt"
                          value={out_of_pocket_exempt}
                          options={dropdownOptions}
                          onChange={this.onFilterChange}
                          stylized
                          disabled={!editMode}
                        />
                      </div>
                      {hasPhases && <div className="deductible-dropdown">
                        <FliptDropdown
                          placeholder="Select"
                          label="Deductible Applies"
                          name="deductible_applies"
                          value={deductible_applies}
                          options={dropdownOptions}
                          onChange={this.onFilterChange}
                          stylized
                          disabled={!editMode}
                        />
                      </div>}
                    </section>
                    {this.renderAccordianContent(activePhaseIndex, phases)}
                  </div>
                </Accordion.Content>
              </div>
            ))}
          </Accordion>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { fieldDetails } = props

  const programsField = fieldDetails?.find(({ field, type, options }) => field === 'programs' && type === 'DATA' && options.length > 0)
  const programs = programsField?.options || []

  const phasesField = fieldDetails?.find(({ field }) => field === 'phase')
  const phases = phasesField?.options || []

  const daySupplyField = fieldDetails?.find(({ field }) => field === 'day_supply')
  const daySupplyFieldOptions = daySupplyField?.options || []
  const daySupplyOptions = daySupplyFieldOptions?.map((option, index) => {
    const { value } = option
    return {
      key: index,
      text: value,
      value,
    }
  })

  const networkTierOptions = fieldDetails?.find(({ field }) => field === 'network_tier')?.options || []
  rowCellInfo.network_tier.options = networkTierOptions?.map((code, index) => ({
    key: index,
    text: code.display_name,
    value: code.value,
    map_value: code.map_value,
  }))

  rowCellInfo.network_tier.options.unshift({
    key: 0, text: 'ALL', value: 'ALL', map_value: 'ALL',
  })

  const copayTypeField = fieldDetails?.find(({ field }) => field === 'copay_type')
  const copayTypeFieldOptions = copayTypeField?.options || []

  const copayTypeOptions = copayTypeFieldOptions?.map((option, index) => {
    const { value } = option
    return {
      key: index,
      text: value,
      value,
    }
  })

  rowCellInfo.day_supply.options = daySupplyOptions
  rowCellInfo.copay_type.options = copayTypeOptions

  return ({
    state: {
      app: state.app,
      planLOB: state.planManagement.planLOB,
      programs: programs ?? [],
      phases: phases ?? [],
      programsData: state.planManagement.customCopayData,
      effective_end_date: state.planManagement.planDetails.model_data.effective_end_date,
      effective_start_date: state.planManagement.planDetails.model_data.effective_start_date
    },
  })
}
const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...PlanManagementActions,
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

const customCopayContainer = (props) => (
  <CustomCopay
    editMode
    {...props}
  />
)

export default CustomCopay

export const CustomCopayContainer = connect(mapStateToProps, mapDispatchToProps)(customCopayContainer)
