import React, { Component } from 'react'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import Config from "../../../../config";
import FliptButton from '../../../../components/form/fliptButton'
import FliptCheckbox from '../../../../components/form/fliptCheckbox'
import FliptDatepicker from '../../../../components/form/fliptPaDatepicker'
import FliptDropdown from '../../../../components/form/fliptPaDropdown'
import FliptGrid from '../../../../components/fliptGrid';
import FliptHierarchyDropdown from '../../../../components/form/fliptHierarchyDropdown'
import FliptInput from '../../../../components/form/fliptPaInput'
import FliptLabel from '../../../../components/form/fliptLabel'
import rowCellInfo from '../data/rowCellInfo'
import { AlternativeModelsData, CmsTierLabelTypes, lobTypes, submissionFilesData } from '../data/dropdownData'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as FormularyExportConfigurationActions } from '../../../../redux/reducers/api/formularyExportConfiguration'
import { Creators as NavigationActions } from '../../../../redux/reducers/navigation'
import { Creators as RPMActions } from '../../../../redux/reducers/rpm'
import { DeleteRowRenderer, MultiRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { DropdownEditor, InputTextEditor } from '../../../../components/fliptGrid/cellEditors'
import { calcGridRowHeight, convertStrToDateObj, getCAGOptions, parseQueryString } from '../../../../utils/utilities'

class FormularyExportConfigurationBuilder extends Component {
  constructor(props) {
    super(props)

    this.defaultForm = {
      clinical_program_list_id: [],
      cp: [],
      contract_id: '',
      effective_end: '',
      effective_start: '',
      formulary_name: '',
      group: '',
      hierarchy: [],
      is_hierarchy_global: false,
      lob: '',
      pa: [],
      pa_drug_list_id: [],
      pbp: '',
      pr_alt_drug_list_id: [],
      pr_ex_drug_list_id: [],
      ql_drug_list_id: [],
      specialty_program_list_id: [],
      st_drug_list_id: [],
      status: '',
    }

    this.state = {
      readOnly: true,
      form: this.defaultForm,
      tiers: [],
      druglistTypes: ['DRUG_LIST', 'PROGRAM'],
      dropdownValues:
        [
          {
            drug_list_type: ['DRUG_LIST', 'PROGRAM'],
            drug_list_name: [],
          },
        ],
      file_generation_criterias: ['Medicare D', 'Medicare - EGWP'],
      supplemental_benefits: AlternativeModelsData,
      submission_files: submissionFilesData
    }
  }

  componentDidMount() {
    const { actions, history: { location } } = this.props
    this.clearFormData()

    setTimeout(() => {
      actions.getSpecialtyPrograms()
      actions.getDrugList({
        tier: [],
        prior_authorization: [],
        step_therapy: [],
        quantity_limit: []
      })
      actions.getPrAlternates()
      actions.getPrExclusions()
      actions.getClinicalPrograms()
    },
      Config.COMPONENT_DATA_LOAD_TIME
    )

    if (location.state) {
      const readOnly = location.state.status === 'PUBLISHED' || !location.state.editMode
      this.setState({ readOnly })
      actions.getFormularyExportConfigurationDocument(location.state)
      setTimeout(
        () => {
          this.loadFormData()
        }, Config.COMPONENT_DATA_LOAD_TIME
      )
    } else {
      this.clearFormData();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.state.documents[0]?.id || !prevProps.state.documents[0]?.id) return
    const prevId = prevProps.state.documents[0].id
    const currentId = this.props.state.documents[0].id

    if ((!!prevId && !!currentId) && (prevId !== currentId)) this.loadFormData()
  }

  _mapValueToOption = (row) => {
    const data = {
      ...row,
    };

    Object.keys(data).forEach((col) => {
      if (Array.isArray(data[col])) {
        data[col] = data[col].map((group, index) => ({
          key: index,
          text: group,
          value: group,
        }));
      }
    });
    return data;
  };

  _constructDropdownOptions = () =>
    this.state.dropdownValues.map((row) => this._mapValueToOption(row));

  _updateField = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget
    const { form } = this.state
    const { actions } = this.props
    const newForm = { ...form }

    if (name === 'group') {
      newForm.contract_id = ''
      newForm.pbp = ''

      actions.getGroupConfigurationData({ group_name: value })
    }

    newForm[name] = value
    this.setState({ form: newForm })
  }

  clearFormData() {
    const { actions } = this.props
    actions.clearFormularyExportConfigurationList()
    actions.clearApprovalDocuments()

    this.setState({
      form: this.defaultForm
    });
  }

  addGridRow = () => {
    const { tiers, druglistTypes, dropdownValues } = this.state
    // if (tiers.length === 8) {
    //     const header = 'Field Error';
    //     const message = 'You Can Add Maximum of 8 Tiers'
    //     this._showTransitionalPortal(header, message)
    //     return
    // }
    const newTiers = [...tiers]
    const updatedDropdownValues = dropdownValues.concat(
      Object.keys(dropdownValues[0]).reduce((acc, header) => {
        return acc[header] = header === 'drug_list_type' ? druglistTypes : []
      }, {})
    );

    const obj = {
      drug_list_name: [],
      drug_list_type: '',
      exclude_from_export: '',
      tier_level: '',
      tier_name: '',
    }
    newTiers.push(obj)

    this.setState({
      dropdownValues: updatedDropdownValues,
      tiers: newTiers,
    })
  }

  _showTransitionalPortal = (header, message) => {
    const { props } = this;
    const transitionalPortal = {
      header: header,
      copy: message,
    }

    props.actions.displayTransitionalPortal(transitionalPortal)
  }

  delRow = (rowIndex) => {
    const { tiers } = this.state
    const newTiers = [...tiers]

    newTiers.splice(rowIndex, 1)
    newTiers.forEach((data) => data.tier_level = '')

    this.setState({
      tiers: newTiers,
    })
  }

  getTierLevelOptions = (selectedTierLevel) => {
    const { tiers } = this.state
    const numberOfTiersOptions = []

    for (let i = 1; i <= tiers.length; i++) {
      numberOfTiersOptions.push({
        key: i,
        value: i,
        text: i,
        disabled: selectedTierLevel.includes(i)
      })
    }
    return numberOfTiersOptions;
  }

  loadFormData = () => {
    const { form } = this.state
    const { documents } = this.props.state
    const doc = documents[0] //on doc edit, we just reuse reducer to load 1 doc

    if (!doc) return

    this.clearFormData()
    const data = doc?.module_data?.data || {}
    const f = {
      ...form,
      clinical_program_list_id: data?.cp?.map((p) => p.cp_list_id),
      doc_id: doc.id,
      effective_end: moment(data.effective_end).format('YYYY-MM-DD') || '',
      effective_start: moment(data.effective_start).format('YYYY-MM-DD') || '',
      formulary_name: data.formulary_name,
      group: data.group || '',
      hierarchy: doc.hierarchy,
      lob: data.lob,
      pa_drug_list_id: data?.pa?.map((p) => p.pa_drug_list_id),
      pbp: data.pbp,
      pr_alt_drug_list_id: data.pr_alternates?.map((p) => p.pr_alt_drug_list_id),
      pr_ex_drug_list_id: data.pr_exlusions?.map((p) => p.pr_ex_drug_list_id),
      ql_drug_list_id: data?.ql?.map((p) => p.ql_drug_list_id),
      specialty_program_list_id: data.specialty_program_list_id,
      st_drug_list_id: data?.st?.map((p) => p.st_drug_list_id),
      status: doc.status,
    }
    let tiers = []
    if (data.tiers) {
      tiers = data.tiers.map((el) => {
        return {
          drug_list_name: el.tier_data.map((t) => t.drug_list_name),
          drug_list_type: el.drug_list_type,
          exclude_from_export: el.exclude_from_export,
          tier_level: el.tier_level,
          tier_name: el.tier_name,
        }
      })
    }

    this.setState({
      form: f,
      tiers,
    })
  }

  // getTierLevelOptions = (selectedTierLevel) => {
  //     const { tiers } = this.state
  //     const numberOfTiersOptions = []
  //     for (let i = 1; i <= tiers.length; i++) {
  //         numberOfTiersOptions.push({
  //             key: i,
  //             value: i,
  //             text: i,
  //             disabled: selectedTierLevel.includes(i)
  //         })
  //     }
  //     return numberOfTiersOptions;
  // }

  _updateTierData = (el, dropdown, rowIndex, gridApi) => {
    const { tiers, dropdownValues } = this.state
    const { name, value } = dropdown || el.currentTarget
    const newTiers = [...tiers]
    newTiers[rowIndex][name] = value
    let updatedDropdownValues = dropdownValues

    if (name === 'drug_list_type') {
      let condition_name = ''
      if (value === 'DRUG_LIST') {
        condition_name = 'GLOBAL_TIER'
      } else if (value === 'PROGRAM') {
        condition_name = 'GLOBAL_PROGRAM'
      }
      if (!updatedDropdownValues[rowIndex]) updatedDropdownValues.push({ 'drug_list_name': [] })

      updatedDropdownValues[rowIndex]["drug_list_name"] = this.props.state.drugListData.tier.filter((cond) => cond.drug_list_type.toUpperCase() === condition_name).map(item => item.doc_name)
    }

    this.setState({
      tiers: newTiers,
      dropdownValues: updatedDropdownValues
    })

    gridApi.resetRowHeights()
    gridApi.refreshCells(newTiers)

  }

  handleCheckboxChange = (index, change) => {
    const { supplemental_benefits, submission_files } = this.state

    if (change === 'Supplemental') {
      const new_supplemental_benefits = [...supplemental_benefits]
      new_supplemental_benefits[index].applied = !new_supplemental_benefits[index].applied
      this.setState({ supplemental_benefits: new_supplemental_benefits })
    } else {
      const new_submission_files = [...submission_files]
      new_submission_files[index].extract = !new_submission_files[index].extract
      this.setState({ submission_files: new_submission_files })
    }
  }

  _getRowHeight = (params) => calcGridRowHeight(params.data?.drug_list_name)

  setHierarchy = ({ hierarchy, hierarchyIDs }) => {
    const { form } = this.state

    this.setState({
      form: {
        ...form,
        hierarchy,
        hierarchyIDs,
      }
    })
  }

  saveConfig = () => {
    const { form, tiers, supplemental_benefits, submission_files } = this.state
    const { actions, state: { drugListData: { tier, quantity_limit, step_therapy, prior_authorization }, prAlternatesData, prExclusionsData, clinicalProgramsData } } = this.props
    const params = { ...form, supplemental_benefits, submission_files, tiers }

    params.effective_start = form.effective_start ? moment(form.effective_start).format('YYYY-MM-DD') : ''
    params.effective_end = form.effective_end ? moment(form.effective_end).format('YYYY-MM-DD') : ''

    params.st = params?.st_drug_list_id?.map((drug) => {
      return ({
        st_drug_list_id: drug,
        st_drug_list_name: step_therapy.filter((tierData) => drug === tierData?.doc_id)[0]?.doc_name
      })
    })

    params.ql = params?.ql_drug_list_id?.map((drug) => {
      return ({
        ql_drug_list_id: drug,
        ql_drug_list_name: quantity_limit.filter((tierData) => drug === tierData?.doc_id)[0]?.doc_name
      })
    })

    params.pa = params?.pa_drug_list_id?.map((drug) => {
      return ({
        pa_drug_list_id: drug,
        pa_drug_list_name: prior_authorization.filter((tierData) => drug === tierData?.doc_id)[0]?.doc_name
      })
    })

    params.pr_exlusions = params?.pr_ex_drug_list_id?.map((drug) => {
      return ({
        pr_ex_drug_list_id: drug,
        pr_ex_drug_list_name: prExclusionsData.filter((tierData) => drug === tierData?.doc_id)[0]?.doc_name
      })
    })

    params.pr_alternates = params?.pr_alt_drug_list_id?.map((drug) => {
      return ({
        pr_alt_drug_list_id: drug,
        pr_alt_drug_list_name: prAlternatesData.filter((tierData) => drug === tierData?.doc_id)[0]?.doc_name
      })
    })

    params.cp = params?.clinical_program_list_id?.map((p) => {
      return ({
        cp_list_id: p,
        cp_list_name: clinicalProgramsData.filter((tierData) => p === tierData?.doc_id)[0]?.doc_name
      })
    })

    const finalTiers = params?.tiers?.map((data) => {
      const obj = { ...data }
      obj.tier_data = []
      obj.exclude_from_export = obj.exclude_from_export === 'Yes'
      let lookupObj = {
        "DRUG_LIST": "global_tier",
        "PROGRAM": "global_program"
      }
      obj?.drug_list_name?.forEach((drug) => {
        let record_data = tier.filter((tierData) => (drug === tierData?.doc_name) && (lookupObj[obj.drug_list_type] === tierData?.drug_list_type))
        obj.tier_data.push({
          drug_list_id: record_data[0]?.doc_id,
          drug_list_type: record_data[0]?.drug_list_type,
          doc_version: record_data[0]?.doc_version,
          drug_list_name: drug,
        })
      })

      delete obj.drug_list_name
      return (obj)
    })
    params.tiers = finalTiers

    const deleteParams = ["pr_ex_drug_list_id", "pa_drug_list_id", "ql_drug_list_id", "st_drug_list_id", "clinical_program_list_id"]
    deleteParams.forEach(el => {
      delete params[el]
    })

    actions.saveFormularyExportConfigurationData(params)
  }

  cellRendererParams = {
    action: {
      cellRenderer: DeleteRowRenderer,
      state: {
        onClick: this.delRow,
      },
      width: 95,
    },
    drug_list_name: {
      cellRenderer: MultiRowRenderer,
      width: 800,
    }
  }

  cellEditorParams = {
    drug_list_type: {
      editable: true,
      cellEditor: DropdownEditor,
      onChange: this._updateTierData,
      width: "20%"
    },
    drug_list_name: {
      editable: true,
      cellEditor: DropdownEditor,
      onChange: this._updateTierData,
      width: "30%"
    },
    tier_name: {
      editable: true,
      cellEditor: InputTextEditor,
      onChange: this._updateTierData,
      width: "35%"
    },
    tier_level: {
      editable: true,
      cellEditor: DropdownEditor,
      onChange: this._updateTierData,
      width: "5%"
    },
    exclude_from_export: {
      editable: true,
      cellEditor: DropdownEditor,
      onChange: this._updateTierData,
      width: "5%"
    },
  }

  generateDropdownOptions = (o, property = false) => {
    if (!o[property]) return false

    return o[property].map((i, index) => {
      return ({
        key: index,
        value: i.doc_id,
        text: i.doc_name
      })
    })
  }

  render() {
    const { form, tiers, supplemental_benefits, submission_files, readOnly } = this.state
    const { state: {
      carriers, userCAG, drugListData, groupConfigData, prAlternatesData,
      prExclusionsData, specialtyProgramsData, clinicalProgramsData }
    } = this.props
    const dropdownOptions = this._constructDropdownOptions();
    const headers = ['drug_list_type', 'drug_list_name', 'tier_name', 'tier_level', 'exclude_from_export', 'action']
    const selectedTierLevel = tiers?.map((data) => data.tier_level)
    const isEditableStatus = !form.status || form.status.toUpperCase() === 'DRAFT' || form.status.toUpperCase() === 'REJECTED'
    const supplementalBenefits = supplemental_benefits.map((benefit, index) => {
      return (<div className='checkbox-container' >
        <FliptCheckbox
          checked={benefit.applied}
          onChange={() => this.handleCheckboxChange(index, 'Supplemental')}
          value={benefit.applied}
        />
        <FliptLabel
          description={benefit.benefit_name}
          label={benefit.benefit_name}
          labelClass='checkbox-label'
        />
      </div>)
    })
    const submissionFiles = submission_files.map((submission, index) => {
      return (<div className='checkbox-container' >
        <FliptCheckbox
          checked={submission.extract}
          onChange={() => this.handleCheckboxChange(index)}
          value={submission.extract}
        />
        <FliptLabel
          description={submission.file_name}
          label={submission.file_name}
          labelClass='checkbox-label'
        />
      </div>)
    })

    // const filterOptions = getCAGOptions(carriers, form.carrier, form.account)
    // const clientOptions = userCAG?.client?.map((client, index) => {
    //   return ({
    //     key: index,
    //     value: client,
    //     text: client
    //   })
    // })
    rowCellInfo.tier_level.options = this.getTierLevelOptions(selectedTierLevel)
    // rowCellInfo.drug_list_name.options = drugListData?.tier?.map((drug, index) => {
    //     return ({
    //         key: index,
    //         value: drug.doc_name,
    //         text: `${drug.doc_name}||${drug.drug_list_type.toUpperCase()}`
    //     })
    // })
    // rowCellInfo.drug_list_type.options = [
    //     {
    //         key: "DRUG_LIST",
    //         value: "DRUG_LIST",
    //         text: "DRUG_LIST"
    //     },
    //     {
    //         key: "PROGRAM",
    //         value: "PROGRAM",
    //         text: "PROGRAM"
    //     },
    // ]
    const prAlts = prAlternatesData?.map((drug, index) => {
      return ({
        key: index,
        value: drug.doc_id,
        text: drug.doc_name
      })
    })
    const prExclusions = prExclusionsData?.map((item, index) => {
      return ({
        key: index,
        value: item.doc_id,
        text: item.doc_name
      })
    })

    const specialtyPrograms = specialtyProgramsData?.map((item, index) => {
      return ({
        key: index,
        value: item.doc_id,
        text: item.doc_name
      })
    })

    const clinicalPrograms = clinicalProgramsData?.map((item, index) => {
      return ({
        key: index,
        value: item.doc_id,
        text: item.doc_name
      })
    })

    const hidePrExclusions = () => prExclusions.length == 0
    const hidePrAlternates = () => prAlts.length == 0
    const hideSpecialtyPrograms = () => specialtyPrograms.length == 0
    const hideClinicalPrograms = () => clinicalPrograms.length == 0

    return (
      <div id="FormularyExportBuilder" >
        <BreadCrumb {...this.props} />
        <div className="header">Formulary Configuration Builder</div>
        <div className='content'>
          <div className="section">
            <div>
              <FliptInput label='Formulary Name' value={form.formulary_name} name='formulary_name' onChange={this._updateField} stylized='true' />
            </div>
            <div>
              <FliptDatepicker placeholder='MM-DD-YYYY' labelText='Start Date' name='effective_start' value={convertStrToDateObj(form?.effective_start)} onChange={this._updateField} stylized='true' />
            </div>
            <div>
              <FliptDatepicker placeholder='MM-DD-YYYY' labelText='End Date' name='effective_end' value={convertStrToDateObj(form?.effective_end)} onChange={this._updateField} stylized='true' />
            </div>
            <div>
              <FliptDropdown placeholder="Select LOB" options={lobTypes} label='LOB' value={form.lob} name='lob' onChange={this._updateField} stylized='true' />
            </div>
            {/* <FliptDropdown placeholder="Select Group" label="Group" name="group" value={form.group} options={filterOptions.groupOptions} onChange={this._updateField} stylized='true' /> */}
            {/* 
                            <FliptDropdown placeholder="Select Client" options={clientOptions} label='Client' name='client' value={form.client} onChange={this._updateField} stylized='true' />
                            <FliptInput label='Contract ID' value={form.contract_id} name='contract_id' disabled stylized='true' /> 
                            <FliptDropdown placeholder="Select Carrier" label="Carrier" name="carrier" value={form.carrier} options={filterOptions.carrierOptions} onChange={this._updateField} stylized='true' />
                            <FliptInput label='PBP' value={form.pbp} name='pbp' disabled stylized='true' />
                        */}
          </div>

          <div className='section'>
            <div className='inner-header'>Basic Formulary Information</div>
            <FliptHierarchyDropdown showEnhancedTier={false} showRxcui={false} setForm={this.setHierarchy} form={form} showHierarchyGlobal={true} />
          </div>

          <div className="section">
            <div className="row">
              <div className="inner-header">Define Drug List</div>
            </div>
            <div className="row grid-container">
              <FliptGrid
                cellEditorParams={this.cellEditorParams}
                cellRendererParams={this.cellRendererParams}
                data={tiers}
                dropdownOptions={dropdownOptions}
                getRowHeight={this._getRowHeight}
                headers={headers}
                rowCellInfo={rowCellInfo}
              />

            </div>
          </div>
          <div className="section buttons">
            <div className="button-container">
              {!readOnly && isEditableStatus && <FliptButton name='Add Condition' className='primary' onClick={() => this.addGridRow()} />}
            </div>
          </div>
          <div className="section">
            <div className="row">
              <div>
                <div className='inner-header'>Define Prior Authorization Drug List</div>
                <div className='field-container'>
                  <FliptDropdown
                    className="create-formulary-tier-list"
                    item
                    label='Prior Authorization Drug List'
                    multiple={true}
                    name='pa_drug_list_id'
                    onChange={this._updateField}
                    options={this.generateDropdownOptions(drugListData, 'prior_authorization')}
                    // readOnly={readOnly}
                    selection
                    simple
                    stylized='true'
                    value={form?.pa_drug_list_id || []}
                  />
                </div>
              </div>
              <div>
                <div className='inner-header'>Define Quantity Limit Drug List</div>
                <div className='field-container'>
                  <FliptDropdown
                    className="create-formulary-tier-list"
                    item
                    label='Quantity Limit Drug List'
                    multiple={true}
                    name='ql_drug_list_id'
                    onChange={this._updateField}
                    options={this.generateDropdownOptions(drugListData, 'quantity_limit')}
                    // readOnly={readOnly}
                    selection
                    simple
                    stylized='true'
                    value={form?.ql_drug_list_id || []}
                  />
                </div>
              </div>
              <div>
                <div className='inner-header'>Define Step Therapy Drug List</div>
                <div className='field-container'>
                  <FliptDropdown
                    className="create-formulary-tier-list"
                    item
                    label='Step Therapy Drug List'
                    multiple={true}
                    name='st_drug_list_id'
                    onChange={this._updateField}
                    options={this.generateDropdownOptions(drugListData, 'step_therapy')}
                    // readOnly={readOnly}
                    selection
                    simple
                    stylized='true'
                    value={form?.st_drug_list_id || []}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div>
                <div hidden={hidePrExclusions()}>
                  <div className='inner-header' hidden={hidePrExclusions()}>Define Program Exclusions</div>
                  <div className='field-container' hidden={hidePrExclusions()}>
                    <FliptDropdown
                      className="create-formulary-tier-list"
                      item
                      label='Program Exclusions'
                      multiple={true}
                      name='pr_ex_drug_list_id'
                      onChange={this._updateField}
                      options={prExclusions}
                      // readOnly={readOnly}
                      selection
                      simple
                      stylized='true'
                      value={form?.pr_ex_drug_list_id || []}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div hidden={hidePrAlternates()}>
                  <div className='inner-header' hidden={hidePrAlternates()}>Define Program Alternatives</div>
                  <div className='field-container' hidden={hidePrAlternates()}>
                    <FliptDropdown
                      className="create-formulary-tier-list"
                      item
                      label='Program Alternatives'
                      multiple={true}
                      name='pr_alt_drug_list_id'
                      onChange={this._updateField}
                      options={prAlts}
                      // readOnly={readOnly}
                      selection
                      simple
                      stylized='true'
                      value={form?.pr_alt_drug_list_id || []}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div hidden={hideSpecialtyPrograms()}>
                  <div className='inner-header' hidden={hideSpecialtyPrograms()}>Specialty Programs</div>
                  <div className='field-container' hidden={hideSpecialtyPrograms()}>
                    <FliptDropdown
                      className="create-formulary-tier-list"
                      item
                      label='Specialty Programs'
                      multiple={true}
                      name='specialty_program_list_id'
                      onChange={this._updateField}
                      options={specialtyPrograms}
                      // readOnly={readOnly}
                      selection
                      simple
                      stylized='true'
                      value={form?.specialty_program_list_id || []}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div hidden={hideClinicalPrograms()}>
                  <div className='inner-header' hidden={hideClinicalPrograms()}>Clinical Programs</div>
                  <div className='field-container' hidden={hideClinicalPrograms()}>
                    <FliptDropdown
                      className="create-formulary-tier-list"
                      item
                      label='Clinical Programs'
                      multiple={true}
                      name='clinical_program_list_id'
                      onChange={this._updateField}
                      options={clinicalPrograms}
                      // readOnly={readOnly}
                      selection
                      simple
                      stylized='true'
                      value={form?.clinical_program_list_id || []}
                    />
                  </div>
                </div>
              </div>
            </div >
            <div className="row" style={{ display: "none" }}>
              <div>
                <div className='inner-header'>Medicare File Generation Criteria</div>
                <fieldset disabled={!this.state.file_generation_criterias.includes(form.lob)}>
                  <div className='checkbox-outer-container'>
                    <div className='checkbox-inner-container'>
                      <div className='checkbox-header'>
                        Define Submission Files {submissionFiles}
                      </div>
                    </div>
                    <div className='checkbox-inner-container'>
                      <div className='checkbox-header'>
                        Supplemental Benefits or Alternative Models {supplementalBenefits}
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>

          <div className="section buttons">
            <div className='button-container'>
              {!readOnly && isEditableStatus && <FliptButton name='Save Draft' className='primary' onClick={() => this.saveConfig()} />}
            </div>
          </div>
        </div >
      </div >
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    carriers: state?.user?.carriers && state?.user?.carriers?.length > 0 ? state.user.carriers : [],
    clinicalProgramsData: state.formularyExportConfiguration.clinicalProgramsData,
    documents: state.rpm.documents,
    drugListData: state.formularyExportConfiguration.drugListData,
    prAlternatesData: state.formularyExportConfiguration.prAlternatesData,
    prExclusionsData: state.formularyExportConfiguration.prExclusionsData,
    specialtyProgramsData: state.formularyExportConfiguration.specialtyProgramsData,
    // dashboardData: state.cmsFormualry.dashboardData,
    // groupConfigData: state.cmsFormualry.groupConfigData,
    // prExclusionsData: state.cmsFormualry.prExclusionsData,
    userCAG: state?.user?.userCAG
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...FormularyExportConfigurationActions,
    ...NavigationActions,
    ...RPMActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormularyExportConfigurationBuilder)
