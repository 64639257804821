import { call, put, select, takeLatest } from 'redux-saga/effects';
import { parseQueryString } from '../../../utils/utilities';
import {
  Types,
  Creators as rebateActions
} from '../../reducers/api/rebateManagement';
import { Creators as AppActions } from '../../reducers/app';
import { Creators as NavigationActions } from '../../reducers/navigation';

import { getAppState, getApiPath } from '../../reducers/selectors';
import { fetchGet, fetchPost, fetchPut } from '../../../utils/fetchApi';

export default [
  getRebateSummaryDataWatcher,
  getRebateDataWatcher,
  putRebateDataWatcher,
  saveRebateDataWatcher,
  getDrugListSummaryDataWatcher
];


function* getRebateSummaryDataWatcher() {
  yield takeLatest(Types.GET_REBATE_SUMMARY_DATA, getSummaryDataHandler);
}




function* getRebateDataWatcher() {
  yield takeLatest(Types.GET_REBATE_DATA, getDataHandler);
}

function* saveRebateDataWatcher() {
  yield takeLatest(Types.SAVE_REBATE_DATA, saveRebateDataHandler);
}

function* putRebateDataWatcher() {
  yield takeLatest(Types.PUT_REBATE_DATA, putRebateDataHandler);
}

function* getDrugListSummaryDataWatcher() {
  yield takeLatest(Types.GET_DRUG_LIST_SUMMARY_DATA, getDrugListSummaryDataHandler);
}



function* getDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)

    const { api_path } = yield select(getApiPath, 'rebate-management-type')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchGet, url, payload)

    if (payload.drug_list) {
      const data = response?.data?.length > 0 ? response?.data : {};
      yield put(rebateActions.setDrugListData({ data }))
    } else {
      const data = response?.data?.length > 0 ? response?.data[0] : {};
      yield put(rebateActions.setRebateData({ data }))
    }

  } catch (err) {
    console.log('getDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Getting Rebate Management Data Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getSummaryDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState);
    const { api_path } = yield select(getApiPath, 'rebate-management-summary')
    const url = `${serviceUrl}${api_path}`;
    const response = yield call(fetchGet, url, payload);
    const data = response?.data?.length > 0 ? response?.data : {};
    yield put(rebateActions.setRebateSummaryData({ data }));
  } catch (err) {
    console.log('getDataHandler Error >Data ', err);
    const transitionalPortal = {
      header: 'Getting Rebate Management Data Failed',
      copy: err
    };
    yield put(AppActions.displayTransitionalPortal(transitionalPortal));
  }
}

function* putRebateDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'rebate-management-update')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPut, url, payload)
    yield put(rebateActions.setRebateData({ data: response?.data }))
    const transitionalPortal = {
      header: 'Updated Successfully',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    if (payload.publish) {
      yield put(rebateActions.setReloadData())
    } else {
      yield put(NavigationActions.navigateTo({ pathname: '/rebate-management-summary' }))
    }

  } catch (err) {
    console.log('putRebateDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Put Rebate Config Data Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* saveRebateDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)

    const { api_path } = yield select(getApiPath, 'rebate-management-add')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPost, url, payload)
    yield put(rebateActions.setRebateData({ data: response?.data }))
    const transitionalPortal = {
      header: 'Rebate Created Successfully',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(NavigationActions.navigateTo({ pathname: '/rebate-management-summary' }))
  } catch (err) {
    console.log('SaveRebateDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Creating Rebate Data Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}
function* getDrugListSummaryDataHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState);
    const { api_path } = yield select(getApiPath, 'get-all-drug-list');
    const url = `${serviceUrl}${api_path}`;
    const body = { pagination: 'true' };
    const response = yield call(fetchGet, url, body);
    const data = response?.data?.length > 0 ? response?.data : {};
    yield put(rebateActions.setDrugListSummaryData({ data }));
  } catch (err) {
    console.log('getDataHandler Error >Data ', err);
    const transitionalPortal = {
      header: 'Getting Drug List Data Failed',
      copy: err
    };
    yield put(AppActions.displayTransitionalPortal(transitionalPortal));
  }
}
