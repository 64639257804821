import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Icon } from 'semantic-ui-react'

import { Creators as AppActions } from '../../../../redux/reducers/app'
import FliptGrid from '../../../../components/fliptGrid'
import { DeleteRowRenderer, DropdownRenderer, PreviewCellRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { DatePickerEditor, DropdownEditor, InputTextEditor } from '../../../../components/fliptGrid/cellEditors'
import AddRow from '../addRow'
import './styles.scss'
import { benefitLimitation as rowCellInfo } from '../data/rowCellInfo'
import FliptSeparator from '../../../../components/form/fliptSeparator'
import FliptLabel from '../../../../components/form/fliptLabel'
import { addRemoveDaysToDate } from '../../../../utils/utilities'

class BenefitLimitation extends PureComponent {
  constructor(props) {
    super(props)
    const { state } = this.props
    const { limitations = [], plan_sponsor_cap = [] } = state
    this.state = {
      limitations,
      plan_sponsor_cap_applicable: !!plan_sponsor_cap && plan_sponsor_cap.length > 0,
      plan_sponsor_cap,
    }
  }

  componentDidUpdate(prevProps) {
    const { props } = this
    const { limitations } = props.state
    if (prevProps.state.limitations !== limitations) {
      this.setState({ limitations })
    }
  }

  renderGrid = (data, grid) => {
    const { fieldDetails, editMode, state } = this.props
    const { headers, cellRendererParams, cellEditorParams, rowCellInfo: rowCellInfoCopy } = this.getGridParams(grid)

    if (!editMode) {
      headers.splice(0, 1)
    }

    fieldDetails?.forEach((element) => {
      const { field } = element
      if (!(field in rowCellInfoCopy)) {
        return
      }

      rowCellInfoCopy[field].options = element.options.map((code, index) => ({
        key: index,
        text: code.display_name,
        value: code.value,
      }))
    })

    rowCellInfoCopy?.network_tier?.options?.unshift({
      key: 0, text: 'ALL', value: 'ALL', map_value: 'ALL',
    })

    const gridProps = editMode ? {
      cellEditorParams,
      cellRendererParams,
      rowCellInfo: rowCellInfoCopy,
    } : {}

    return (
      <div className="grid-container">
        <FliptGrid
          data={data}
          headers={headers}
          {...gridProps}
        />
      </div>
    )

  }

  addRow = (grid) => {
    const { headers } = this.getGridParams(grid)
    headers.splice(0, 1) // remove action from headers
    const row = headers.reduce((obj, v) => {
      if (v === 'cap_type') {
        obj[v] = 'plan_level'
      } else if (['occ', 'multi_source'].includes(v)) { // TODO: eventually set fields based off of backend
        obj[v] = []
      }
      else if (v === 'network_tier') {
        obj[v] = 'ALL'
      }
      else {
        obj[v] = ''
      }
      return obj
    }, {})
    const { limitations, plan_sponsor_cap } = this.state
    let dataToUpdate = []
    switch (grid) {
      case 'limitations': {
        dataToUpdate = limitations
        break
      }
      case 'plan_sponsor_cap': {
        dataToUpdate = plan_sponsor_cap
        break
      }
      default:
        break
    }
    const newState = {
      ...this.state,
      [grid]: [...dataToUpdate, row],
    }
    this.setState(newState)
    this.validateAndUpdateData({ ...newState })
  }

  delRow = (rowIndex, grid) => {
    const { limitations, plan_sponsor_cap } = this.state
    let dataToUpdate = []
    switch (grid) {
      case 'limitations': {
        dataToUpdate = limitations
        break
      }
      case 'plan_sponsor_cap': {
        dataToUpdate = plan_sponsor_cap
        break
      }
      default:
        break
    }

    const newSource = [...dataToUpdate]
    newSource.splice(rowIndex, 1) // Delete selected row
    const newState = {
      ...this.state,
      [grid]: newSource,
    }
    this.setState(newState)
    this.validateAndUpdateData({ ...newState })
  }

  handleChange = (e, dropdown, rowKey, grid) => {
    const { limitations = [], plan_sponsor_cap = [] } = this.state
    const { name, value } = dropdown || e.currentTarget
    let dataToUpdate = []
    switch (grid) {
      case 'limitations': {
        dataToUpdate = limitations
        break
      }
      case 'plan_sponsor_cap': {
        dataToUpdate = plan_sponsor_cap
        break
      }
      default:
        break
    }
    const dataCopy = [...dataToUpdate]
    dataCopy[rowKey] = { ...dataCopy[rowKey], [name]: value }
    _.debounce(() => this.setState({ [grid]: dataCopy }), 100)

    this.validateAndUpdateData({ ...this.state, [grid]: dataCopy })
  }

  handleCheckboxChange = (el) => {
    const { state } = this
    const { name, checked } = el.currentTarget
    const newState = {
      ...state,
      [name]: checked,
    }
    this.setState(newState)
    this.validateAndUpdateData({ ...newState })
  }

  validateAndUpdateData = (data) => {
    const { copyFormData } = this.props

    copyFormData({ data })
  }

  getGridParams = (grid) => {
    const handleChange = (e, dropdown, rowKey) => {
      this.handleChange(e, dropdown, rowKey, grid)
    }

    const minStartDate = addRemoveDaysToDate(1, false)

    const gridParams = {
      limitations: {
        headers: [
          'delete',
          // grid,
          'action',
          'drug_type',
          'pharmacy_type',
          'network_tier',
          'min_day_supply',
          'max_day_supply',
          'days_to_search_back',
          'max_no_of_fills',
          'amount',
          'type',
          'rank',
          'cp_reject_code',
          'cp_reject_message',
          'cp_additional_reject_message',
          'app_message',
          'medicare_eligible',
          'occ',
          'medicare_type',
          'multi_source',
          'doc_name',
          'app_medicare_message_indicator',
          'minimum_age',
          'maximum_age',
        ],
        cellRendererParams: {
          delete: {
            cellRenderer: DeleteRowRenderer,
            state: {
              onClick: (index) => this.delRow(index, grid),
            },
            width: 100,
          },
          action: {
            width: 115,
            overrideHeader: 'Action',
            cellRenderer: DropdownRenderer,
          },
          drug_type: {
            width: 125,
            overrideHeader: 'Drug Type',
          },
          pharmacy_type: {
            width: 140,
          },
          min_day_supply: {
            width: 200,
            overrideHeader: 'Min Days of Supply',
          },
          max_day_supply: {
            cellRenderer: PreviewCellRenderer,
            searchArgs: {
              key: 'max_day_supply',
              parseData: (e, row) => parseInt(e) === 0 ? 'All' : e,
            },
            width: 200,
            overrideHeader: 'Max Days of Supply',
          },
          days_to_search_back: {
            width: 200,
            overrideHeader: 'Days to Search Back',
          },
          max_no_of_fills: {
            width: 150,
            overrideHeader: 'Max No of Fills',
          },
          amount: {
            width: 120,
            overrideHeader: 'Amount',
          },
          type: {
            width: 100,
            overrideHeader: 'Type',
          },
          rank: {
            width: 100,
            overrideHeader: 'Rank',
          },
          cp_reject_code: {
            width: 200,
            overrideHeader: 'Reject Code',
          },
          cp_reject_message: {
            width: 200,
            overrideHeader: 'Reject Message',
          },
          cp_additional_reject_message: {
            width: 250,
            overrideHeader: 'Additional Reject Message',
          },
          app_message: {
            width: 200,
            overrideHeader: 'App Web Message',
          },
          medicare_eligible: {
            width: 200,
            overrideHeader: 'Medicare Eligible',
          },
          occ: {
            width: 200,
            overrideHeader: 'Other Coverage Code',
          },
          medicare_type: {
            width: 200,
            overrideHeader: 'Medicare Type Code',
          },
          multi_source: {
            width: 200,
            overrideHeader: 'Multi-Source Code',
          },
          doc_name: {
            width: 200,
            overrideHeader: 'Program Name',
          },
          app_medicare_message_indicator: {
            width: 200,
            overrideHeader: 'App Medicare Message Indicator',
          },
          minimum_age: {
            width: 200,
            overrideHeader: 'Minimum Age',
          },
          maximum_age: {
            width: 200,
            overrideHeader: 'Maximum Age',
          }
        },
        cellEditorParams: {
          action: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
          },
          drug_type: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
          },
          pharmacy_type: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
          },
          network_tier: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
          },
          min_day_supply: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
          },
          max_day_supply: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
          },
          days_to_search_back: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
            validation: 'numeric',
          },
          max_no_of_fills: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
          },
          amount: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
            validation: 'numeric',
          },
          type: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
          },
          rank: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
            validation: 'numeric',
          },
          cp_reject_code: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
          },
          cp_reject_message: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
          },
          cp_additional_reject_message: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
          },
          app_message: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
          },
          medicare_eligible: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
          },
          occ: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
          },
          medicare_type: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
          },
          multi_source: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
          },
          doc_name: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
          },
          app_medicare_message_indicator: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
          },
          minimum_age: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
            validation: 'numeric',
          },
          maximum_age: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
            validation: 'numeric',
          }
        },
        rowCellInfo: {
          ...rowCellInfo,
        }
      },
      plan_sponsor_cap: {
        headers: [
          'delete',
          // 'plan_code',
          'cap_type',
          // 'drug_category',
          'start_date',
          'end_date',
          'cap_time_period',
          'sponsor_cap',
          'family_individual',
          'cost_aggregation',
        ],
        cellRendererParams: {
          delete: {
            cellRenderer: DeleteRowRenderer,
            state: {
              onClick: (index) => this.delRow(index, 'plan_sponsor_cap'),
            },
            width: 100,
          },
          // plan_code: {
          //   overrideHeader: 'Plan Code',
          // },
          cap_type: {
            cellRenderer: DropdownRenderer,
            overrideHeader: 'Cap Type',
          },
          // drug_category: {
          //   overrideHeader: 'Drug Category',
          // },
          start_date: {
            overrideHeader: 'Start Date',
          },
          end_date: {
            overrideHeader: 'End Date',
          },
          cap_time_period: {
            cellRenderer: DropdownRenderer,
            overrideHeader: 'Cap Time Period',
          },
          sponsor_cap: {
            overrideHeader: 'Sponsor Cap',
            valueFormatter: (params) => {
              return '$' + params.value
            }
          },
          family_individual: {
            cellRenderer: DropdownRenderer,
            overrideHeader: 'Family/Individual'
          },
          cost_aggregation: {
            cellRenderer: DropdownRenderer,
            overrideHeader: 'Cost Aggregation',
          }
        },
        cellEditorParams: {
          plan_code: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
          },
          cap_type: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
          },
          drug_category: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
          },
          start_date: {
            editable: true,
            cellEditor: DatePickerEditor,
            onChange: handleChange,
          },
          end_date: {
            editable: true,
            cellEditor: DatePickerEditor,
            onChange: handleChange,
          },
          cap_time_period: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
          },
          sponsor_cap: {
            editable: true,
            cellEditor: InputTextEditor,
            onChange: handleChange,
            validation: 'numeric',
          },
          family_individual: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
          },
          cost_aggregation: {
            editable: true,
            cellEditor: DropdownEditor,
            onChange: handleChange,
          },
        },
        rowCellInfo: {
          plan_code: {
            type: 'input',
            disabled: false,
            options: [],
          },
          cap_type: {
            type: 'dropdown',
            disabled: false,
            options: [],
          },
          drug_category: {
            type: 'input',
            disabled: false,
            options: [],
          },
          start_date: {
            type: 'input',
            disabled: false,
            options: [],
          },
          end_date: {
            type: 'input',
            disabled: false,
            options: [],
          },
          cap_time_period: {
            type: 'dropdown',
            disabled: false,
            options: [],
          },
          sponsor_cap: {
            type: 'input',
            disabled: false,
            options: [],
          },
          family_individual: {
            type: 'dropdown',
            disabled: false,
            options: [],
          },
          cost_aggregation: {
            type: 'dropdown',
            disabled: false,
            options: [],
          },
        }
      },
    }
    return gridParams[grid]
  }

  render() {
    const { limitations, plan_sponsor_cap_applicable, plan_sponsor_cap } = this.state
    const { editMode, state: { pharmacy_network_tier_rank = [] } } = this.props
    return (
      <div id="BenefitLimitation">
        {
          !pharmacy_network_tier_rank.length &&
          <div className='network-warning-message-container'>
            <Icon name='exclamation triangle' color={'red'} />
            <span className='network-warning-message'>Please define Pharmacy Networks prior to defining Limitations </span>
          </div>
        }
        {this.renderGrid(limitations, 'limitations')}
        {editMode && (
          <div className="addRowButtonContainer">
            <AddRow addRow={() => this.addRow('limitations')} />
          </div>
        )}
        <div className="section-header"> </div>
        <FliptSeparator />
        <div className="plan-sponsor-cap-section">
          <div className={`ui checkbox`}>
            <input type="checkbox" onChange={this.handleCheckboxChange} checked={plan_sponsor_cap_applicable} name="plan_sponsor_cap_applicable" disabled={!editMode} />
            <FliptLabel description="Plan Sponsor Cap Applicable" label="Plan Sponsor Cap Applicable" />
          </div>
          {plan_sponsor_cap_applicable && (
            <>
              {this.renderGrid(plan_sponsor_cap, 'plan_sponsor_cap')}
              {editMode && (
                <div className="addRowButtonContainer">
                  <AddRow addRow={() => this.addRow('plan_sponsor_cap')} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { fieldDetails, modelData: { programs = [] } } = props

  const capTypeFields = fieldDetails?.find(({ field }) => field === 'cap_type')
  if (!capTypeFields.options.find(o => o.value === 'plan_level')) capTypeFields.options = [{ display_name: 'Plan Level', value: 'plan_level' }].concat(capTypeFields.options)

  fieldDetails.find(x => x.field === 'doc_name').options = programs?.map(p => ({
    display_name: p.program_name,
    value: p.program_name,
  })) || []

  return ({
    state: {
      app: state.app,
      limitations: state.planManagement.benefitLimitation.limitations,
      plan_sponsor_cap: state.planManagement.benefitLimitation.plan_sponsor_cap,
      pharmacy_network_tier_rank: state.planManagement?.planDetails?.model_data?.pharmacy_network_tier_rank || [],
      fieldDetails,
    },
  })
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

const benefitLimitationContainer = (props) => (
  <BenefitLimitation
    editMode
    {...props}
  />
)
export default BenefitLimitation

export const BenefitLimitationContainer = connect(mapStateToProps, mapDispatchToProps)(benefitLimitationContainer)
