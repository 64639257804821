export const deleteUnnecessaryFields = (dict) => {
  const fieldsToExclude = [
    'include_in_grid',
    'member_notes',
    'agent_notes',
    'internal_notes',
    'claim_message_code',
    'claim_message_type',
    'target_system_formulary_tier',
    'target_system_formulary_status',
    'conditionId',
    'condition_id',
    'include_inactive_drugs',
    'rank',
  ]
  const response = { ...dict }
  for (const field of fieldsToExclude) {
    delete response?.[field]
  }
  return response
}
