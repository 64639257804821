import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import "./styles.scss";
import BreadCrumb from "../../../../../components/breadCrumb";
import ApprovalOptions from "../../../../../components/approvalOptions";
import Config from "../../../../../config";
import PAConditions from "./PAConditions";
import SaveTierModal from "./saveTierModal";
import _ from "lodash";
import drugDemographicData from "../../../../../config/constants/clinical/drugDemographic";
import drugMappingData from "../../../../../config/constants/clinical/drugMapping";
import { Creators as AppActions } from "../../../../../redux/reducers/app";
import { Creators as AutoSuggestActions } from "../../../../../redux/reducers/api/autoSuggest";
import { Creators as PriorAuthorizationCreationActions } from "../../../../../redux/reducers/api/priorAuthorizationManagement";
import { Creators as QualifierActions } from "../../../../../redux/reducers/api/qualifiers";
import { Creators as RPMActions } from "../../../../../redux/reducers/rpm";
import { drugGroupValues } from "../../../../../config/constants/clinical/drugGroups";
import {
  convertDateToStartTimeObj,
  parseQueryString,
  convertDateToEndTimeObj, downloadCSV
} from "../../../../../utils/utilities";
import { qualifierType, qualifierOperator } from "./data/qualifier";
import { deleteUnnecessaryFields } from "../../../../../utils/utilizationManagement";

class CreatePriorAuthorization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        doc_id: props.state?.priorAuthorizationData?.doc_id || "",
        doc_name: "",
        effective_start_date: "",
        module: "PRIOR_AUTHORIZATION",
        status: "",
        version: "1.0",
        version_effective_date: "",
        include_inactive_drugs: "Y",
        hierarchy: [],
        is_hierarchy_global: false,
        hideHierarchy: false,
      },
      activePAConditionLevel: 0,
      paConditionLevels: [
        [
          {
            condition_id: "",
            action: "",
            include: true,
            // include_in_grid: true,
            medicare_type: "",
            brand_generic: "",
            ddid: "",
            drug_class: "",
            drug_group: "",
            drug_name: "",
            drug_subclass: "",
            item_status_flag: "",
            gpi: "",
            manufacturer: "",
            multi_source: "",
            otc_indicator: "",
            desi_code: "",
            route_of_administration: "",
            effective_start_date: "",
            effective_end_date: ""
          },
        ],
      ],
      paQualifierLevels: [
        [
          {
            action: "",
            pa_operator: "",
            pa_qualifier: "",
            pa_value: "",
          },
        ],
      ],
      showQualifier: [false],
      dropdownValues: [
        [
          {
            drug_group: drugGroupValues,
            drug_class: [],
            drug_subclass: [],
          },
        ],
      ],
      autoSuggestionValues: [
        [
          {
            manufacturer: { values: [], loading: false },
            drug_name: { values: [], loading: false },
          },
        ],
      ],
      rowUpdated: null,
      columnUpdated: null,
      levelUpdated: null,
      autoSuggestionMinChars: 3,
      cellRenderers: {
        gpi: {
          overrideHeader: "GPI",
          hide: false,
        },
        desi_code: {
          hide: true,
        },
        medicare_type: {
          hide: true,
          overrideHeader: "Medicare Type Code",
        },
        manufacturer: {
          hide: true,
        },
        multi_source: {
          overrideHeader: "Multi-Source Code",
          hide: false,
        },
        item_status_flag: {
          hide: true,
          overrideHeader: "Inactive Indicator"
        },
        ddid: {
          hide: true,
          overrideHeader: "DDID",
        },
        brand_generic: {
          hide: true,
          overrideHeader: "Brand/Generic",
        },
        otc_indicator: {
          hide: true,
          overrideHeader: "OTC Indicator",
        },
        route_of_administration: {
          hide: true,
        },
        ndc: {
          hide: true,
        },
        maintenance_drug_code: {
          hide: true,
          overrideHeader: "Maintenance Drug Indicator",
        },
        application_type_flag: {
          hide: true,
          overrideHeader: 'App Type',
        },
        mfg_labeler_id: {
          hide: true,
          overrideHeader: 'Labler Code',
        },
        repackaged_code: {
          hide: true,
          overrideHeader: 'Repackaging Indicator',
        },
        third_party_restriction_code: {
          hide: true,
          overrideHeader: 'TPR Code',
        },
        dosage_form_cd: {
          hide: true,
          overrideHeader: 'Dosage Form Code',
        },
        clinic_pack_code: {
          hide: true,
          overrideHeader: 'Clinic Pack',
        },
        innerpack_code: {
          hide: true,
          overrideHeader: 'Inner Pack',
        },
        unit_dose: {
          hide: true,
          overrideHeader: 'Unit Dose',
        },
        dea_class_code: {
          hide: true,
          overrideHeader: 'DEA Class',
        },
        fda_therapeutic_equivalence_code: {
          hide: true,
          overrideHeader: 'FDA Equivalence',
        },
        marketing_category: {
          hide: true,
          overrideHeader: 'Marketing Category',
        },
        storage_condition_code: {
          hide: true,
          overrideHeader: 'Storage Condition',
        },
        unit_dose_with_non_unit_dose_non_repackager_in_gpi: {
          hide: true,
          overrideHeader: 'UD With Non UD RC In GPI',
        },
        rx_with_otc_in_gpi: {
          hide: true,
          overrideHeader: 'Rx With OTC In GPI',
        },
        member_notes: {
          hide: true,
        },
        agent_notes: {
          hide: true,
        },
        internal_notes: {
          hide: true,
        },
        claim_message_code: {
          hide: true,
        },
        claim_message_type: {
          hide: true,
        },
        condition_id: {
          hide: true,
          overrideHeader: "Condition Id"
        },
      },

      qualifierDropdownValues: [
        [
          {
            pa_qualifier: qualifierType,
            pa_operator: [],
          },
        ],
      ],
    };
  }

  _onCheckboxChange = (el, name, levelIndex, rowIndex) => {
    const { state } = this;
    let ConditionLevels = state.paConditionLevels;
    const rowToUpdate = ConditionLevels[levelIndex][rowIndex];
    const {
      target: { checked: value },
    } = el;

    rowToUpdate[name] = value;
    if ((state?.form?.status || "DRAFT") === "PUBLISHED") {
      let condition_grid_version = (parseInt(state.form.version) + 1).toString() + ".0"
      rowToUpdate["condition_id"] = this.generateConditionId("PA", state.form.doc_name, condition_grid_version, levelIndex + 1, rowIndex + 1)
    } else {
      rowToUpdate["condition_id"] = this.generateConditionId("PA", state.form.doc_name, state.form.version, levelIndex + 1, rowIndex + 1)
    }
    ConditionLevels[levelIndex][rowIndex][name] = value

    this.setState({
      paConditionLevels: ConditionLevels,
      rowUpdated: rowIndex,
      columnUpdated: name,
      columnValue: value,
    });
  };

  clearFormData() {
    this.setState({
      form: {
        doc_id: "",
        doc_name: "",
        version_effective_date: "",
        effective_start_date: "",
        include_inactive_drugs: "Y",
        module: "PRIOR_AUTHORIZATION",
        status: "DRAFT",
        version: "1.0",
      },
    });
  }
  sanitizeString(text) {
    // Keep only alphanumeric characters and spaces, convert to uppercase
    const sanitizedText = text.replace(/[^a-zA-Z0-9 ]/g, '-').toUpperCase();

    // Replace spaces with underscores
    return sanitizedText.replace(/ /g, '-');
  }

  generateConditionId(module, name, version, level, rowNumber) {
    return `${this.sanitizeString(module)}:::${this.sanitizeString(name)}:::${version}:::L${level.toString().padStart(5, '0')}:::${rowNumber.toString().padStart(7, '0')}`
  }

  componentDidMount() {
    const { actions, history } = this.props
    const { search, state } = history.location

    actions.clearPriorAuthorization()
    actions.clearQualifiers()

    if (!search) {
      // user is creating a new document
      this.clearFormData()
      actions.rpmGetMf2ValData()
      this.setState({
        showQualifier: [true]
      })
      return
    }

    if (search === "?import") {
      const newPaConditionLevels = state.paConditionLevels.map((conditionLevel, levelIndex) => {
        const newConditionLevel = conditionLevel.map((row, index) => {
          if ((row?.condition_id || '' === '') || (row?.condition_id === undefined)) {
            row.condition_id = this.generateConditionId('PA', state?.form?.doc_name || "", state?.form?.version || "1.0", levelIndex + 1, index + 1)
          }
          if (row?.include_in_grid !== true) {
            row.include_in_grid = true;
          }
          return row
        })
        return newConditionLevel
      })
      this.setState({
        paConditionLevels: newPaConditionLevels,
        showQualifier: state.showQualifier,
        form: state?.form,
      });
      return;
    }

    const payload = parseQueryString(history.location.search)
    setTimeout(
      () => {
        actions.getPriorAuthorizationData(payload)
        actions.rpmGetMf2ValData()
      },
      Config.COMPONENT_DATA_LOAD_TIME
    );
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    const prevState = prevProps.state;
    const currState = props.state;

    if (prevState === currState) return;
    const hasConditionsChanged =
      prevState.priorAuthorizationCondData.length !==
      currState.priorAuthorizationCondData.length &&
      !!currState.priorAuthorizationCondData.length;
    const hasPaInfoChanged =
      prevState.priorAuthorizationData != currState.priorAuthorizationData;
    if (hasConditionsChanged) {
      this._updateState();
    } else if (hasPaInfoChanged && currState.priorAuthorizationData?.doc_id) {
      const { priorAuthorizationData } = currState;
      // following same practice as _updateState function (not spreading the priorAuthorizationData)
      const form = {
        ...this.state.form,
        doc_id: priorAuthorizationData.doc_id,
        doc_name: priorAuthorizationData.doc_name,
        effective_start_date: priorAuthorizationData.effective_start_date,
        version_effective_date: priorAuthorizationData.version_effective_date,
        hierarchy: priorAuthorizationData.hierarchy,
        is_hierarchy_global: priorAuthorizationData.is_hierarchy_global,
        hideHierarchy: priorAuthorizationData.hideHierarchy,
        status: priorAuthorizationData.status,
        version: priorAuthorizationData.version,
      };
      this.setState({
        ...this.state,
        form,
      });
    }
    if (!_.isEqual(prevState.autoSuggestData, currState.autoSuggestData))
      this._updateAutoSuggestions();
  }

  componentWillUnmount() {
    const { clearQualifiers } = this.props.actions
    clearQualifiers()
  }

  // eslint-disable-next-line react/destructuring-assignment
  _constructDropdownOptions = () =>
    this.state.dropdownValues.map((dropdownValueLevel) =>
      dropdownValueLevel.map((row) => this._mapValueToOption(row))
    );



  _updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget;
    // Check if name is 'effective_start_date'
    if (name === "effective_start_date") {
      // Update effective_start_date in programConditionLevels
      this.setState((prevState) => {
        let updatedPaConditionLevels = prevState.paConditionLevels.map(
          (level) => {
            return level.map((condition) => {
              return {
                ...condition,
                effective_start_date: value,
              }
            })
          }
        )
        // in current behavior, FliptDatePicker/SemanticDatepicker fires onChange event on first render
        // this trigger this function (_updateFields) and causes effective_start_date for the whole grid to
        // change without any use action. below is the fix for that - we only change grid date if effective_start_date
        // is a date object. please consider this behavior before making any changes.
        if (name === 'effective_start_date' && typeof value !== typeof prevState.form.effective_start_date) {
          updatedPaConditionLevels = prevState.paConditionLevels
        }
        return {
          ...prevState,
          form: {
            ...prevState.form,
            [name]: value,
          },
          paConditionLevels: updatedPaConditionLevels,
        };
      });
    } else {
      this.setState((prevState) => {
        let updatedPaConditionLevels = prevState.paConditionLevels
        if ((name === 'doc_name')) {
          updatedPaConditionLevels = updatedPaConditionLevels.map(
            (level, level_index) => {
              return level.map((condition, condition_index) => {
                return {
                  ...condition,
                  condition_id: this.generateConditionId('PA', value, prevState?.form?.version || "1.0", level_index + 1, condition_index + 1),
                }
              })
            }
          )
        }
        if ((name === 'version')) {
          updatedPaConditionLevels = updatedPaConditionLevels.map(
            (level, level_index) => {
              return level.map((condition, condition_index) => {
                return {
                  ...condition,
                  condition_id: this.generateConditionId('PA', prevState?.form?.doc_name || "", value, level_index + 1, condition_index + 1),
                }
              })
            }
          )
        }
        return {
          ...prevState,
          form: {
            ...prevState.form,
            [name]: value,
          },
          paConditionLevels: updatedPaConditionLevels,
        }
      });
    }
  };

  _handleChange = (
    el,
    dropdown,
    rowIndex,
    gridApi,
    paConditionLevel,
    setAutoSuggestionValues
  ) => {
    const { props, state } = this;
    const { actions } = props;
    const paConditionLevels = [...state.paConditionLevels];
    const dropdownValues = [...state.dropdownValues];
    const autoSuggestionValues = [...state.autoSuggestionValues];
    const rowToUpdate = paConditionLevels[paConditionLevel][rowIndex];
    const dropDownRowToUpdate = dropdownValues[paConditionLevel][rowIndex];
    const { name, value } = dropdown || el.currentTarget;
    rowToUpdate[name] = value;

    if ((state?.form?.status || "DRAFT") === "PUBLISHED") {
      let condition_grid_version = (parseInt(state.form.version) + 1).toString() + ".0"
      rowToUpdate["condition_id"] = this.generateConditionId("PA", state.form.doc_name, condition_grid_version, paConditionLevel + 1, rowIndex + 1)
    } else {
      rowToUpdate["condition_id"] = this.generateConditionId("PA", state.form.doc_name, state.form.version, paConditionLevel + 1, rowIndex + 1)
    }

    if (state.rowUpdated != rowIndex || state.levelUpdated != paConditionLevel)
      actions.clearAutoSuggestData();

    switch (name) {
      case "gpi": {
        this._onGpiChange(value, rowToUpdate, dropDownRowToUpdate);
        break;
      }
      case "drug_group":
        this._onDrugGroupChange(rowToUpdate, dropDownRowToUpdate);
        break;
      case "drug_class":
        this._onDrugClassChange(rowToUpdate, dropDownRowToUpdate);
        break;
      case "drug_subclass":
        break;
      case "manufacturer":
        if (value.length >= state.autoSuggestionMinChars) {
          autoSuggestionValues[paConditionLevel][
            rowIndex
          ].manufacturer.loading = true;
          actions.getAutoSuggestData({
            search_string: value,
            search_in: "fts_ndc_drugs",
            search_for: "mfg",
          });
          this.setAutoSuggestionValues = setAutoSuggestionValues;
        }
        break;
      case "drug_name":
        if (value.length >= state.autoSuggestionMinChars) {
          autoSuggestionValues[paConditionLevel][
            rowIndex
          ].drug_name.loading = true;
          actions.getAutoSuggestData({
            search_string: value,
            search_in: "fts_ndc_drugs",
            search_for: "drug_name",
          });
          this.setAutoSuggestionValues = setAutoSuggestionValues;
        }
        break;
      default:
        break;
    }
    paConditionLevels[paConditionLevel][rowIndex] = rowToUpdate;
    dropdownValues[paConditionLevel][rowIndex] = dropDownRowToUpdate;

    const activeColumns = gridApi.columnModel.columnApi
      .getAllGridColumns()
      .filter((col) => {
        if (col.visible) return col.colId;
      });
    let cellRenderers = { ...state.cellRenderers };
    const getColumn = Object.keys(cellRenderers);

    activeColumns.forEach((col) => {
      if (getColumn.includes(col.colId) && col.colId != "action") {
        cellRenderers[col.colId]["hide"] = false;
      }
    });

    this.setState({
      paConditionLevels,
      dropdownValues,
      rowUpdated: rowIndex,
      columnUpdated: name,
      levelUpdated: paConditionLevel,
      cellRenderers: cellRenderers,
    });
    gridApi.refreshCells();
  };
  setForm = (newForm) => {
    this.setState((prevState) => ({
      ...prevState,
      form: newForm,
    }));
  };
  _refreshDrugDemographicDropdowns = (rowToUpdate, dropDownRowToUpdate) => {
    rowToUpdate.drug_group = "";
    rowToUpdate.drug_class = "";
    rowToUpdate.drug_subclass = "";
    dropDownRowToUpdate.drug_group = drugGroupValues;
    dropDownRowToUpdate.drug_class = [];
    dropDownRowToUpdate.drug_subclass = [];
  };

  _onGpiChange = (value, rowToUpdate, dropDownRowToUpdate) => {
    const gpiCode = value;
    const gpiLen = gpiCode.length;
    if (gpiLen === 0) {
      this._refreshDrugDemographicDropdowns(rowToUpdate, dropDownRowToUpdate);
      return;
    }
    const drugGroupCode = gpiCode.slice(0, 2);
    const drugClassCode = gpiCode.slice(2, 4);
    const drugSubClassCode = gpiCode.slice(4, 6);
    this._selectDrugGroup(drugGroupCode, rowToUpdate, dropDownRowToUpdate);
    this._selectDrugClass(
      drugGroupCode,
      drugClassCode,
      rowToUpdate,
      dropDownRowToUpdate
    );
    this._selectDrugSubclass(
      drugGroupCode,
      drugClassCode,
      drugSubClassCode,
      rowToUpdate
    );
  };

  _selectDrugGroup = (gpiCode, rowToUpdate, dropDownRowToUpdate) => {
    if (gpiCode in drugMappingData) {
      rowToUpdate.drug_group = drugMappingData[gpiCode].drug_group;
      this._onDrugGroupChange(rowToUpdate, dropDownRowToUpdate);
    }
  };

  _selectDrugClass = (
    drugGroupCode,
    drugClassCode,
    rowToUpdate,
    dropDownRowToUpdate
  ) => {
    const validDrugClassCode =
      drugGroupCode in drugMappingData &&
      drugClassCode in drugMappingData[drugGroupCode].drug_classes;
    if (validDrugClassCode) {
      rowToUpdate.drug_class =
        drugMappingData[drugGroupCode].drug_classes[drugClassCode].drug_class;
      this._onDrugClassChange(rowToUpdate, dropDownRowToUpdate);
    }
  };

  _selectDrugSubclass = (
    drugGroupCode,
    drugClassCode,
    drugSubClassCode,
    rowToUpdate
  ) => {
    const validDrugClassCode =
      drugGroupCode in drugMappingData &&
      drugClassCode in drugMappingData[drugGroupCode].drug_classes;
    const validDrugSubClassCode =
      validDrugClassCode &&
      drugSubClassCode in
      drugMappingData[drugGroupCode].drug_classes[drugClassCode]
        .drug_subclasses;
    if (validDrugSubClassCode) {
      rowToUpdate.drug_subclass =
        drugMappingData[drugGroupCode].drug_classes[
          drugClassCode
        ].drug_subclasses[drugSubClassCode].drug_subclass;
    }
  };

  _onDrugGroupChange = (rowToUpdate, dropDownRowToUpdate) => {
    rowToUpdate.drug_class = "";
    rowToUpdate.drug_subclass = "";
    dropDownRowToUpdate.drug_class = this._extractDrugDemographics(rowToUpdate);
    dropDownRowToUpdate.drug_subclass = [];
  };

  _onDrugClassChange = (rowToUpdate, dropDownRowToUpdate) => {
    rowToUpdate.drug_subclass = "";
    dropDownRowToUpdate.drug_subclass =
      this._extractDrugDemographics(rowToUpdate);
  };

  _autoSuggestionResultSelect = (data, rowIndex) => {
    const { state } = this;
    const paConditionLevels = [...state.paConditionLevels];
    const rowToUpdate =
      paConditionLevels[state.activePAConditionLevel][rowIndex];
    const { name, result } = data;
    const value = result.title;
    rowToUpdate[name] = value;
    paConditionLevels[state.activePAConditionLevel][rowIndex] = rowToUpdate;
    this.setState({
      paConditionLevels,
    });
  };

  _delPACondition = (rowIndex, paConditionLevel) => {
    const { paConditionLevels, dropdownValues, autoSuggestionValues } =
      this.state;
    const paConditionLevelToUpdate = [...paConditionLevels[paConditionLevel]];
    const dropdownToUpdate = [...dropdownValues[paConditionLevel]];
    const autoSuggestionToUpdate = [...autoSuggestionValues[paConditionLevel]];

    if (paConditionLevelToUpdate.length <= 1) return;

    const newPAConditionLevel = paConditionLevelToUpdate.filter(
      (cond) => paConditionLevelToUpdate.indexOf(cond) !== rowIndex
    );
    paConditionLevels[paConditionLevel] = newPAConditionLevel;

    const newdropdown = dropdownToUpdate.filter(
      (cond) => dropdownToUpdate.indexOf(cond) !== rowIndex
    );
    dropdownValues[paConditionLevel] = newdropdown;

    const newAutoSuggestion = autoSuggestionToUpdate.filter(
      (cond) => autoSuggestionToUpdate.indexOf(cond) !== rowIndex
    );
    autoSuggestionValues[paConditionLevel] = newAutoSuggestion;

    this.setState({
      paConditionLevels,
      dropdownValues,
      autoSuggestionValues,
    });
  };

  _addPACondition = (level) => {
    const { paConditionLevels, dropdownValues, autoSuggestionValues, form } =
      this.state;
    let no_of_conditions = paConditionLevels[level].length + 1
    let new_id = ""
    if ((form?.status || "DRAFT") === "PUBLISHED") {
      let condition_grid_version = (parseInt(form?.version || "1.0") + 1).toString() + ".0"
      new_id = this.generateConditionId("PA", form?.doc_name || "", condition_grid_version, level + 1, no_of_conditions)
    } else {
      new_id = this.generateConditionId("PA", form?.doc_name || "", form?.version || "1.0", level + 1, no_of_conditions)
    }
    const updatedPAConditionLevels = paConditionLevels[level].concat(
      Object.keys(paConditionLevels[0][0]).reduce((acc, header) => {
        acc[header] = "";
        if (header === "include") acc[header] = true;
        acc["condition_id"] = new_id
        acc["effective_start_date"] = form.effective_start_date || "";
        return acc;
      }, {})
    );
    const updatedDropdownValues = dropdownValues[level].concat(
      Object.keys(dropdownValues[0][0]).reduce((acc, header) => {
        if (header === "drug_group") {
          acc[header] = drugGroupValues;
        } else {
          acc[header] = [];
        }
        return acc;
      }, {})
    );
    const updatedAutoSuggestionValues = autoSuggestionValues[level].concat(
      Object.keys(autoSuggestionValues[0][0]).reduce((acc, header) => {
        acc[header] = { values: [], loading: false };
        return acc;
      }, {})
    );
    paConditionLevels[level] = updatedPAConditionLevels;
    dropdownValues[level] = updatedDropdownValues;
    autoSuggestionValues[level] = updatedAutoSuggestionValues;

    this.setState({
      paConditionLevels,
      dropdownValues,
      autoSuggestionValues,
    });
  };

  _deletePAConditionLevel = (e, paConditionLevel) => {
    const { state } = this;
    const paConditionLevels = [...state.paConditionLevels];
    const paQualifierLevels = [...state.paQualifierLevels];
    const dropdownValues = [...state.dropdownValues];
    const autoSuggestionValues = [...state.autoSuggestionValues];
    const showQualifier = [...state.showQualifier];

    if (paConditionLevels.length <= 1) return;
    showQualifier.splice(paConditionLevel, 1);
    this.setState({
      activePAConditionLevel: 0,
      paConditionLevels: paConditionLevels.filter(
        (cond) => paConditionLevels.indexOf(cond) !== paConditionLevel
      ),
      paQualifierLevels: paQualifierLevels.filter(
        (cond) => paQualifierLevels.indexOf(cond) !== paConditionLevel
      ),
      dropdownValues: dropdownValues.filter(
        (cond) => dropdownValues.indexOf(cond) !== paConditionLevel
      ),
      autoSuggestionValues: autoSuggestionValues.filter(
        (cond) => autoSuggestionValues.indexOf(cond) !== paConditionLevel
      ),
      showQualifier,
    });
  };

  _addNewPAConditionLevel = () => {
    const { state } = this;
    const paConditionLevels = [...state.paConditionLevels];
    const paQualifierLevels = [...state.paQualifierLevels];
    const dropdownValues = [...state.dropdownValues];
    const qualifierDropdownValues = [...state.qualifierDropdownValues];
    const autoSuggestionValues = [...state.autoSuggestionValues];
    const showQualifier = [...state.showQualifier];
    let condition_levels = paConditionLevels.length
    paConditionLevels.push([
      {
        action: "",
        condition_id: this.generateConditionId("PA", state.form.doc_name, state.form.version, condition_levels + 1, 1),
        brand_generic: "",
        drug_class: "",
        drug_group: "",
        drug_name: "",
        drug_subclass: "",
        gpi: "",
        manufacturer: "",
        multi_source: "",
        otc_indicator: "",
        effective_start_date: state.form.effective_start_date || "",
        effective_end_date: "",
      },
    ]);
    paQualifierLevels.push([
      {
        pa_qualifier: "",
        pa_operator: "",
        pa_value: "",
        action: "",
      },
    ]);
    dropdownValues.push([
      { drug_group: drugGroupValues, drug_class: [], drug_subclass: [] },
    ]);
    qualifierDropdownValues.push([
      { pa_qualifier: qualifierType, pa_operator: [] },
    ]);
    autoSuggestionValues.push([
      {
        manufacturer: { values: [], loading: false },
        drug_name: { values: [], loading: false },
      },
    ]);
    showQualifier.push(true);
    this.setState({
      activePAConditionLevel: paConditionLevels.length - 1,
      autoSuggestionValues,
      dropdownValues,
      paConditionLevels,
      paQualifierLevels,
      showQualifier,
      qualifierDropdownValues,
    });
  };

  checkDateValidation = (tierConditions) => {
    const { actions } = this.props;
    let invalidDate = false;
    let formattedPaConditions = tierConditions.map((tierData) => {
      if (!tierData.effective_start_date) {
        invalidDate = true;
      }
      return {
        ...tierData,
        effective_start_date: convertDateToStartTimeObj(
          tierData.effective_start_date
        ),
        effective_end_date: convertDateToEndTimeObj(
          tierData.effective_end_date
        ),
      };
    });
    if (invalidDate) {
      const transitionalPortal = {
        header: "Missing Effective Start Date in conditions",
        copy: `Please Add effective Start Date before saving`,
      };
      actions.displayTransitionalPortal(transitionalPortal);
    }
    return { formattedPaConditions, invalidDate };
  };

  _handlePAConditionLevelClick = (e, titleProps) => {
    const { state } = this;
    const { activePAConditionLevel } = state;
    const { index } = titleProps;
    const newIndex = activePAConditionLevel === index ? -1 : index;
    this.setState({ activePAConditionLevel: newIndex });
  };

  _handleQualifierChange = (
    el,
    dropdown,
    rowIndex,
    gridApi,
    paConditionLevel
  ) => {
    const { state } = this;
    const paQualifierLevels = [...state.paQualifierLevels];
    const rowToUpdate = paQualifierLevels[paConditionLevel][rowIndex];
    const { name, value } = dropdown || el.currentTarget;
    rowToUpdate[name] = value;
    paQualifierLevels[paConditionLevel][rowIndex] = rowToUpdate;

    const qualifierDropdownValues = [...state.qualifierDropdownValues];
    const dropDownRowToUpdate =
      qualifierDropdownValues[paConditionLevel][rowIndex];
    const options1 = [
      "Gender",
      "Diagnosis",
      "Pharmacy",
      "Prescriber",
      "Taxonomy",
      "Specialty",
      "State",
    ];
    const option2 = ["Lookback"];
    if (name === "pa_qualifier") {
      if (options1.includes(value)) {
        dropDownRowToUpdate.pa_operator = ["!=", "="];
      } else if (option2.includes(value)) {
        dropDownRowToUpdate.pa_operator = ["<", "<="];
      } else {
        dropDownRowToUpdate.pa_operator = qualifierOperator;
      }
      qualifierDropdownValues[paConditionLevel][rowIndex] = dropDownRowToUpdate;
    }
    this.setState({
      paQualifierLevels,
      dropDownRowToUpdate,
    });
    gridApi.refreshCells();
  };

  _delQualifierRow = (rowIndex, paConditionLevel) => {
    const { paQualifierLevels } = this.state;
    const paQualifierLevelToUpdate = [...paQualifierLevels[paConditionLevel]];
    if (paQualifierLevelToUpdate.length <= 1) return;

    const newPAQualifierLevel = paQualifierLevelToUpdate.filter(
      (cond) => paQualifierLevelToUpdate.indexOf(cond) !== rowIndex
    );
    paQualifierLevels[paConditionLevel] = newPAQualifierLevel;

    this.setState({
      paQualifierLevels,
    });
  };

  _addQualifierRow = (level) => {
    const { paQualifierLevels, qualifierDropdownValues } = this.state;
    const updatedPAQualifierLevels = paQualifierLevels[level].concat(
      Object.keys(paQualifierLevels[0][0]).reduce((acc, header) => {
        acc[header] = "";
        return acc;
      }, {})
    );

    const updatedDropdownValues = qualifierDropdownValues[level].concat(
      Object.keys(qualifierDropdownValues[0][0]).reduce((acc, header) => {
        if (header === "pa_qualifier") {
          acc[header] = qualifierType;
        } else {
          acc[header] = [];
        }
        return acc;
      }, {})
    );

    paQualifierLevels[level] = updatedPAQualifierLevels;
    qualifierDropdownValues[level] = updatedDropdownValues;
    this.setState({
      paQualifierLevels,
      qualifierDropdownValues,
    });
  };

  _download_drugs_to_csv = (el, paConditionLevel) => {

    const { props, state } = this;
    const { paConditionLevels, form } = state;
    const { doc_name, version } = form;
    let filename = `prior_auth_${doc_name}_${version}_level_${paConditionLevel + 1}_${Date.now()}.csv`
    let paConditions = paConditionLevels[paConditionLevel]
    const { formattedPaConditions, invalidDate } =
      this.checkDateValidation(paConditions);
    if (invalidDate) return;
    props.actions.generatePriorAuthorizationLevelExport({
      drugConditions: formattedPaConditions,
      paConditionLevel: paConditionLevel,
      filename: filename
    }, downloadCSV)
  }

  _sendForReview = () => {
    const {
      props: { actions },
      state,
    } = this;
    const {
      form,
      form: { doc_id },
    } = state;

    if (!doc_id) {
      const transitionalPortal = {
        header: "Prior Authorization For Approval",
        copy: "Please save Prior Authorization Data first",
      };
      actions.displayTransitionalPortal(transitionalPortal);
    } else {
      const message = "Send Prior Authorization For Review?";

      actions.setModalComponent({
        modalProperties: {
          size: "tiny",
        },
        contents: SaveTierModal({
          form,
          message,
          onSaveClick: actions.rpmSendForReview,
          toggleModal: actions.toggleModal,
        }),
      });
      actions.toggleModal();
    }
  };

  _savePriorAuthorization = () => {
    const {
      props: {
        actions,
        state: { criteriaIds },
      },
      state,
    } = this;
    const { form, paConditionLevels, paQualifierLevels } = state;
    const hierarchyOrganizationLevel = form?.hierarchy?.[0]?.children || [];
    if (
      !form.doc_name ||
      !form.effective_start_date ||
      hierarchyOrganizationLevel?.length == 0
    ) {
      let message = "";
      if (!form.doc_name) message = "enter a Prior Authorization Name";
      else if (!form.effective_start_date) message = "select a Start Date";
      else if (hierarchyOrganizationLevel?.length == 0)
        message = "assign a Hierarchy";
      const transitionalPortal = {
        header: "Prior Authorization Save Failed",
        copy: `Please ${message} before saving`,
      };
      actions.displayTransitionalPortal(transitionalPortal);
    } else {
      const formattedPaConditionLevels = [];
      for (const i in paConditionLevels) {
        const { formattedPaConditions, invalidDate } = this.checkDateValidation(
          paConditionLevels[i]
        );
        if (invalidDate) return;
        else formattedPaConditionLevels.push(formattedPaConditions);
      }
      const message =
        "You are about to save the Prior Authorization. Do you wish to save?";
      actions.setModalComponent({
        modalProperties: {
          size: "tiny",
        },
        contents: SaveTierModal({
          form,
          conditions: {
            paConditions: formattedPaConditionLevels,
            qualifierConditions: paQualifierLevels,
            criteriaIds,
            umConditions: formattedPaConditionLevels,
          },
          onSaveClick: actions.savePriorAuthorizationData,
          toggleModal: actions.toggleModal,
          message,
        }),
      });
      actions.toggleModal();
    }
  };

  _mapValueToOption = (row) => {
    const data = {
      ...row,
    };
    Object.keys(data).forEach((col) => {
      if (Array.isArray(data[col])) {
        data[col] = data[col].map((group, index) => ({
          key: index,
          text: group,
          value: group,
        }));
      }
    });
    return data;
  };

  _extractDrugDemographics = (selectedValues) => {
    const validKeys = ["drug_group", "drug_class"];
    const extractedKeys = validKeys.filter((key) => !!selectedValues[key]);
    const extractedData = extractedKeys.reduce(
      (obj, key) => ({
        ...obj[selectedValues[key]],
      }),
      drugDemographicData
    );
    return Object.keys(extractedData);
  };

  _updateDropdownFromCond(initDropdownValues) {
    const { state } = this.props;
    const { priorAuthorizationCondData } = state;
    const dropdownValues = [];
    priorAuthorizationCondData.forEach((cond) => {
      const dropdownRowValues = [];
      cond?.forEach(() => {
        dropdownRowValues.push({ ...initDropdownValues[0][0] });
      });
      dropdownValues.push(dropdownRowValues);
    });
    // const dropdownValues = Array(priorAuthorizationCondData.length).fill([{ ...initDropdownValues[0][0] }])

    priorAuthorizationCondData.forEach(
      (priorAuthorizationCondLevelData, level) => {
        if (priorAuthorizationCondLevelData?.length) {
          priorAuthorizationCondLevelData.forEach((cond, index) => {
            const values = {};
            if (cond.drug_group) {
              values.drug_group = cond.drug_group;
              dropdownValues[level][index].drug_class =
                this._extractDrugDemographics(values);
            }
            if (cond.drug_class) {
              values.drug_class = cond.drug_class;
              dropdownValues[level][index].drug_subclass =
                this._extractDrugDemographics(values);
            }
            if (cond.drug_subclass) {
              values.drug_subclass = cond.drug_subclass;
            }
          });
        }
      }
    );
    return dropdownValues;
  }

  _updateAutoSuggestionFromCond(initAutoSuggestionValues) {
    const { state } = this.props;
    const { priorAuthorizationCondData } = state;
    const autoSuggestionValues = [];
    priorAuthorizationCondData.forEach((cond) => {
      const autoSuggestionRowValues = [];
      cond?.forEach(() => {
        autoSuggestionRowValues.push({ ...initAutoSuggestionValues[0][0] });
      });
      autoSuggestionValues.push(autoSuggestionRowValues);
    });
    return autoSuggestionValues;
  }

  _updateState() {
    const { props, state } = this;
    const { dropdownValues, autoSuggestionValues, qualifierDropdownValues } =
      state;
    const {
      priorAuthorizationData,
      priorAuthorizationQualifierCondData,
      priorAuthorizationCondData,
    } = props.state;

    const updatedDropdownValues = this._updateDropdownFromCond(dropdownValues);
    const updatedAutoSuggestionValues =
      this._updateAutoSuggestionFromCond(autoSuggestionValues);
    const updatedShowQualifier = Array(priorAuthorizationCondData.length).fill(
      true
    );
    priorAuthorizationQualifierCondData.forEach((pa, index) => {
      let obj = {
        pa_qualifier: qualifierType,
        pa_operator: [],
      };
      qualifierDropdownValues[index] = [];
      while (qualifierDropdownValues[index]?.length < pa.length) {
        qualifierDropdownValues[index].push(obj);
      }
    });

    const form = {
      ...state.form,
      doc_id: priorAuthorizationData.doc_id,
      doc_name: priorAuthorizationData.doc_name,
      effective_start_date: priorAuthorizationData.effective_start_date,
      version_effective_date: priorAuthorizationData.version_effective_date,
      hierarchy: priorAuthorizationData.hierarchy,
      is_hierarchy_global: priorAuthorizationData.is_hierarchy_global,
      hideHierarchy: priorAuthorizationData.hideHierarchy,
      status: priorAuthorizationData.status,
      version: priorAuthorizationData.version,
    };

    if (priorAuthorizationCondData?.length) {
      priorAuthorizationCondData.map((outerData, level_index) => {
        if (outerData?.length) {
          outerData.map((innerData, condition_index) => {
            if (
              !innerData.effective_start_date &&
              priorAuthorizationData.effective_start_date
            )
              innerData.effective_start_date = convertDateToStartTimeObj(
                priorAuthorizationData.effective_start_date
              );
            innerData.condition_id = innerData?.condition_id || this.generateConditionId("PA", form?.doc_name || "", form?.version || '1.0', level_index + 1, condition_index + 1)
          });
        }
      });
    }
    this.setState({
      autoSuggestionValues: updatedAutoSuggestionValues,
      dropdownValues: updatedDropdownValues,
      form,
      paConditionLevels: priorAuthorizationCondData,
      // paConditionLevels: priorAuthorizationCondData.map((level) =>
      //   level.map((row) => {
      //     if (row?.include_in_grid !== true) {
      //       row.include_in_grid = true;
      //     }
      //     return row;
      //   })
      // ),
      paQualifierLevels: priorAuthorizationQualifierCondData,
      showQualifier: updatedShowQualifier,
      qualifierDropdownValues,
    });
  }

  _updateAutoSuggestions() {
    const { props, state } = this;
    const { autoSuggestData } = props.state;
    const { activePAConditionLevel, rowUpdated, columnUpdated } = state;
    const autoSuggestionValues = [...state.autoSuggestionValues];
    if (columnUpdated === "manufacturer") {
      autoSuggestionValues[activePAConditionLevel][
        rowUpdated
      ].manufacturer.values = autoSuggestData["mfg"].map((val) => ({
        title: val,
      }));
      autoSuggestionValues[activePAConditionLevel][
        rowUpdated
      ].manufacturer.loading = false;
      if (this.setAutoSuggestionValues)
        this.setAutoSuggestionValues(autoSuggestionValues);
    } else if (columnUpdated === "drug_name") {
      autoSuggestionValues[activePAConditionLevel][
        rowUpdated
      ].drug_name.values = autoSuggestData["drug_name"].map((val) => ({
        title: val,
      }));
      autoSuggestionValues[activePAConditionLevel][
        rowUpdated
      ].drug_name.loading = false;
      if (this.setAutoSuggestionValues)
        this.setAutoSuggestionValues(autoSuggestionValues);
    }
    this.setState(autoSuggestionValues);
  }

  approvalOptionsCallback = () => {
    const { state, props } = this;
    props.actions.getPriorAuthorizationData({ doc_id: state.form.doc_id });
  };

  _constructQualifierDropdownOptions = () =>
    this.state.qualifierDropdownValues.map((dropdownValueLevel) =>
      dropdownValueLevel.map((row) => this._mapValueToOption(row))
    );

  render() {
    const { props, state } = this;
    const {
      form,
      form: { hideHierarchy },
      activePAConditionLevel,
      paConditionLevels,
      paQualifierLevels,
      autoSuggestionValues,
      autoSuggestionMinChars,
      showQualifier,
      cellRenderers,
    } = state;
    let editMode = true;

    if ("state" in props.history.location) {
      editMode =
        props.history.location.state &&
          "editMode" in props.history.location.state
          ? props.history.location.state.editMode
          : true;

      if (parseQueryString(props.history.location.search).editMode === "false")
        editMode = false;
    }

    if (hideHierarchy) editMode = false;
    let headerText = editMode
      ? "Create Prior Authorization"
      : "View Prior Authorization";

    if (form.doc_id && editMode) headerText = "Edit Prior Authorization";
    const dropdownOptions = this._constructDropdownOptions();
    const qualifierDropdownOptions = this._constructQualifierDropdownOptions();

    return (
      <div id="createPriorAuthorization">
        <div className="breadCrumbContainer">
          <BreadCrumb {...this.props} />{" "}
        </div>
        <div className="header">{headerText}</div>
        {
          <ApprovalOptions
            docData={this.state.form}
            actionCallback={this.approvalOptionsCallback}
          />
        }
        <div className="content">
          <PAConditions
            activePAConditionLevel={activePAConditionLevel}
            addNewPAConditionLevel={this._addNewPAConditionLevel}
            addPACondition={this._addPACondition}
            addQualifierRow={this._addQualifierRow}
            autoSuggestionMinChars={autoSuggestionMinChars}
            autoSuggestionResultSelect={this._autoSuggestionResultSelect}
            autoSuggestionValues={autoSuggestionValues}
            criteriaIds={this.props.state.criteriaIds}
            delPACondition={this._delPACondition}
            delQualifierRow={this._delQualifierRow}
            deletePAConditionLevel={this._deletePAConditionLevel}
            onCheckboxChange={this._onCheckboxChange}
            doc_id={form.doc_id}
            dropdownOptions={dropdownOptions}
            editMode={editMode}
            form={form}
            setForm={this.setForm}
            exportConditionDrugs={this._download_drugs_to_csv}
            handleChange={this._handleChange}
            handlePAConditionLevelClick={this._handlePAConditionLevelClick}
            handleQualifierChange={this._handleQualifierChange}
            paConditionLevels={paConditionLevels}
            paQualifierLevels={paQualifierLevels}
            savePriorAuthorization={this._savePriorAuthorization}
            sendForReview={this._sendForReview}
            showQualifier={showQualifier}
            updateFields={this._updateFields}
            cellRenderers={cellRenderers}
            qualifierDropdownOptions={qualifierDropdownOptions}
          />
          {/* <PADrugs
            paConditions={paConditions}
            editMode={editMode}
            savePriorAuthorization={this._savePriorAuthorization}
          /> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: {
    allPriorAuthorizationData:
      state.priorAuthorizationCreation.allPriorAuthorizationData,
    autoSuggestData: state.autoSuggest.autoSuggestData,
    autoSuggestSearchTerm: state.autoSuggest.autoSuggestSearchTerm,
    priorAuthorizationCondData:
      state.priorAuthorizationCreation.priorAuthorizationCondData,
    priorAuthorizationData:
      state.priorAuthorizationCreation.priorAuthorizationData,
    priorAuthorizationQualifierCondData:
      state.priorAuthorizationCreation.priorAuthorizationQualifierCondData,
    criteriaIds:
      state.priorAuthorizationCreation.priorAuthorizationCriteriaIdData,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...AutoSuggestActions,
    ...PriorAuthorizationCreationActions,
    ...RPMActions,
    ...QualifierActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePriorAuthorization);
