import '../styles.scss'
import React from 'react'

import AddRow from '../addRow'
import FliptButton from '../../../../../../components/form/fliptButton'
import FliptGrid from '../../../../../../components/fliptGrid'
import FliptInput from '../../../../../../components/form/fliptInput'
import FliptDropdown from '../../../../../../components/form/fliptDropdown'
import rowCellInfo from '../data/rowCellInfo'
import { DeleteRowRenderer } from '../../../../../../components/fliptGrid/cellRenderers'
import { DropdownEditor } from '../../../../../../components/fliptGrid/cellEditors'
import { documentGovernedValues, approvalStep } from './dropdownForRules'
import FliptHierarchyDropdown from '../../../../../../components/form/fliptHierarchyDropdown'

export default function RuleConditions(props) {
  const {
    ruleConditions, updateFields, setForm,
    handleChange,
    addRow, delRow,
    createRules, editMode, rolesData, form, back,
    getRoleName,
  } = props

  const { is_hierarchy_global } = form
  let documentGovernedOptions = Object.keys(documentGovernedValues).map((d, index) => ({
    key: index + 1,
    text: documentGovernedValues[d].text,
    value: documentGovernedValues[d].value,
  }))

  const roleDataOptions = rolesData.map((obj, index) => ({
    key: index,
    text: obj["name"],
    value: obj["id"],
  }))

  const approvalStepOptions = approvalStep.map((code, index) => ({
    key: index,
    text: code,
    value: code,
  }))

  rowCellInfo.approval_role.options = roleDataOptions
  rowCellInfo.approval_step.options = approvalStepOptions

  if (editMode) {
    rowCellInfo.action = {
      type: 'deleteRow',
      disabled: false,
    }
    Object.keys(rowCellInfo).forEach((key) => { rowCellInfo[key].disabled = false })
  } else {
    delete rowCellInfo.action
    Object.keys(rowCellInfo).forEach((key) => { rowCellInfo[key].disabled = true })
  }
  const headers = Object.keys(rowCellInfo)

  if (headers.includes('action')) {
    headers.splice(headers.indexOf('action'), 1)
    headers.unshift('action')
  }

  const cellRendererParams = {
    action: {
      cellRenderer: DeleteRowRenderer,
      state: {
        onClick: delRow,
      },
      width: 95,
    },
    approval_step: {
      overrideHeader: 'Approval Step',
    },
    approval_role: {
      overrideHeader: 'Approval Role',
      valueFormatter: (params) => {
        return getRoleName(params)
      },
    },
  }

  const cellEditorParams = {
    approval_step: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
    approval_role: {
      cellEditor: DropdownEditor,
      onChange: handleChange,
    },
  }

  let readOnlyRuleConditions = []
  if (!editMode) {
    readOnlyRuleConditions = ruleConditions.map(rule => {
      const readOnlyRule = { ...rule }
      if (rule.approval_role != '') {
        readOnlyRule.approval_role = rolesData.find((role) => role.id === rule.approval_role)?.name
      }
      return readOnlyRule
    })
  }

  const gridProps = editMode ? {
    cellEditorParams,
    cellRendererParams,
    data: ruleConditions,
    headers,
    rowCellInfo,
  } : {
    data: readOnlyRuleConditions,
    headers,
  }

  const rd = form
  return (
    <div className="enclosing-container">
      <div className="create-rule-section">
        <div className="top-fields">
          <div className="fields">
            <div className="fields-label">Rule Name</div>
            <FliptInput className="fields-input" name="name" defaultValue={rd.name} onChange={updateFields} required readOnly={!editMode} />
          </div>
          <div className="fields">
            <div className="fields-label">Document Governed</div>
            {!!documentGovernedOptions.length && <FliptDropdown className="fields-input" value={rd.document_governed} name="document_governed" options={documentGovernedOptions} selection onChange={updateFields} required disabled={!editMode} />}
          </div>
        </div>
        <div>
          <FliptHierarchyDropdown
            setForm={setForm}
            form={form}
            showHierarchyGlobal={true}
            showHierarchyDropdowns={!is_hierarchy_global}
          />
        </div>
      </div>
      <section className="grid-container spacing border-line shadow">
        <FliptGrid
          {...gridProps}
          headers={headers}
        />
        {editMode &&
          (<div className="addRowButtonContainer">
            <AddRow addRow={addRow} />
          </div>)
        }
      </section>
      <div className="buttonContainer">
        {editMode && <FliptButton name="Save" className="primary" onClick={createRules} />}
        <FliptButton name="Back" className="secondary" onClick={back} />
      </div>
    </div>
  )
}
