import {
  call,
  put,
  select,
  takeLatest,
  take,
  // all,
} from 'redux-saga/effects'

import moment from 'moment-timezone'
import { Types, Creators as drugMappingActions } from '../../reducers/api/drugMappingLookup'
import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { Creators as NavigationActions } from '../../reducers/navigation'
// import { Creators as MemberActions } from '../../reducers/api/memberLookup'
import {
  getAppState, getApiPath, getDrugMapLookUp, getHistory, getUserState, isMenuEmpty,
} from '../../reducers/selectors'
import { fetchPost, fetchGet } from '../../../utils/fetchApi'
import { createQueryString, filterRowData } from '../../../utils/utilities'

export default [
  getDrugMappingLookupDataWatcher,
  getDrugMappingOptionsDataWatcher
]

/* WATCHERS */

function* getDrugMappingLookupDataWatcher() {
  yield takeLatest(Types.GET_DRUG_MAPPING_LOOKUP_DATA, getDrugMappingLookupDataHandler)
}

function* getDrugMappingOptionsDataWatcher() {
  yield takeLatest(Types.GET_DRUG_MAPPING_OPTIONS_DATA, getDrugMappingOptionsDataHandler)
}
/* HANDLERS */
function* getDrugMappingOptionsDataHandler() {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }

    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'drug-map-lookup')
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchGet, url)
    yield put(drugMappingActions.setDrugMappingOptionsData(data[0]))
  } catch (err) {
    console.log('getDrugMappingOptionsDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Retrieving Drug Mapping Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getDrugMappingLookupDataHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }

    const {
      form, params, headers, headerMapping, isAdjustment = false, defaultClaimsAccess = false,
    } = payload
    let tempForm = { ...form }

    const { serviceUrl } = yield select(getAppState)
    const history = yield select(getHistory)
    const { claimsPerPage } = yield select(getDrugMapLookUp)
    const { userCAG = {} } = yield select(getUserState)
    history.location.search = createQueryString({
      ...tempForm,
      offset: params?.request.startRow,
    })
    const qs = history.location.search ? `${history.location.search}&limit=${claimsPerPage}` : ''
    if (!qs && !isAdjustment) {
      params?.success({
        rowData: [],
        rowCount: 0,
      })
    } else {
      const empty = yield select(isMenuEmpty)
      if (empty) {
        yield take([AppTypes.SET_APP_SETTINGS])
      }
      const { api_path } = yield select(getApiPath, 'drug-map-lookup')
      const url = `${serviceUrl}${api_path}`
      const reqData = {

        module: tempForm?.module || '',
        doc_name: tempForm?.doc_name || '',
        gpi: tempForm?.gpi || '',
        ndc: tempForm?.ndc || '',
        ddid: tempForm?.ddid || '',
        limit: claimsPerPage,
        offset: params?.request.startRow,
      }

      if (!isAdjustment && headers) {
        const { data, last_row } = yield call(fetchPost, url, reqData)
        let gridData = data
        gridData = data?.map((d) => ({
          ...filterRowData(d, headers, headerMapping),
        }))
        params?.success({
          rowData: gridData,
          rowCount: last_row,
        })
        //   res = yield call(fetchPost, url.replace('/claim-error-management', '/member-details'), data?.map((d) => d.cardholder_id), false)
        //   if (res) {
        //     const dataWithMemberDetails = gridData.map((d, idx) => {
        //       const row = { ...d }
        //       row.first_name = res.data[idx].first_name ? res.data[idx].first_name : 'N/A'
        //       row.last_name = res.data[idx].last_name ? res.data[idx].last_name : 'N/A'
        //       row.date_of_birth = res.data[idx].date_of_birth ? moment(res.data[idx].date_of_birth).format('MM/DD/YYYY') : 'N/A'
        //       if (!row?.plan_name) {
        //         row.plan_name = res.data[idx].plan_name ? res.data[idx].plan_name : 'N/A'
        //       }
        //       row.is_vip = res?.data[idx]?.is_vip ?? false
        //       data[idx] = row
        //       return row
        //     })
        //     gridData = dataWithMemberDetails.map((d) => ({
        //       ...filterRowData(d, headers, headerMapping),
        //     }))
        //     params.success({
        //       rowData: gridData,
        //       rowCount: last_row,
        //     })
        //   }
      }
    }
  } catch (err) {
    console.log('getDrugMappingLookupDataHandler Error > Data: ', err)
    const transitionalPortal = {
      header: 'Drug Mapping Lookup Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

export {
  getDrugMappingLookupDataHandler,
  getDrugMappingOptionsDataHandler
}
