import React, { useEffect, useState, bind } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './styles.scss'
import FliptButton from '../../../../components/form/fliptButton'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as UserActions } from '../../../../redux/reducers/userManagement/index'
import { Creators as FormularyCreationActions } from '../../../../redux/reducers/api/formularyCreationManagement'
import FliptGrid from '../../../../components/fliptGrid'
import { DropdownRenderer } from '../../../../components/fliptGrid/cellRenderers'
import FliptTable from '../../../../components/fliptTable'
import FliptDropdown from '../../../../components/v2/fliptDropdown'
import { MODULES } from '../../../../config/constants/approvals'

class TableViewer extends React.Component {
  constructor(props) {
    let currentCols = []
    super(props);
    if (props.state.importDrugData.header_field_mapping) {
      Object.keys(props.state.importDrugData.header_field_mapping).map((key, index) => {
        currentCols.push({ "FAST_field": key, "import_field_number": props.state.importDrugData.header_field_mapping[key] })
      })
    }

    this.state = {
      cols: currentCols,
      mappedData: [],
      mappedDataCols: [],
      headersMapped: false,
      condition_level: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.state.importDrugData !== this.props.state.importDrugData
      && this.props.state.importDrugData?.header_field_mapping) {
      let newCols = []
      Object.keys(this.props.state.importDrugData.header_field_mapping).map((key, index) => {
        newCols.push({ "FAST_field": key, "import_field_number": this.props.state.importDrugData.header_field_mapping[key] })
      })
      this.setState({
        cols: newCols
      })
    }
    if (prevProps.state.headerFieldMappingData !== this.props.state.headerFieldMappingData
      && this.props.state.headerFieldMappingData.length
    ) {
      this.setState({
        mappedData: this.props.state.headerFieldMappingData,
        mappedDataCols: Object.keys(this.props.state.headerFieldMappingData[0])
      })
    }
    if (prevProps.object_type != this.props.object_type && this.props.object_type === MODULES.FORMULARY_TIER) {
      this.setState({
        condition_level: ''
      })
    }
  }

  handleChange(e, dropdown, rowKey) {
    const { name, value } = dropdown || e.currentTarget
    const newState = this.state.cols.map((obj, i) => {
      if (i == rowKey) {
        obj[name] = value
      }
      return obj;
    });

    this.setState({
      data: newState,
    })
  }

  handleConditionLevelChange = (e, dropdown) => {
    const { name, value } = dropdown || e.currentTarget
    this.setState({
      condition_level: value,
    })
  }

  render() {

    const cellRendererParams = {
      import_field_number: {
        cellRenderer: DropdownRenderer,
      },
      FAST_field: {
        width: '300px'
      }
    }
    let options = []
    if (this.props.state.importDrugData.import_file_columns) {
      options = this.props.state.importDrugData.import_file_columns.map((code, index) => ({
        value: code,
        text: code,
        index: index,
      }))
    }

    const rowCellInfo = {
      import_field_number: {
        type: 'dropdown',
        options: options,
        disabled: false,
      },
      FAST_field: {
        type: 'text',
        disabled: true,
      }
    }

    const handleChange = (e, dropdown, rowKey) => {
      this.handleChange(e, dropdown, rowKey)
    }

    const generateSmartRulesCallback = () => {
      const { actions, state } = this.props
      const { file_load_id } = state.importDrugData
      actions.generateSmartRulesFormularyImportFile({ file_load_id })
    }

    const submitHeaders = () => {
      const { actions, state } = this.props
      const { cols, condition_level } = this.state
      const { importDrugData } = state
      const { file_load_id } = importDrugData
      let header_field_mapping = {}
      cols.forEach(column => {
        let newKey = column.FAST_field
        header_field_mapping[newKey] = column.import_field_number
      })
      if (condition_level) {
        header_field_mapping["condition_level"] = condition_level
      }
      actions.saveHeaderMappingImportFile({ header_field_mapping, file_load_id }, generateSmartRulesCallback)
      this.setState({
        headersMapped: true
      })
    };
    return (
      <div className="tableContainer">
        <div className="gridOfContainer">
          <FliptGrid
            data={this.props.state?.importDrugData?.import_drug_data}
            headers={this.props.state?.importDrugData?.import_file_columns}
          />
        </div>
        <div className="mapper-container">
          <FliptTable
            handleChange={handleChange}
            rowCellInfo={rowCellInfo}
            data={this.state.cols}
            cellRendererParams={cellRendererParams}
            headers={["FAST_field", "import_field_number"]}
          />
          {['PRIOR_AUTHORIZATION', 'PROGRAM_LIST'].includes(this.props.object_type) && <div className='conditions-option-container'>
            <span className='conditions-options-label'>{`If creating multi-condition level objects, select the column that will act as separator. If no value is selected, all data will be loaded as a single condition level:`}</span>
            <div className='conditions-option-dropdown-container'>
              <FliptDropdown
                placeholder="Select Option"
                value={this.state.condition_level}
                onChange={this.handleConditionLevelChange}
                options={options}
                name="object_type"
                clearable
              />
            </div>
            <div className="divider-tab" />
          </div>
          }
          <FliptButton name="Map Headers" onClick={submitHeaders} className="primary mapHeaders" />
        </div>
        {this.state.headersMapped ?
          <div className="gridOfContainer">
            <FliptGrid data={this.state.mappedData} headers={this.state.mappedDataCols} />
          </div>
          : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state: {
      user: state.user,
      importDrugData: state.formularyCreation.importDrugData,
      headerFieldMappingData: state.formularyCreation.headerFieldMappingData,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...UserActions,
    ...FormularyCreationActions
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableViewer)
