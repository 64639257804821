/* eslint-disable */
import React, { Component } from 'react'
import { Button, Icon, Popup } from 'semantic-ui-react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import BreadCrumb from '../../../../../../components/breadCrumb'
import { changeNodeAtPath } from '@nosferatu500/react-sortable-tree'
import FliptButton from '../../../../../../components/form/fliptButton'
import FliptCheckbox from '../../../../../../components/form/fliptCheckbox'
import FliptDropdown from '../../../../../../components/form/fliptDropdown'
import FliptInput from '../../../../../../components/form/fliptInput'
import FliptRadio from '../../../../../../components/form/fliptRadio'
import FliptTable from '../../../../../../components/fliptTable'
import FliptTree from '../../../../../../components/fliptTree'
import RolesModal from '../rolesModal'
import PROGRESSION from '../../../../../../config/constants/progression'
import { Creators as AppActions } from '../../../../../../redux/reducers/app'
import { Creators as CompanyManagementActions } from '../../../../../../redux/reducers/companyManagement'
import { Creators as NavigationActions } from '../../../../../../redux/reducers/navigation'
import { Creators as RolesActions } from '../../../../../../redux/reducers/roleManagement'
import { Creators as TeamManagementActions } from '../../../../../../redux/reducers/teamManagement'
import { Creators as UserActions } from '../../../../../../redux/reducers/user'
import { Creators as UserManagementActions } from '../../../../../../redux/reducers/userManagement'
import './styles.scss'
import FliptDatePicker from '../../../../../../components/form/fliptDatePicker'
import Config from '../../../../../../config'
import { parseQueryString, convertStrToDateObj } from '../../../../../../utils/utilities'
import ResetPasswordModal from '../resetPasswordModal'

class UserManagement extends Component {
  constructor(props) {
    super(props)

    const { history } = this.props
    this.state = {
      form: {
        additional_company_access: [],
        company: '',
        domains_access: [],
        effective_end_date: '',
        effective_start_date: '',
        email_address: '',
        enable_mfa: false,
        first_name: '',
        id: '',
        is_active: '',
        last_name: '',
        phone_number: '',
      },
      passwordResetForm: {
        confirm_password: '',
        new_password: '',
        uuid: parseQueryString(history.location.search)?.id || '',
      },
      companies: props?.state?.company_listing || [],
      companyData: {},
      disabledFields: [],
      editMode: true,
      hierarchyFullAccess: props?.state?.user?.hierarchyFullAccess,
      organization: props?.state?.user?.hierarchyAccess,
      roles: props?.state?.roles,
      rowUpdated: null,
      teams: props?.state?.teams_listing,
      // centerwellAdministrator: false || this?.props?.user?.role_key?.includes('centerwellAdministrator' || this?.props?.user?.role_key?.includes('centerwell_test_claim_generation')),
    }
  }

  componentDidMount() {
    const { actions, history: { location }, state: { company_listing = [] } } = this.props

    setTimeout(() => {
      actions.clearUserData()
      actions.getRoleManagementRoles()
      actions.getTeamListData()
      actions.getCompanyListData()
    }, Config.COMPONENT_DATA_LOAD_TIME)

    if (!location?.search) {
      this.state.companies = [...company_listing]
      return
    }

    const { id } = parseQueryString(location.search)

    const s = {
      editMode: id && !!location.state?.editMode,
      id,
    }

    this.setState(s)
    const payload = parseQueryString(location.search)
    setTimeout(() => {
      actions.userGet(payload)
    }, Config.COMPONENT_DATA_LOAD_TIME + 1500)
  }

  componentDidUpdate(prevProps) {
    const { props } = this
    const prevState = prevProps.state
    const currState = props.state
    const {
      user, roles, company_listing = [], teams_listing, loggedInUser,
    } = currState
    const { additional_company_access = [] } = user ?? {}
    const { disabledFields, centerwellAdministrator } = this.state
    // if (loggedInUser && loggedInUser.role_key && loggedInUser.role_key.includes('centerwellAdministrator') && (!disabledFields || disabledFields.length < 1)) {
    //   const company = _.cloneDeep(company_listing.filter((company) => [user.company, ...additional_company_access].includes(company.company_code)))
    //   this._setCenterwellDefaults()
    //   this._changeCompany(company)
    // }
    if (user && Object.keys(user).length && !_.isEqual(prevState.user, user)) {
      const company = _.cloneDeep(company_listing.filter((company) => [user.company, ...additional_company_access].includes(company.company_code)))
      this._changeCompany(company)
      this.updateForm(user)
    }
    if (!_.isEqual(prevState.roles, roles)) {
      this.updateRoles(roles)
    }
    if (!_.isEqual(prevState.company_listing, company_listing)) {
      this.updateCompanies(company_listing)
    }
    if (!_.isEqual(prevState.teams_listing, teams_listing)) {
      this.updateTeams(teams_listing)
    }
    if (!_.isEqual(prevState.user?.hierarchyAccess, user?.hierarchyAccess)) {
      const { hierarchyAccess, hierarchyFullAccess } = user
      if (hierarchyAccess && !centerwellAdministrator) {
        const company = _.cloneDeep(company_listing.filter((company) => [user.company, ...additional_company_access].includes(company.company_code)))
        const companyHierarchies = {}
        company.forEach(company => companyHierarchies[company.company_code] = company.hierarchy)
        this._updateTree(companyHierarchies, hierarchyAccess, hierarchyFullAccess)
      }
    }
  }
  _changeCompany = (company) => {
    const { centerwellAdministrator } = this.state;
    const { user } = this.props.state;
    const { hierarchyAccess, hierarchyFullAccess } = user
    const companyHierarchies = {}
    company.forEach(company => companyHierarchies[company.company_code] = company.hierarchy)
    if (!companyHierarchies && company.length === 0) return

    if (centerwellAdministrator || this.props?.state?.loggedInUser?.role_key?.includes('centerwellAdministrator')) {
      Object.entries(companyHierarchies).forEach(([company_code, hierarchy]) => Object.entries(hierarchy.map).forEach(([key, value]) => {
        if (value?.organization_id === 'FLIPTRX' || value?.client_id === 'FliptRx' || value?.carrier_id === 'FLIPT' || value?.account_id === 'NOVARTIS001' || value?.group_id === 'NOVARTISALL') {
          companyHierarchies[company_code].map[key].active = true
          companyHierarchies[company_code].map[key].access = true
        } else {
          companyHierarchies[company_code].map[key].active = false
          companyHierarchies[company_code].map[key].access = false
        }
      }))
    }
    this._updateTree(companyHierarchies, hierarchyAccess, hierarchyFullAccess)
  }
  _updateTree = (hierarchy, hierarchyAccess, hierarchyFullAccess) => {
    let hierarchyMap = {}
    Object.values(hierarchy).filter(h => h).forEach((hierarchy) => {
      Object.entries(hierarchy.map).forEach(([key, value]) => hierarchyMap[key] = value)
    })
    if (hierarchyAccess) {
      Object.values(hierarchyAccess).forEach((hierarchy) =>
        hierarchy.forEach(key => {
          if (hierarchyMap[key]) hierarchyMap[key]['access'] = true
        }))
    }
    if (hierarchyFullAccess) {
      Object.values(hierarchyFullAccess).forEach((hierarchy) =>
        hierarchy.forEach(key => {
          if (hierarchyMap[key]) hierarchyMap[key]['fullAccess'] = true
        }))
    }

    const newTree = [{
      title: 'Available Organizations',
      node_key: 'topLevel',
      children: [],
      access: Object.values(hierarchy).filter(h => h).map(h => h.map[h?.keyLevelMapping?.organization[0]]).every(x => x.access) ?? false,
      subtitle: '~',
    }]

    if (hierarchy) {
      const newData = { id: 'TOP', organization: _.cloneDeep(Object.values(hierarchy).filter(h => h).map(h => h.map[h?.keyLevelMapping?.organization[0]]).map(org => hierarchyMap[org.key])) }
      this._convertToTree(newData, newTree[0], hierarchyMap)
      this.setState((prevState) => ({ ...prevState, organization: newTree }))
    }
  }
  _convertToTree = (data, newTree, hierarchyMap) => {
    if (!data) return

    const keyData = Object.keys(data)
    if ((keyData.indexOf('id') > -1 || keyData.indexOf('name')) > -1 && keyData.length < 2) {
      return
    }

    Object.keys(data).forEach((el) => {
      if (Array.isArray(data[el])) {
        data[el].forEach((element, i) => {
          if (!element?.active) return
          newTree.children.push({
            title: element[element?.level + '_name'] ?? '',
            subtitle: element?.level,
            node_key: element?.key,
            access: element?.access ?? false,
            id: element[element?.level + '_id'] ?? '',
            children: [],
            fullAccess: element?.fullAccess ?? false,
          })
          Object.keys(element).forEach((e) => {
            if (Array.isArray(element[e])) {
              element[e] = element[e].map(x => hierarchyMap[x])
            }
          })
          this._convertToTree(element, newTree.children[newTree.children.length - 1], hierarchyMap)
        })
      }
    })
  }

  _teamsDisplay = () => {
    const { state, state: { editMode, disabledFields, centerwellAdministrator } } = this
    let teamsData = state.teams
    if (centerwellAdministrator) {
      teamsData = []
    }
    teamsData = teamsData.map((team, idx) => ({
      '': <FliptCheckbox value={idx} name={team.name} checked={team.enable} onClick={this._handleTeamsChange} disabled={!editMode || disabledFields.includes('teams')} />,
      team_name: team.name,
    }))

    return <FliptTable data={teamsData} />
  }

  _roleCheckboxDisplay = (role, idx, editMode, disabledFields) => {
    return (
      <Popup disabled={!(role?.inTeam > 0)} content='Cannot Remove: Role Associated with Team' trigger={
        <span>
          <FliptCheckbox value={idx} name={role.name} checked={role.enable || (role?.inTeam > 0)} onClick={this._handleRolesChange} disabled={!editMode || disabledFields.includes('roles') || (role?.inTeam > 0)} />
        </span>
      } />
    )
  }

  _rolesDisplay = () => {
    const { state, state: { editMode, disabledFields, centerwellAdministrator }, } = this
    let rolesData = state.roles
    if (centerwellAdministrator) {
      rolesData = rolesData.filter(role => ['centerwell_test_claim_generation'].includes(role.role_key)).map(role => ({ ...role, enable: true }))
    }
    rolesData = rolesData.map((role, idx) => ({
      '': this._roleCheckboxDisplay(role, idx, editMode, disabledFields),
      role: role.name,
    }))
    return <FliptTable data={rolesData} />
  }

  _updateDropdown = (evt, el) => {
    const { form } = this.state
    const { company_listing } = this.props.state
    const { company: userCompany, additional_company_access = [] } = form
    let company = []
    if (el.name === 'company') company = _.cloneDeep(company_listing.filter((company) => [el.value, ...additional_company_access].includes(company.company_code)))
    else company = _.cloneDeep(company_listing.filter((company) => [userCompany, ...el.value].includes(company.company_code)))
    this._changeCompany(company)
    form[el.name] = el.value
    this.setState({ form })
  }

  _enableMFA = () => {
    const { form } = this.state

    form.enable_mfa = !form.enable_mfa
    this.setState({ form })
  }

  _updateIsActive = () => {
    const { form } = this.state

    form.is_active = !form.is_active
    this.setState({ form })
  }

  _displayModal = () => {
    const { actions, state } = this.props
    actions.setModalComponent({
      modalProperties: { size: 'tiny' },
      contents: RolesModal({ roles: state.roles }),
    })

    actions.toggleModal()
  }

  _onChange = (el) => {
    this.setState({ organization: el })
  }

  _changeAccessChildren = (node, access) => {
    if (!node) return
    for (let i = 0; i < node.length; i++) {
      node[i].access = access
      this._changeAccessChildren(node[i].children, access)
    }
  }

  _selectAllChildren = (leaf) => {
    const { path, node } = leaf
    const access = !node.access
    node.access = access
    this._changeAccessChildren(node.children, access)
    this.setState((state) => ({
      organization: changeNodeAtPath({
        treeData: state.organization,
        path,
        getNodeKey: ({ treeIndex }) => treeIndex,
        newNode: node,
      }),
    }))
  }

  _changeAccessNode = (leaf) => {
    const { path, node } = leaf
    const access = !node.access
    node.access = access
    // this._changeAccessChildren(node.children, access)
    this.setState((state) => ({
      organization: changeNodeAtPath({
        treeData: state.organization,
        path,
        getNodeKey: ({ treeIndex }) => treeIndex,
        newNode: node,
      }),
    }))
  }

  _setCenterwellDefaults = () => {
    this.setState((state) => ({
      ...state,
      disabledFields: ['company', 'hierarchy', 'teams', 'roles'],
      form: {
        ...state.form,
        company: 'FLIPTRX',
      },
      centerwellAdministrator: true,
    }))
  }

  _updateFullAccess = (el, leaf) => {
    const { path, node } = leaf
    const fullAccess = el?.currentTarget?.checked
    node.fullAccess = fullAccess
    if (fullAccess) {
      this._changeAccessChildren(node.children, fullAccess)
      node.access = true
    }
    this.setState((state) => ({
      organization: changeNodeAtPath({
        treeData: state.organization,
        path,
        getNodeKey: ({ treeIndex }) => treeIndex,
        newNode: node,
      }),
    }))
  }

  generateNodeProps = (leaf) => {
    const returnedProps = {}
    const { node } = leaf
    returnedProps.buttons = [
      (node?.node_key !== "topLevel" && (
        <div className='hierarchy-slider-div'>
          <label className=''>Full Access</label>
          <label className="switch">
            <input type="checkbox" checked={node.fullAccess} onChange={(el) => this._updateFullAccess(el, leaf)} />
            <span className="slider round"></span>
          </label>
        </div>
      )),
      <Button className='hierarchy-select-all-button' onClick={() => { this._selectAllChildren(leaf) }}>Select All</Button>,
      <FliptCheckbox className='hierarchy-checkbox' checked={node.access} onClick={() => { this._changeAccessNode(leaf) }} />
    ]
    return returnedProps;
  }
  _rolesIcon = () => (
    <div onClick={this._displayModal} role="button" tabIndex={0}>
      <Icon disabled name="question circle outline" size="large" />
    </div>
  )

  _updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value,
      },
    }))
  }

  _handleRolesChange = (el) => {
    const { state } = this
    const roles = [...state.roles]
    const { name, checked, value } = el.currentTarget
    const rowToUpdate = { ...roles[value] }

    rowToUpdate.enable = checked
    roles[value] = rowToUpdate

    this.setState({
      roles,
      rowUpdated: value,
      columnUpdate: name,
    })
  }

  _handleTeamsChange = (el) => {
    const { state } = this
    const teams = [...state.teams]
    const roles = [...state.roles]
    const { name, checked, value } = el.currentTarget
    const rowToUpdate = { ...teams[value] }

    rowToUpdate.enable = checked
    teams[value] = rowToUpdate

    // `inTeam` Functionality
    // The `inTeam` field is a counter for each role that keeps track of how many selected teams contain a specific role
    // The business logic for roles that exist within a selected team is that the role should be selected and disabled thus there needs to be a counter to determine if a role should be disabled/selected
    rowToUpdate.roles.forEach(({ id }) => {
      const index = roles.findIndex(x => x.id === id)
      if (index > -1) {
        if (checked) {
          if (_.isUndefined(roles[index]?.inTeam)) roles[index].inTeam = 0
          roles[index].inTeam += 1
        } else {
          if (_.isUndefined(roles[index]?.inTeam)) roles[index].inTeam = 1
          roles[index].inTeam -= 1
        }
      }
    })

    this.setState({
      teams,
      roles,
      rowUpdated: value,
      columnUpdate: name,
    })
  }
  // _convertTreeToFlat = (data, newHierarchy, hierarchyFullAccess) => {
  //   if (!data) return
  //   for (let i = 0; i < data.length; i++) {
  //     if (!data[i]) {
  //       return
  //     }
  //     const idx = PROGRESSION.indexOf(data[i]?.subtitle)
  //     if (idx !== -1) {
  //       if (data[i]?.access) newHierarchy.push(data[i]?.node_key)
  //       if (data[i]?.fullAccess) hierarchyFullAccess.push(data[i].node_key)
  //       this._convertTreeToFlat(data[i].children, newHierarchy, hierarchyFullAccess)
  //     }
  //   }
  // }

  _getPathKeys = (node, cH, fH, fA, goBack = false) => { // cH for Current Hierarchy, fH for Final Hierarchy, fA for Full Access
    const { access, node_key, children, fullAccess } = node
    if (access) {
      cH.add(node_key)
      cH.forEach(x => {
        fH.add(x)
        cH.delete(x)
      })
    }
    if (fullAccess) {
      fA.add(node_key)
    }
    if (!children || (Array.isArray(children) && children.length < 1)) {
      cH.delete(node_key)
      if (access) goBack = true
      return
    } else {
      cH.add(node_key)
      children.forEach(c => {
        this._getPathKeys(c, cH, fH, fA, goBack)
        if (goBack) {
          goBack = false
          return
        }
      })
      cH.delete(node_key)
      return
    }
  }

  _submitForm = () => {
    const { props, state, state: { old_roles } } = this
    const { form, roles, teams, organization, centerwellAdministrator } = state
    let rolesData = roles // roles.filter((d) => d.enable).map((d) => ({ id: d.id }))
    // if (centerwellAdministrator) rolesData.find(x => x.role_key === 'centerwell_test_claim_generation').enable = true
    const teamsData = teams // teams.filter((d) => d.enable).map((d) => ({ id: d.id }))

    let newHierarchy = {}
    let newFullAccess = {}
    organization[0].children.forEach(org => {
      const finalHierarchy = new Set()
      const currentHierarchy = new Set()
      const fullAccess = new Set()
      let goBack = false
      this._getPathKeys(org, currentHierarchy, finalHierarchy, fullAccess, goBack)
      newHierarchy[props.state.company_listing.find(company => company?.hierarchy?.map[company.hierarchy.keyLevelMapping.organization[0]]?.organization_id === org?.id).company_code] = Array.from(finalHierarchy)
      newFullAccess[props.state.company_listing.find(company => company?.hierarchy?.map[company.hierarchy.keyLevelMapping.organization[0]]?.organization_id === org?.id).company_code] = Array.from(fullAccess)
    })
    const payload = {
      ...form,
      roles: rolesData,
      teams: teamsData,
      hierarchyAccess: newHierarchy,
      hierarchyFullAccess: newFullAccess,
      new_link: true,
    }

    if (state.editMode && state.id) {
      props.actions.editUserManagementUser({
        ...payload,
        uuid: state.id,
      })
    } else {
      props.actions.createUserManagementUser(payload)
    }
  }

  _resetPassword = () => {
    const { actions } = this.props

    actions.setModalComponent({
      modalProperties: { size: 'tiny' },
      contents: ResetPasswordModal({ updatePasswordForm: this.updatePasswordForm, passwordReset: this.passwordReset }),
    })
    actions.toggleModal()
  }

  updatePasswordForm = (e) => {
    const { passwordResetForm } = this.state
    passwordResetForm.new_password = e.currentTarget.value
    passwordResetForm.confirm_password = e.currentTarget.value

    this.setState({ passwordResetForm })
  }

  passwordReset = () => {
    const { actions } = this.props
    const { passwordResetForm } = this.state

    actions.changeUserManagementPassword(passwordResetForm)
    actions.toggleModal()
  }

  updateForm = (user) => {
    const { state, actions } = this.props
    const { form } = this.state
    const formData = Object.keys(form).reduce((acc, curr) => {
      acc[curr] = user[curr]

      return acc
    }, {})
    formData.id = state?.id
    const {
      roles, teams, companies, hierarchyAccess,
    } = user
    const rolesData = roles?.length ? roles : state.roles
    const teamsData = teams?.length ? teams : state.teams_listing
    teamsData.forEach((team) => {
      if (team.enable) {
        team.roles.forEach(({ id }) => {
          const index = roles.findIndex(x => x.id === id)
          if (index > -1) {
            if (_.isUndefined(roles[index]?.inTeam)) roles[index].inTeam = 0
            roles[index].inTeam += 1
          }
        })
      }
    })
    const companiesData = companies?.length ? companies : state.company_listing
    actions.getCompanyListData()
    this.setState((prevState) => ({
      ...prevState,
      form: formData,
      roles: rolesData,
      companies: companiesData,
      teams: teamsData,
      old_roles: roles,
    }))
  }

  updateCompanies(currCompanies = []) {
    this.setState((prevState) => ({
      ...prevState,
      companies: [...currCompanies],
    }))
  }

  updateTeams(currTeams = []) {
    this.setState((prevState) => ({
      ...prevState,
      teams: [...currTeams],
    }))
  }

  updateRoles(currRoles = []) {
    this.setState((prevState) => ({
      ...prevState,
      roles: [...currRoles],
    }))
  }

  render() {
    const { props, state } = this
    const {
      form, editMode, companies = [], disabledFields, centerwellAdministrator,
    } = state
    const ud = form

    // const domainsOptions = domains_access.map((domain) => ({ key: domain.id, text: domain.name, value: domain.id }))
    // Use company_code or company_short_name for the values
    const availableCompanies = companies.map((company) => ({ id: company.company_code, text: company.company_full_name, value: company.company_code }))
    if (centerwellAdministrator) {
      availableCompanies = availableCompanies.filter(company => company.id === 'FLIPTRX')
    }
    let headerText = editMode ? 'Create User' : 'View User'
    const { location: { search } } = props.history
    if (search && editMode) headerText = 'Edit User'

    return (
      <div id="createUser">
        <BreadCrumb {...this.props} />
        <div className="header">
          <h1>{headerText}</h1>
        </div>
        <div className="enclosing-container">
          <span className="field-name">User Information</span>
          <div className="create-user-section">
            <div className="top-fields">
              <div className="fields">
                <div className="fields-label">First Name</div>
                <FliptInput className="fields-input" name="first_name" defaultValue={ud.first_name} onChange={this._updateFields} required readOnly={!editMode} />
              </div>
              <div className="fields">
                <div className="fields-label">Last Name</div>
                <FliptInput className="fields-input" name="last_name" defaultValue={ud.last_name} onChange={this._updateFields} required readOnly={!editMode} />
              </div>
              <div className="fields">
                {/* <div className="fields-label">Effective Start Date</div>
                <FliptDatePicker className="fields-input" value={convertStrToDateObj(ud.effective_start_date)} name="effective_start_date" onChange={this._updateFields} readOnly={!editMode} /> */}
              </div>
              <div className="fields">
                {/* <div className="fields-label">Effective End Date</div>
                <FliptDatePicker className="fields-input" value={convertStrToDateObj(ud.effective_end_date)} name="effective_end_date" onChange={this._updateFields} readOnly={!editMode} /> */}
              </div>
              <div className="fields">
                <div className="fields-label">Change Password</div>
                <FliptButton name="change_password" className="primary" onClick={this._resetPassword} disabled={!(editMode && search)} />
              </div>
            </div>
            <div className="bottom-fields">
              <div className="fields">
                <div className="fields-label">Email Address</div>
                <FliptInput className="fields-input" defaultValue={ud.email_address} name="email_address" onChange={this._updateFields} required validation="email_address" readOnly={!editMode} />
              </div>
              <div className="fields">
                <div className="fields-label">Phone Number</div>
                <FliptInput className="fields-input" defaultValue={ud.phone_number} name="phone_number" onChange={this._updateFields} required readOnly={!editMode} />
              </div>
              <div className="fields">
                <div className="fields-label">Company</div>
                {!!availableCompanies.length && <FliptDropdown value={ud.company} name="company" options={availableCompanies} selection onChange={this._updateDropdown} readOnly={!editMode || disabledFields.includes('company')} />}
              </div>
              <div className="fields">
                <div className="fields-label" style={{ whiteSpace: 'nowrap' }} >Additional Company Access</div>
                {!!availableCompanies.length && <FliptDropdown classname="dropdown" multiple selection simple item value={ud.additional_company_access} name="additional_company_access" options={availableCompanies.filter(x => x.value != ud.company)} onChange={this._updateDropdown} readOnly={!editMode || disabledFields.includes('company')} />}
              </div>
              <div className="fields">
                <div className="fields-label">MFA Enabled</div>
                <FliptRadio toggle label="MFA Enabled" checked={ud.enable_mfa} name="enable_mfa" onChange={this._enableMFA} readOnly={!(editMode && search)} />
              </div>
              <div className="fields">
                <div className="fields-label">Is Active</div>
                <FliptRadio toggle label="Active" name="is_active" checked={ud.is_active} onChange={this._updateIsActive} readOnly={!(editMode && search)} />
              </div>
            </div>
          </div>
          <div className="lower-section">
            <div className="hierarchy-section">
              <div className="field-name">Hierarchy</div>
              <div className="hierarchy-tree" disabled={!editMode || disabledFields?.includes('hierarchy')}>
                <FliptTree className={'hierarchy-tree'} style={{ height: 250 }} generateNodeProps={this.generateNodeProps} onChange={this._onChange} treeData={this.state.organization} canDrag={false} scrolling readOnly={!editMode} />
              </div>
            </div>
            <div className="roles-section">
              <div className="field-name">Roles</div>
              <div className="roles-table">
                {this._rolesDisplay()}
              </div>
            </div>
            <div className="teams-section">
              {/* <div className="field-name">Teams</div>
              <div className="teams-table">
                {this._teamsDisplay()}
              </div> */}
            </div>
          </div>
          <div className="buttons-container">
            {editMode && <FliptButton name="Save" className="primary" onClick={this._submitForm} />}
            <FliptButton name="Back" className="secondary" onClick={props.history.goBack} />
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    company_data: state.companyManagement.companyData,
    company_listing: state.companyManagement.companyListing,
    data: state.userManagement.data,
    domains_access: state.user.domains_access,
    loggedInUser: state.user,
    roles: state.roleManagement.roleListing,
    teams_listing: state.teamManagement.teamListing,
    user: state.user.userData,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...CompanyManagementActions,
    ...NavigationActions,
    ...RolesActions,
    ...TeamManagementActions,
    ...UserActions,
    ...UserManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement)
