import React, { Component, useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Creators as PlanManagementActions } from '../../../../redux/reducers/api/planManagement'
import './styles.scss'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { DatePickerEditor, DropdownEditor } from '../../../../components/fliptGrid/cellEditors'
import { paHeader, programHeader, qlHeader, rejectCodeOptions, rejectMessage, stHeader } from './data'
import { DeleteRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import moment from 'moment-timezone'
import FliptButton from '../../../../components/v2/fliptButton'
import FliptGrid from '../../../../components/fliptGrid'
import { paRowCellInfo, programRowCellInfo, qlRowCellInfo, stRowCellInfo } from './rowCellInfo'
import FliptInput from '../../../../components/v2/fliptInput'
import FliptDropdown from '../../../../components/v2/fliptDropdown'
import { resolveMessage } from '../../../../utils/validationHelpers'



const UtiliziationMgmt = (props) => {
    const { actions, fieldDetails, modelData, copyFormData } = props
    const [form, setForm] = useState({
        ...modelData,
        pa_ingredient_cost_limit_30: modelData?.pa_ingredient_cost_limit_30 || '',
        pa_ingredient_cost_limit_60: modelData?.pa_ingredient_cost_limit_60 || '',
        pa_ingredient_cost_limit_90: modelData?.pa_ingredient_cost_limit_90 || '',
        maximum_claim_action: modelData?.maximum_claim_action || '',
        maximum_claim_cost: modelData?.maximum_claim_cost || 0,
        maximum_claim_cost_basis: modelData?.maximum_claim_cost_basis || '',
        pa_reject_code: modelData?.pa_reject_code || '',
        pa_reject_message: modelData?.pa_reject_message || '',
        clinical_pa: modelData?.clinical_pa?.length ? modelData?.clinical_pa : [{ pa_name: '', pa_id: '', effective_begin_date: '', effective_end_date: '' }],
        clinical_ql: modelData?.clinical_ql?.length ? modelData?.clinical_ql : [{ ql_name: '', ql_id: '', effective_begin_date: '', effective_end_date: '' }],
        clinical_step_therapy: modelData?.clinical_step_therapy?.length ? modelData?.clinical_step_therapy : [{ step_therapy_name: '', step_therapy_id: '', effective_begin_date: '', effective_end_date: '' }],
        programs: modelData?.programs?.length ? modelData?.programs : [{ program_name: '', program_id: '', effective_begin_date: '', effective_end_date: '' }]
    })

    const getDropdownOptions = (options) => options.map((option, index) => ({ key: index + 1, text: option, value: option }))

    const [formOptions, setFormOptions] = useState({
        costBasisOptions: [],
        costActionOptions: [],
    })

    const [dropdownOptions, setDropdownOptions] = useState({
        programs: [],
        clinical_step_therapy: [],
        clinical_ql: [],
        clinical_pa: []
    })

    useEffect(() => {
        const tempDropdownOptions = {}
        Object.keys(dropdownOptions).map((key) => {
            let obj = fieldDetails.find(o => o.field === key);
            let tempOptions = obj.options;
            tempOptions = [...new Set(tempOptions.map((data, index) => ({
                key: index + 1,
                text: data.display_name,
                value: data.value,
                id: data.doc_id
            })))];
            tempDropdownOptions[key] = tempOptions
            if (key === 'programs') programRowCellInfo['program_name'].options = tempOptions
            if (key === 'clinical_step_therapy') stRowCellInfo['step_therapy_name'].options = tempOptions
            if (key === 'clinical_ql') qlRowCellInfo['ql_name'].options = tempOptions
            if (key === 'clinical_pa') paRowCellInfo['pa_name'].options = tempOptions
            setDropdownOptions(tempDropdownOptions)
        })
        let optionObj = fieldDetails.find(o => o.field === 'maximum_claim_cost_basis');
        let costBasisOptions = [...new Set(optionObj.options.map((data, index) => ({
            key: index + 1,
            text: data.display_name,
            value: data.value,
        })))];
        optionObj = fieldDetails.find(o => o.field === 'maximum_claim_action');
        let costActionOptions = [...new Set(optionObj.options.map((data, index) => ({
            key: index + 1,
            text: data.display_name,
            value: data.value,
        })))];
        setFormOptions({ costBasisOptions, costActionOptions })
    }, [])

    const getKeyName = (data) => {
        const keyDataPair = {
            pa_name: 'clinical_pa',
            ql_name: 'clinical_ql',
            step_therapy_name: 'clinical_step_therapy',
            program_name: 'programs'
        }
        const keyIdPair = {
            pa_name: 'pa_id',
            ql_name: 'ql_id',
            step_therapy_name: 'step_therapy_id',
            program_name: 'program_id'
        }
        let formKeyName = ''
        let formKeyId = ''
        Object.keys(data).some((key) => {
            if (key.includes('name')) {
                formKeyName = keyDataPair[key]
                formKeyId = keyIdPair[key]
                return
            }
        })
        return ({ formKeyName, formKeyId })

    }
    const setTheForm = (newForm) => {
        copyFormData(newForm)
        setForm(newForm)
    }

    const handleChange = (e, dropdown, rowIndex, api, stepLevel, data) => {
        const { formKeyName, formKeyId } = getKeyName(data)
        const newForm = { ...form }
        const newGridData = [...newForm[formKeyName]]
        const { name, value } = dropdown || e.currentTarget
        // this was the dream. but alas our date picker has its own internal state
        // so it will appear to the user as if date has already been set despite the error message
        // if (name === 'effective_end_date' || name === 'effective_begin_date') {
        //     const end_date = name === 'effective_end_date' ? value : newGridData[rowIndex]['effective_end_date']
        //     const being_date = name === 'effective_begin_date' ? value : newGridData[rowIndex]['effective_begin_date']
        //     if (being_date && end_date && moment(end_date).isBefore(moment(being_date))) {
        //         const transitionalPortal = {
        //             header: 'Begin Date must be before end date',
        //             copy: 'Begin Date must be before end date',
        //         }
        //         actions.displayTransitionalPortal(transitionalPortal)
        //         value = ''
        //     }
        // }
        if (name.includes('name')) {
            newGridData[rowIndex][formKeyId] = dropdownOptions[formKeyName].filter(function (el) {
                return el.value == value
            })[0]?.id;
        }
        newGridData[rowIndex][name] = value
        newForm[formKeyName] = newGridData
        setTheForm(newForm)
    }

    const delGridRow = (rowIndex, stepLevel, data) => {
        const { formKeyName } = getKeyName(data)
        const newForm = { ...form }
        const newGridData = [...newForm[formKeyName]]
        newGridData.splice(rowIndex, 1)
        newForm[formKeyName] = newGridData
        setTheForm(newForm)
    }

    const dateEditor = {
        effective_begin_date: {
            cellEditor: DatePickerEditor,
            onChange: handleChange,
        },
        effective_end_date: {
            cellEditor: DatePickerEditor,
            onChange: handleChange,
        }
    }
    const qlEditorParams = {
        ...dateEditor,
        ql_name: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
        }
    }
    const stEditorParams = {
        ...dateEditor,
        step_therapy_name: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
        }
    }
    const programEditorParams = {
        ...dateEditor,
        program_name: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
        }
    }
    const paEditorParams = {
        ...dateEditor,
        pa_name: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
        }
    }
    const cellRendererParams = {
        action: {
            cellRenderer: DeleteRowRenderer,
            state: {
                onClick: delGridRow,
            },
            width: 95,
        },
        effective_begin_date: {
            valueFormatter: (params) => {
                if (!params.value) return ''
                return moment(params.value)?.format('YYYY-MM-DD') || params.value
            },
        },
        effective_end_date: {
            valueFormatter: (params) => {
                if (!params.value) return ''
                return moment(params.value)?.format('YYYY-MM-DD') || params.value
            },
        },
    }

    const paCellRendererParams = {
        ...cellRendererParams,
        pa_name: {
            overrideHeader: 'Prior Authorization Name',
        }
    }

    const qlCellRendererParams = {
        ...cellRendererParams,
        ql_name: {
            overrideHeader: 'Quantity Limit Name',
        }
    }

    const _showTransitionalPortal = (header, message) => {
        const transitionalPortal = {
            header: header,
            copy: message,
        }
        actions.displayTransitionalPortal(transitionalPortal)
    }

    const handleFormChange = (e, dropdown) => {
        const newForm = { ...form }
        const { name, value } = dropdown || e.currentTarget
        if (name === 'pa_reject_code') newForm.pa_reject_message = rejectMessage[value]
        newForm[name] = value
        setTheForm(newForm)
    }

    const addGridRow = (formType, nameKey, idKey) => {
        const tempFormData = { ...form }
        const tempFormValue = [...tempFormData[formType]]
        const index = tempFormValue?.length >= 1 ? tempFormValue.length - 1 : 0
        if (tempFormValue?.length >= 1 && (!tempFormValue[index][nameKey] || !tempFormValue[index].effective_begin_date)) {
            _showTransitionalPortal(resolveMessage("val_missing_name_start_date").message)
            return
        }
        tempFormValue.push({
            [idKey]: '', [nameKey]: '',
            effective_begin_date: '',
            effective_end_date: ''
        })
        tempFormData[formType] = tempFormValue
        setTheForm(tempFormData)
    }

    return (
        <div id="utilizationMgmt">
            <span>Utilization Management</span>
            <div className='header-container'>
                <span>Programs</span>
                <FliptButton name='Add+' className='primary' onClick={() => addGridRow('programs', 'program_name', 'program_id')} />
            </div>
            <div className="grid-container">
                <FliptGrid
                    data={form?.programs || []}
                    headers={programHeader}
                    cellEditorParams={programEditorParams}
                    rowCellInfo={programRowCellInfo}
                    cellRendererParams={cellRendererParams}
                />
            </div>
            <div className='header-container'>
                <span>Quantity Limit</span>
                <FliptButton name='Add+' className='primary' onClick={() => addGridRow('clinical_ql', 'ql_name', 'ql_id')} />
            </div>
            <div className="grid-container">
                <FliptGrid
                    data={form?.clinical_ql || []}
                    headers={qlHeader}
                    cellEditorParams={qlEditorParams}
                    rowCellInfo={qlRowCellInfo}
                    cellRendererParams={qlCellRendererParams}
                />
            </div>
            <div className='header-container'>
                <span>Step Therapy</span>
                <FliptButton name='Add+' className='primary' onClick={() => addGridRow('clinical_step_therapy', 'step_therapy_name', 'step_therapy_id')} />
            </div>
            <div className="grid-container">
                <FliptGrid
                    data={form?.clinical_step_therapy || []}
                    headers={stHeader}
                    cellEditorParams={stEditorParams}
                    rowCellInfo={stRowCellInfo}
                    cellRendererParams={cellRendererParams}
                />
            </div>
            <div className='header-container'>
                <span>Clinical Prior Authorization</span>
                <FliptButton name='Add+' className='primary' onClick={() => addGridRow('clinical_pa', 'pa_name', 'pa_id')} />
            </div>
            <div className="grid-container">
                <FliptGrid
                    data={form?.clinical_pa || []}
                    headers={paHeader}
                    cellEditorParams={paEditorParams}
                    rowCellInfo={paRowCellInfo}
                    cellRendererParams={paCellRendererParams}
                />
            </div>
            <div className='field-container'>
                <FliptInput stylized datatype="numeric" label='PA Ingredient Cost Limit 30' onChange={handleFormChange} name='pa_ingredient_cost_limit_30' value={form.pa_ingredient_cost_limit_30} />
                <FliptInput stylized label='Maximum Claim Cost' onChange={handleFormChange}
                    iconPosition='left' name='maximum_claim_cost' semantic value={form.maximum_claim_cost} datatype='numeric' />
                <FliptDropdown stylized options={formOptions.costBasisOptions} label='Cost Basis' onChange={handleFormChange} name='maximum_claim_cost_basis' value={form.maximum_claim_cost_basis} />
                <FliptDropdown stylized options={formOptions.costActionOptions} label='Action' onChange={handleFormChange} name='maximum_claim_action' value={form.maximum_claim_action} />
            </div>
            <div className='field-container'>
                <FliptInput stylized datatype="numeric" label='PA Ingredient Cost Limit 60' onChange={handleFormChange} name='pa_ingredient_cost_limit_60' value={form.pa_ingredient_cost_limit_60} />
                <FliptInput stylized datatype="numeric" label='PA Ingredient Cost Limit 90' onChange={handleFormChange} name='pa_ingredient_cost_limit_90' value={form.pa_ingredient_cost_limit_90} />
                <FliptDropdown stylized options={getDropdownOptions(rejectCodeOptions)} label='PA Reject Code' onChange={handleFormChange} name='pa_reject_code' value={form.pa_reject_code} />
                <FliptInput stylized label='PA Reject Message' disabled name='pa_reject_message' value={form.pa_reject_message} />
            </div>
        </div>

    )
}


const mapStateToProps = (state) => ({
    state: {
        contractSummaryData: state.contractManagement.contractSummaryData,
        contractData: state.contractManagement.contractData,
        allNetworkData: state.networkTierCreation.allNetworkTiersData,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...PlanManagementActions
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UtiliziationMgmt)

