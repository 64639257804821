// QTY = Quantity
const getQtyQl = (qlDetails, id) => {
  const { quantity_max_amount } = qlDetails
  let ql = {}
  if (quantity_max_amount) {
    ql = {
      id,
      is_published: false,
      lookback: '',
      ql_subtype: '',
      ql_type: 'per_prescription',
      subtype: 'max_quantity',
      value: String(quantity_max_amount)
    }
  }
  return ql
}

// DD = 'Daily Dose'
const getDdQl = (qlDetails, id) => {
  const daily_dose_max_amount_time_period_units = Number(qlDetails.daily_dose_max_amount_time_period_units)
  const daily_dose_max_amount = qlDetails.daily_dose_max_amount
  let ql = {}
  if (daily_dose_max_amount_time_period_units) {
    if (daily_dose_max_amount_time_period_units !== 1) {
      ql = {
        id,
        is_published: false,
        lookback: daily_dose_max_amount_time_period_units,
        ql_type: 'per_period',
        subtype: 'max_quantity',
        value: daily_dose_max_amount
      }
    } else {
      ql = {
        id,
        is_published: false,
        lookback: '',
        ql_type: 'per_day',
        subtype: 'ql_ratio',
        value: daily_dose_max_amount
      }
    }
  }
  return ql
}

// DS = Days Supply
const getDsQl = (qlDetails, id) => {
  const days_supply_max_amount = Number(qlDetails.days_supply_max_amount)
  let ql = {}
  if (days_supply_max_amount) {
    ql = {
      id,
      is_published: false,
      lookback: '',
      ql_subtype: '',
      ql_type: 'per_prescription',
      subtype: 'max_days_supply',
      value: String(days_supply_max_amount)
    }
  }
  return ql
}

// PTD = Prescribed to Date
const getPtdQl = (qlDetails, id) => {
  const {
    ptd_quantity_max_amount,
    ptd_quantity_max_amt_time_period,
    ptd_quantity_max_amt_time_period_units
  } = qlDetails
  let ql = {}
  if (ptd_quantity_max_amount) {
    let lookback = ''
    if (ptd_quantity_max_amt_time_period === 'DY') {
      // DY = days
      lookback = ptd_quantity_max_amt_time_period_units
    } else if (ptd_quantity_max_amt_time_period === 'LT') {
      // LT = LifeTime
      lookback = '99999'
    }
    ql = {
      id,
      is_published: false,
      lookback,
      ql_subtype: '',
      ql_type: 'per_prescription',
      subtype: 'max_quantity',
      value: ptd_quantity_max_amount
    }
  }
  return ql
}

// PTDF = Prescribed to Date Fills
const getPtdfQl = (qlDetails, id) => {
  const {
    ptd_fills_max_amount,
    ptd_fills_max_amount_time_period,
    ptd_fills_max_amount_time_period_units
  } = qlDetails
  let ql = {}
  if (ptd_fills_max_amount) {
    let lookback = ''
    if (ptd_fills_max_amount_time_period === 'DY') {
      // DY = days
      lookback = ptd_fills_max_amount_time_period_units
    } else if (ptd_fills_max_amount_time_period === 'LT') {
      // LT = LifeTime
      lookback = '99999'
    }
    ql = {
      id,
      is_published: false,
      lookback,
      ql_subtype: '',
      ql_type: 'per_prescription',
      subtype: 'max_fills',
      value: ptd_fills_max_amount
    }
  }
  return ql
}

// PTDS = Prescriber to Date Supply
const getPtdsQl = (qlDetails, id) => {
  const {
    ptd_ds_max_amount,
    ptd_ds_max_amount_time_period,
    ptd_ds_max_amount_time_period_units
  } = qlDetails
  let ql = {}
  if (ptd_ds_max_amount) {
    let lookback = ''
    if (ptd_ds_max_amount_time_period === 'DY') {
      // DY = days
      lookback = ptd_ds_max_amount_time_period_units
    } else if (ptd_ds_max_amount_time_period === 'LT') {
      // LT = LifeTime
      lookback = '99999'
    }
    ql = {
      id,
      is_published: false,
      lookback,
      ql_subtype: '',
      ql_type: 'per_prescription',
      subtype: 'max_days_supply',
      value: ptd_ds_max_amount
    }
  }
  return ql
}

// ERX = Electronically Prescribed (Probably)
const getErxQl = (qlDetails, id) => {
  const {
    max_amount,
    max_amount_qualifier,
    max_time_period_units,
    max_amount_time_period
  } = qlDetails
  let ql = {}
  if (max_amount_qualifier) {
    if (max_amount_qualifier === 'QY') {
      // QY = Quantity
      if (max_amount_time_period === 'DY') {
        // DY = Days
        if (max_time_period_units !== 1) {
          ql = {
            id,
            is_published: false,
            lookback: max_time_period_units,
            ql_type: 'per_period',
            subtype: 'max_quantity',
            value: max_amount
          }
        } else {
          ql = {
            id,
            is_published: false,
            lookback: '',
            ql_type: 'per_day',
            subtype: 'ql_ratio',
            value: max_amount
          }
        }
      } else if (max_amount_time_period === 'PD') {
        // PD = Per Dispense
        qlCount += 1
        ql = {
          id,
          is_published: false,
          lookback: '',
          ql_type: 'per_prescription',
          subtype: 'max_quantity',
          value: max_amount
        }
      }
    } else if (max_amount_qualifier === 'DS') {
      if (max_amount_time_period == 'DY') {
        // DY = Days
        qlCount += 1
        ql = {
          id,
          is_published: false,
          lookback: max_time_period_units,
          ql_type: 'per_period',
          subtype: 'max_days_supply',
          value: max_amount
        }
      } else if (max_amount_time_period == 'PD') {
        // PD = Per Dispense
        qlCount += 1
        ql = {
          id,
          is_published: false,
          lookback: '',
          ql_type: 'per_prescription',
          subtype: 'max_days_supply',
          value: max_amount
        }
      }
    }
  }
  return ql
}

const qlParserMapping = {
  QTY: getQtyQl,
  DD: getDdQl,
  DS: getDsQl,
  PTD: getPtdQl,
  PTDF: getPtdfQl,
  PTDS: getPtdsQl,
  ERX: getErxQl,
}

// we don't scan for ERX because it can only be in 'quantity_limits_type' column
// ask ERX does not have it's own dedicated columns
const typesOfQLToScan = ['QTY', 'DD', 'DS', 'PTD', 'PTDF', 'PTDS']

export const parseQLData = (conditionsDataWithLevels) => {
  const mainQLColumn = 'quantity_limits_type'
  const qlObjects = []
  conditionsDataWithLevels.forEach((level) => {
    const qlDetails = level.find((x) => !!x[mainQLColumn])
    if (!qlDetails) {
      return []
    }
    const conditions = []
    let qlIdCount = 1
    const logic_tree = ['OR']
    const mainQlColumnValue = qlDetails[mainQLColumn]
    const mainQl = qlParserMapping[mainQlColumnValue].call(this, qlDetails, `ql${qlIdCount}`)
    if (Object.keys(mainQl).length) {
      conditions.push(mainQl)
      logic_tree.push(mainQl.id)
      qlIdCount++
    }
    typesOfQLToScan.forEach((qlType) => {
      if (qlType === mainQlColumnValue) return
      const ql = qlParserMapping[qlType].call(this, qlDetails, `ql${qlIdCount}`)
      if (Object.keys(ql).length) {
        conditions.push(ql)
        logic_tree.push(ql.id)
        qlIdCount++
      }
    })

    qlObjects.push({
      criteria: conditions.map((condition) =>
      ({
        conditions: [],
        logic_tree: ['AND'],
        parent_id: condition.id
      })),
      is_published: false,
      parent_criteria: {
        conditions,
        logic_tree,
      },
      parent_type: 'ql',
    })
  })
  return qlObjects
}
