import {
  call,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects'

import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { Creators as NavigationActions } from '../../reducers/navigation'
import { Types, Creators as contractActions } from '../../reducers/api/contractManagement'
import { fetchGet, fetchPost, fetchPut } from '../../../utils/fetchApi'
import { getAppState, getApiPath, isMenuEmpty } from '../../reducers/selectors'

export default [
  createNewVersionWatcher,
  createProcessorWatcher,
  getContractDataWatcher,
  getContractSummaryDataWatcher,
  getProcessorDataWatcher,
  publishDocWatcher,
  putContractDataWatcher,
  updateProcessorWatcher
]

function* getContractSummaryDataWatcher() {
  yield takeLatest(Types.GET_CONTRACT_SUMMARY_DATA, getSummaryDataHandler)
}

function* createProcessorWatcher() {
  yield takeLatest(Types.CREATE_PROCESSOR, createProcessorHandler)
}

function* updateProcessorWatcher() {
  yield takeLatest(Types.UPDATE_PROCESSOR, updateProcessorHandler)
}

function* getProcessorDataWatcher() {
  yield takeLatest(Types.GET_PROCESSOR_DATA, getProcessorHandler)
}

function* getContractDataWatcher() {
  yield takeLatest(Types.GET_CONTRACT_DATA, getDataHandler)
}

function* putContractDataWatcher() {
  yield takeLatest(Types.PUT_CONTRACT_DATA, putContractDataHandler)
}

function* publishDocWatcher() {
  yield takeLatest(Types.PUBLISH_DOC, publishDocHandler)
}
function* createNewVersionWatcher() {
  yield takeLatest(Types.CREATE_NEW_CONTRACT_VERSION, createNewVersionHandler)
}


function* getSummaryDataHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'contract-management-summary')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchGet, url, payload)
    yield put(contractActions.setContractSummaryData({ data: response?.data }))
  } catch (err) {
    console.log('getSummaryDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Getting Contract Management Summary Data Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getDataHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'contract-management-type')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchGet, url, payload)
    const data = response?.data?.length > 0 ? response?.data[0] : {}
    yield put(contractActions.setContractData({ data }))
  } catch (err) {
    console.log('getDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Getting Contract Management Data Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* putContractDataHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'contract-management-add')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPut, url, payload)
    yield put(contractActions.setContractData({ data: response?.data || {} }))
    const transitionalPortal = {
      header: 'Updated Successfully',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    yield put(NavigationActions.navigateTo({ pathname: '/contract-management-summary' }))
  } catch (err) {
    console.log('putContractDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Put Contract Config Data Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}


function* updateProcessorHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'processor-update')
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPut, url, payload)
    if (data.length) {
      const transitionalPortal = {
        header: 'Update Processor Successful',
        copy: 'Processor Details Updated for given ID',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      if (payload.status === 'Published') yield put(contractActions.saveProcessorUpdateData(data[0]))
      else yield put(NavigationActions.navigateTo({ pathname: '/contract-management-summary', state: { isProcessor: true } }))
    } else {
      const transitionalPortal = {
        header: 'Update Processor Failed',
        copy: 'Error in creating new Processor',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }

  } catch (err) {
    console.log('updateProcessorHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Create Processor Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* createProcessorHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'create-processor')
    const url = `${serviceUrl}${api_path}`
    const { data } = yield call(fetchPost, url, payload)
    if (data.length) {
      const transitionalPortal = {
        header: 'Create Processor Successful',
        copy: 'Processor Created with Id ' + data[0].doc_id,
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
      if (payload.status === 'Published') yield put(contractActions.saveProcessorUpdateData(data[0]))
      yield put(NavigationActions.navigateTo({ pathname: '/contract-management-summary', state: { isProcessor: true } }))
    } else {
      const transitionalPortal = {
        header: 'Create Processor Failed',
        copy: 'Error in creating new Processor',
      }
      yield put(AppActions.displayTransitionalPortal(transitionalPortal))
    }

  } catch (err) {
    console.log('createProcessorHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Create Processor Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getProcessorHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'processor-lookup')
    const { form, params } = payload
    const url = `${serviceUrl}${api_path}`
    form.offset = params?.request.startRow || 0

    const { data, last_row } = yield call(fetchPost, url, form)
    const gridData = data.map((item) => {
      return ({
        ...item,
        bin: item?.bin_pcn_details?.map(({ bin }) => bin),
        pcn: item?.bin_pcn_details?.map(({ pcn }) => pcn)
      })
    })
    if (data.length) {
      params?.success({
        rowData: gridData,
        rowCount: parseInt(last_row, 10),
      })
    } else {
      params?.success({
        rowData: [],
        rowCount: 0,
      })
    }
  } catch (err) {
    console.log('getProcessorHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Get Processor Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* publishDocHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)

    const { api_path } = yield select(getApiPath, 'contract-management-publish-document')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPost, url, payload)
    yield put(contractActions.getContractSummaryData())
    const transitionalPortal = {
      header: 'Published Successfully',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } catch (err) {
    console.log('putContractDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Put Contract Config Data Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* createNewVersionHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty)
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)

    const { api_path } = yield select(getApiPath, 'contract-management-create-version')
    const url = `${serviceUrl}${api_path}`
    const response = yield call(fetchPost, url, payload)
    yield put(contractActions.getContractSummaryData())
    const transitionalPortal = {
      header: 'Created New Version Successfully',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } catch (err) {
    console.log('putContractDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Put Contract Config Data Failed',
      copy: err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

export {
  getSummaryDataHandler,
  getDataHandler,
  putContractDataHandler,
  publishDocHandler,
  createNewVersionHandler,
}
