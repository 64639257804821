/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { useSelector } from "react-redux";
import "./styles.scss";
import {
  convertSnakeCaseToString,
  capitalizeStr,
  calcGridRowHeight,
} from "../../utils/utilities";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

import ServerSideDatasource from "./serverSideDataSource";

export default function FliptGrid(props) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const sidebarIsOpen = useSelector((state) => state.app.sidebarIsOpen);

  const {
    // additionalElems,
    agGridRef,
    autoSuggestionMinChars,
    autoSuggestionValues,
    cellEditorParams,
    cellRendererParams,
    data,
    doAutoSize = false,
    dropdownOptions,
    filterOptions = [
      "contains",
      "equals",
      "notContains",
      "notEqual",
      "startsWith",
      "endsWith",
    ],
    // updateRowHeight,
    autoGroupColumnDef,
    auxData,
    doesExternalFilterPass,
    frameworkComponents,
    from = "",
    getRowHeight,
    getRowId,
    getRowNodeId = null,
    gridOptions,
    gridPagination = true,
    gridRef = { current: {} },
    gridTheme = "ag-theme-alpine",
    groupDisplayType,
    groupSelectsChildren,
    handleGridSize = false,
    headers,
    hideSideBar = true,
    immutableData = false,
    isExternalFilterPresent,
    isGroupOpenByDefault,
    onBodyScroll,
    onComponentStateChanged,
    onGridReadyCustom,
    onRowDragEnd = null,
    onRowSelected,
    onSelectionChanged,
    rowBuffer,
    rowCellInfo,
    rowClassRules,
    rowDragManaged = false,
    rowSelection,
    serverSideGridConfig = {},
    serverSideGridParams = {},
    stepLevel,
    suppressColumnVirtualisation,
    suppressRowClickSelection,
    tooltipComponent,
    tooltipShowDelay,
  } = props;
  const {
    cacheBlockSize = 100,
    pagination = gridPagination,
    rowModel = 'clientSide',
    paginationPageSize = 20,
    serverSideStoreType = "full",
    paginationNumberFormatter,
  } = serverSideGridConfig;

  let headerTitles = headers || [];

  if (!headerTitles && !!data.length) {
    headerTitles = data[0] || [];
  }

  const columnDefs = headerTitles.map((header) => {
    const obj = {};
    obj.headerName =
      header?.headerName || capitalizeStr(convertSnakeCaseToString(header));
    obj.field = header?.field || header;
    obj.comparator = (valueA, valueB) => {
      if (valueA === valueB) return 0;
      let x = valueA ? valueA.toString().toLowerCase() : "";
      let y = valueB ? valueB.toString().toLowerCase() : "";
      return x > y ? 1 : -1;
    };
    if (cellRendererParams && cellRendererParams[header]) {
      obj.rowDrag = cellRendererParams[header].rowDrag
      obj.cellRendererFramework = cellRendererParams[header].cellRenderer;
      obj.suppressMenu = cellRendererParams[header].suppressMenu;
      obj.cellRendererParams = {
        path: cellRendererParams[header].path,
        searchArgs: cellRendererParams[header].searchArgs,
        compact: cellRendererParams[header].compact,
        Component: cellRendererParams[header].Component,
        value: cellRendererParams[header].value,
        disabled: cellRendererParams[header].disabled,
        state: cellRendererParams[header].state,
        onChange: from
          ? (...e) => cellRendererParams[header].onChange(from, ...e)
          : cellRendererParams[header].onChange,
      };
      obj.sortable =
        cellRendererParams[header].sortable === false ? false : true;
      obj.comparator = cellRendererParams[header].comparator || obj.comparator;
      obj.onCellClicked = cellRendererParams[header].onCellClicked;
      obj.hide = cellRendererParams[header].hide;
      obj.width = cellRendererParams[header].width;
      obj.cellClass = cellRendererParams[header].cellClass;
      obj.headerName =
        cellRendererParams[header]?.overrideHeader || obj.headerName;
      obj.type = cellRendererParams[header]?.type || "";
      obj.rowGroup = cellRendererParams[header].rowGroup;
      obj.rowGroupIndex = cellRendererParams[header].rowGroupIndex;
      obj.suppressFiltersToolPanel = cellRendererParams[header].suppressFiltersToolPanel;
      cellRendererParams[header].suppressFiltersToolPanel;
      obj.menuTabs = cellRendererParams[header].menuTabs;
      obj.minWidth = cellRendererParams[header].minWidth;
      obj.maxWidth = cellRendererParams[header].maxWidth;
      obj.wrapText = cellRendererParams[header].wrapText;
      obj.autoHeight = cellRendererParams[header].autoHeight;
      obj.filter = cellRendererParams[header].filter
        ? cellRendererParams[header].filter
        : cellRendererParams[header].filter === false
          ? false
          : "agTextColumnFilter";
      obj.filterParams = cellRendererParams[header].filterParams;
      obj.flex = cellRendererParams[header].flex;
      obj.sort = cellRendererParams[header].sort;
      obj.pinned = cellRendererParams[header].pinned;
      obj.checkboxSelection = cellRendererParams[header].checkboxSelection;
      obj.headerCheckboxSelection =
        cellRendererParams[header].headerCheckboxSelection;
      obj.headerCheckboxSelectionFilteredOnly =
        cellRendererParams[header].headerCheckboxSelectionFilteredOnly;
      obj.tooltipField = cellRendererParams[header].tooltipField;
      if (cellRendererParams[header]?.valueParser) {
        obj.valueParser = cellRendererParams[header]?.valueParser;
      }
      obj.valueFormatter = cellRendererParams[header].valueFormatter;
      obj.cellClassRules = cellRendererParams[header].cellClassRules;
      if (cellRendererParams[header])
        obj.comparator = cellRendererParams[header].comparator;
    }
    if (cellEditorParams && cellEditorParams[header]) {
      obj.cellEditorFramework = cellEditorParams[header].cellEditor;
      obj.cellEditorParams = {
        onChange: from
          ? (...e) => cellEditorParams[header].onChange(from, ...e)
          : cellEditorParams[header].onChange,
        value: cellEditorParams[header].value,
        validation: cellEditorParams[header].validation,
        disabled: cellEditorParams[header].disabled || false,
        multiValData: cellEditorParams[header].multiValData || [],
        isMultiRow: cellEditorParams[header].isMultiRow || false,
        inputProps: cellEditorParams[header].inputProps || {},
        autoSuggestionResultSelect:
          cellEditorParams[header].autoSuggestionResultSelect || null,
        editorParams: cellEditorParams[header].editorParams || null,
        isDisabled: cellEditorParams[header]?.isDisabled || null,
        readOnly: cellEditorParams[header]?.readOnly || null,
      };
      obj.editable =
        (cellEditorParams &&
          cellEditorParams[header] &&
          cellEditorParams[header]?.editable) ||
        true;
      obj.singleClickEdit = true;
    }

    return obj;
  });

  const onGridReady = (params) => {
    if (onGridReadyCustom) {
      onGridReadyCustom();
    }
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    gridRef.current = { gridApi: params.api };

    const { form, apiId, sagaToCall, headerMapping } = serverSideGridParams;
    const datasource = new ServerSideDatasource(
      form,
      apiId,
      sagaToCall,
      headers,
      headerMapping
    );
    params.api.setServerSideDatasource(datasource);
  };

  // autosize every column except 'action' every time displayed rows have changed - triggered after sort, filter, or tree expand / collapse events
  const onModelUpdated = (params) => {
    if (doAutoSize) {
      params.columnApi.autoSizeAllColumns(false);
    }
  };

  const onGridSizeChanged = () => {
    if (gridApi && !sidebarIsOpen && handleGridSize) {
      gridApi.sizeColumnsToFit();
    }
  };

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
    ],
  };
  const defaultColDef = props.defaultColDef
    ? props.defaultColDef
    : {
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      filterParams: { buttons: ["reset"], filterOptions },
      tooltipComponent: tooltipComponent,
    };
  return (
    <div className={gridTheme}>
      {/* {AgGrid()} */}
      <AgGridReact
        ref={agGridRef}
        onGridReady={onGridReady}
        onBodyScroll={onBodyScroll}
        // listener for every time displayed rows change - triggered after sort, filter, or tree expand / collapse events
        onModelUpdated={onModelUpdated}
        rowData={data}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        sideBar={hideSideBar ? sideBar : hideSideBar}
        context={{
          dropdownOptions,
          rowCellInfo,
          autoSuggestionValues,
          autoSuggestionMinChars,
          stepLevel,
          auxData,
        }}
        rowModelType={rowModel}
        serverSideStoreType={serverSideStoreType}
        cacheBlockSize={cacheBlockSize}
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        paginationNumberFormatter={paginationNumberFormatter}
        getRowHeight={getRowHeight}
        rowBuffer={rowBuffer}
        suppressColumnVirtualisation={suppressColumnVirtualisation}
        rowClassRules={rowClassRules}
        wrapText
        autoHeight
        getRowId={getRowId}
        groupDisplayType={groupDisplayType}
        autoGroupColumnDef={autoGroupColumnDef}
        groupSelectsChildren={groupSelectsChildren}
        rowSelection={rowSelection}
        suppressRowClickSelection={suppressRowClickSelection}
        onSelectionChanged={onSelectionChanged}
        onRowSelected={onRowSelected}
        isGroupOpenByDefault={isGroupOpenByDefault}
        onComponentStateChanged={onComponentStateChanged}
        tooltipComponent={tooltipComponent}
        tooltipShowDelay={tooltipShowDelay}
        frameworkComponents={frameworkComponents}
        isExternalFilterPresent={isExternalFilterPresent}
        doesExternalFilterPass={doesExternalFilterPass}
        gridOptions={gridOptions}
        onGridSizeChanged={onGridSizeChanged}
        rowDragManaged={rowDragManaged}
        onRowDragEnd={onRowDragEnd}
        immutableData={immutableData}
        getRowNodeId={getRowNodeId}
      />
      {/* {additionalElems} */}
    </div>
  );
}
