import React, { Component } from 'react'
import RuleConditions from './ruleConditions/index'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'

import './styles.scss'
import BreadCrumb from '../../../../../components/breadCrumb'
import Config from '../../../../../config'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { Creators as RPMActions } from '../../../../../redux/reducers/rpm'
import { Creators as RolesActions } from '../../../../../redux/reducers/roleManagement'
import { parseQueryString } from '../../../../../utils/utilities'

class CreateApprovalRules extends Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {
        id: props.state?.approvalRuleData?.id || '',
        document_governed: '',
        name: '',
        hierarchy: [],
        hierarchyIDs: [],
        is_hierarchy_global: false,
      },
      ruleConditions: [{
        action: '',
        approval_step: '',
        approval_role: '',
      }],
      columnUpdated: null,
      editMode: true,
      columnValue: '',
      rowUpdated: null,
    }
  }

  componentDidMount() {
    const { actions, history: { location: { search, state } } } = this.props

    actions.clearApprovalRules()
    actions.getRoleManagementRoles()

    if (!search) return

    const { id } = parseQueryString(search)
    const s = {
      editMode: id && !!state?.editMode,
      id,
    }

    this.setState(s)

    setTimeout(() => {
      actions.rpmGetApprovalRulesData(parseQueryString(search))
    }, Config.COMPONENT_DATA_LOAD_TIME)
  }

  componentDidUpdate(prevProps) {
    const { props } = this
    const prevState = prevProps.state
    const currState = props.state

    if (_.isEqual(prevState, currState)) return

    if (!_.isEqual(prevState.rpm.approvalRuleData, currState.rpm.approvalRuleData)) this._updateState(currState)
  }

  _setForm = (newForm) => {
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...newForm,
      }
    }))
  }

  _handleChange = (el, dropdown, rowIndex, gridApi) => {
    const { state } = this
    const ruleConditions = [...state.ruleConditions]
    const rowToUpdate = ruleConditions[rowIndex]
    const { name, value } = dropdown || el.currentTarget

    rowToUpdate[name] = value
    ruleConditions[rowIndex] = rowToUpdate

    this.setState({
      ruleConditions, rowUpdated: rowIndex, columnUpdated: name, columnValue: value,
    })

    gridApi.refreshCells()
  }

  _updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value,
      },
    }))
  }

  _delRow = (rowIndex) => {
    const { ruleConditions } = this.state

    if (ruleConditions.length <= 1) return

    this.setState({
      ruleConditions: ruleConditions.filter((cond) => ruleConditions.indexOf(cond) !== rowIndex),
    })
  }

  _addRow = () => {
    const { ruleConditions } = this.state

    this.setState({
      ruleConditions: [
        ...ruleConditions,
        Object.keys(ruleConditions[0]).reduce((acc, header) => {
          acc[header] = ''
          return acc
        }, {}),
      ],
    })
  }

  _createRules = () => {
    const { props, state } = this
    const { form, ruleConditions } = state

    const payload = {
      ...form,
      conditions: ruleConditions
    }
    if (form?.hierarchy === '') {
      payload.hierarchy = []
    }

    if (state.editMode && state.id) {
      props.actions.rpmUpdateApprovalRules({
        ...payload,
        id: state.id,
      })
    } else {
      props.actions.rpmCreateApprovalRules(payload)
    }
  }

  _updateState(currState) {
    const { form } = this.state
    const approvalRuleData = currState?.rpm?.approvalRuleData
    let conditions = approvalRuleData?.conditions
    const formData = Object.keys(form).reduce((acc, curr) => {
      acc[curr] = currState.rpm.approvalRuleData[curr]
      return acc
    }, {})

    if (!conditions.length) {
      conditions = [{
        action: '',
        approval_step: '',
        approval_role: '',
      }]
    }

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...formData,
      },
      ruleConditions: conditions,
    }))
  }

  _mapValueToOption = (row) => {
    const data = {
      ...row,
    }

    Object.keys(data).forEach((col) => {
      data[col] = data[col].map((group, index) => ({
        key: index,
        text: group,
        value: group,
      }))
    })
    return data
  }

  _goBack = () => {
    const { props: { history } } = this
    history.goBack()
  }

  _getRoleName = (params) => {
    const { props: { state: { rolesData } } } = this
    const roleName = rolesData?.find(x => x.id === params.value)?.name ?? (params?.value ?? 'None')
    return roleName
  }

  render() {
    const { form, editMode, ruleConditions } = this.state
    const { state: { rolesData }, history: { location: { search } } } = this.props
    let headerText = editMode ? 'Create Rule' : 'View Rule'
    if (search && editMode) headerText = 'Edit Rule'

    return (
      <div id="approvalRule">
        <BreadCrumb {...this.props} />
        <div className="header">{headerText}</div>
        <div className="content">
          <RuleConditions
            addRow={this._addRow}
            delRow={this._delRow}
            editMode={editMode}
            rolesData={rolesData}
            back={this._goBack}
            form={form}
            createRules={this._createRules}
            handleChange={this._handleChange}
            ruleConditions={ruleConditions}
            updateFields={this._updateFields}
            setForm={this._setForm}
            getRoleName={this._getRoleName}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    rpm: state.rpm,
    rolesData: state.roleManagement.roleListing,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...RPMActions,
    ...RolesActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateApprovalRules)
