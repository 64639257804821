import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const INITIAL_STATE = Immutable({
  loadingStatus: {},
  qualifierModels: [],
  qualifierRows: [],
  qualifierLookupModel: {},
  qualifierLookupData: []
})

// operates on 2D array
const updateArrayAtIndex = (immutableArray = [], index, subArray) => {
  const array = Immutable.asMutable(immutableArray, { deep: true })
  const maxIndex = array.length - 1
  if (index <= maxIndex) {
    array[index] = [...subArray]
  } else {
    // add empty arrays on indexes between maxIndex and index-1
    for (let i = maxIndex + 1; i < index; i++) {
      array.push([])
    }
    array.push([...subArray])
  }
  return array
}

export const setQualifierDescriptionReducer = (state = INITIAL_STATE, { payload }) => {
  const { stepLevel, description } = payload;
  return state
}

export const clearQualifiersReducer = (state = INITIAL_STATE) => {
  return INITIAL_STATE
}

export const clearQualifierLookupDataReducer = (state = INITIAL_STATE) => ({
  ...state,
  qualifierLookupData: []
})

export const clearQualifierLookupModelReducer = (state = INITIAL_STATE) => {
  return {
    ...state,
    qualifierLookupModel: {}
  }
}

export const clearQualifierModelsReducer = (state = INITIAL_STATE) => ({
  ...state,
  qualifierModels: []
})

export const clearQualifierRowsReducer = (state = INITIAL_STATE) => ({
  ...state,
  qualifierRows: []
})

export const setEntireQualifierRowsReducer = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    qualifierRows: payload
  }
}

export const setQualifierLookupDataReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  qualifierLookupData: [...payload]
})

export const setQualifierLookupModelReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  qualifierLookupModel: { ...state.qualifierLookupModel, ...payload }
})

export const setQualifierModelsReducer = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  qualifierModels: [...payload]
})

export const setQualifierRowsReducer = (state = INITIAL_STATE, { payload }) => {
  const { stepLevel, rows = [] } = payload
  return {
    ...state,
    qualifierRows: updateArrayAtIndex(state.qualifierRows, stepLevel, rows)
  }
}


export const { Types, Creators } = createActions({
  clearQualifiers: null,
  clearQualifierLookupData: null,
  clearQualifierLookupModel: null,
  clearQualifierModels: null,
  clearQualifierRows: null,
  getMultipleQualifierCriteria: ['payload'],
  getQualifierModels: ['payload'],
  getQualifierCriteria: ['payload'],
  getQualifierLookup: ['payload'],
  getQualifierLookupData: ['payload'],
  setEntireQualifierRows: ['payload'],
  setQualifierDescription: ['payload'],
  setQualifierLookupData: ['payload'],
  setQualifierLookupModel: ['payload'],
  setQualifierModels: ['payload'],
  setQualifierRows: ['payload'],
})

const HANDLERS = {
  [Types.CLEAR_QUALIFIERS]: clearQualifiersReducer,
  [Types.CLEAR_QUALIFIER_LOOKUP_DATA]: clearQualifierLookupDataReducer,
  [Types.CLEAR_QUALIFIER_LOOKUP_MODEL]: clearQualifierLookupModelReducer,
  [Types.CLEAR_QUALIFIER_MODELS]: clearQualifierModelsReducer,
  [Types.CLEAR_QUALIFIER_ROWS]: clearQualifierRowsReducer,
  [Types.SET_ENTIRE_QUALIFIER_ROWS]: setEntireQualifierRowsReducer,
  [Types.SET_QUALIFIER_DESCRIPTION]: setQualifierDescriptionReducer,
  [Types.SET_QUALIFIER_LOOKUP_DATA]: setQualifierLookupDataReducer,
  [Types.SET_QUALIFIER_LOOKUP_MODEL]: setQualifierLookupModelReducer,
  [Types.SET_QUALIFIER_MODELS]: setQualifierModelsReducer,
  [Types.SET_QUALIFIER_ROWS]: setQualifierRowsReducer,
}

export default createReducer(INITIAL_STATE, HANDLERS)
