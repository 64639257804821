export const qlCriteriaFields = [
  'quantity_max_amount',
  'days_supply_max_amount',
  'daily_dose_max_amount',
  'daily_dose_max_amount_time_period_units',
  'ptd_quantity_max_amount',
  'ptd_quantity_max_amt_time_period_units',
  'ptd_quantity_max_amt_time_period',
  'ptd_quantity_refill_%',
  'ptd_ds_max_amount',
  'ptd_ds_max_amount_time_period_units',
  'ptd_ds_max_amount_time_period',
  'ptd_ds_refill_%',
  'ptd_fills_max_amount',
  'ptd_fills_max_amount_time_period_units',
  'ptd_fills_max_amount_time_period',
  'ptd_fills_refill_%',
  'max_amount',
  'max_amount_qualifier',
  'max_time_period_units',
  'max_amount_time_period',
  'max_time_period_start',
  'max_time_period_end',
  'quantity_limits_type'
]
