import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";

import "./styles.scss";
import BreadCrumb from "../../../../../components/breadCrumb";
import ApprovalOptions from "../../../../../components/approvalOptions";
import Config from "../../../../../config";
import QLConditions from "./QLConditions";
import SaveTierModal from "./saveTierModal";
import _ from "lodash";
import drugDemographicData from "../../../../../config/constants/clinical/drugDemographic";
import drugMappingData from "../../../../../config/constants/clinical/drugMapping";
import { Creators as AppActions } from "../../../../../redux/reducers/app";
import { Creators as AutoSuggestActions } from "../../../../../redux/reducers/api/autoSuggest";
import { Creators as QuantityLimitCreationActions } from "../../../../../redux/reducers/api/quantityLimitManagement";
import { Creators as QualifierActions } from "../../../../../redux/reducers/api/qualifiers";
import { Creators as RPMActions } from "../../../../../redux/reducers/rpm";
import { drugGroupValues } from "../../../../../config/constants/clinical/drugGroups";
import {
  convertDateToStartTimeObj,
  parseQueryString,
  convertDateToEndTimeObj, downloadCSV
} from "../../../../../utils/utilities";
import { qlCriteria } from "./data/criteria";

class CreateQuantityLimit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        doc_name: "",
        effective_start_date: "",
        questionnaire_name: "",
        questionnaire_link: "",
        ql_reason: "",
        doc_id: props.state?.quantityLimitData?.doc_id || "",
        module: "QUANTITY_LIMIT",
        version: "1.0",
        status: "DRAFT",
        version_effective_date: "",
        hierarchy: [],
        include_inactive_drugs: "Y",
        is_hierarchy_global: false,
        hideHierarchy: false,
      },
      activeQLConditionLevel: null,
      ql_condition: {
        include: true,
        // include_in_grid: true,
        gpi: "",
        medicare_type: "",
        drug_group: "",
        drug_class: "",
        drug_subclass: "",
        manufacturer: "",
        drug_name: "",
        multi_source: "",
        inactive_indicator: "",
        ddid: "",
        brand_generic: "",
        otc_indicator: "",
        action: "",
        desi_code: "",
        route_of_administration: "",
        effective_start_date: "",
        effective_end_date: "",
      },
      qlConditionLevels: [],
      qlCriteriaLevels: [cloneDeep(qlCriteria)],
      showCriteria: [false],
      dropdownValues: [
        [
          {
            drug_group: drugGroupValues,
            drug_class: [],
            drug_subclass: [],
          },
        ],
      ],
      autoSuggestionValues: [
        [
          {
            manufacturer: { values: [], loading: false },
            drug_name: { values: [], loading: false },
          },
        ],
      ],
      rowUpdated: null,
      columnUpdated: null,
      levelUpdated: null,
      autoSuggestionMinChars: 3,
      cellRenderers: {
        gpi: {
          overrideHeader: "GPI",
          hide: false,
        },
        desi_code: {
          hide: true,
        },
        medicare_type: {
          hide: true,
          overrideHeader: "Medicare Type Code",
        },
        manufacturer: {
          hide: true,
        },
        multi_source: {
          overrideHeader: "Multi-Source Code",
          hide: false,
        },
        ddid: {
          hide: false,
          overrideHeader: "DDID",
        },
        item_status_flag: {
          hide: true,
          overrideHeader: "Inactive Indicator"
        },
        brand_generic: {
          hide: true,
          overrideHeader: "Brand/Generic",
        },
        otc_indicator: {
          hide: true,
          overrideHeader: "OTC Indicator",
        },
        route_of_administration: {
          hide: true,
        },
        ndc: {
          hide: true,
        },
        maintenance_drug_code: {
          hide: true,
          overrideHeader: "Maintenance Drug Indicator",
        },
        application_type_flag: {
          hide: true,
          overrideHeader: 'App Type',
        },
        mfg_labeler_id: {
          hide: true,
          overrideHeader: 'Labler Code',
        },
        repackaged_code: {
          hide: true,
          overrideHeader: 'Repackaging Indicator',
        },
        third_party_restriction_code: {
          hide: true,
          overrideHeader: 'TPR Code',
        },
        dosage_form_cd: {
          hide: true,
          overrideHeader: 'Dosage Form Code',
        },
        clinic_pack_code: {
          hide: true,
          overrideHeader: 'Clinic Pack',
        },
        innerpack_code: {
          hide: true,
          overrideHeader: 'Inner Pack',
        },
        unit_dose: {
          hide: true,
          overrideHeader: 'Unit Dose',
        },
        dea_class_code: {
          hide: true,
          overrideHeader: 'DEA Class',
        },
        fda_therapeutic_equivalence_code: {
          hide: true,
          overrideHeader: 'FDA Equivalence',
        },
        marketing_category: {
          hide: true,
          overrideHeader: 'Marketing Category',
        },
        storage_condition_code: {
          hide: true,
          overrideHeader: 'Storage Condition',
        },
        unit_dose_with_non_unit_dose_non_repackager_in_gpi: {
          hide: true,
          overrideHeader: 'UD With Non UD RC In GPI',
        },
        rx_with_otc_in_gpi: {
          hide: true,
          overrideHeader: 'Rx With OTC In GPI',
        },
        member_notes: {
          hide: true,
        },
        agent_notes: {
          hide: true,
        },
        internal_notes: {
          hide: true,
        },
        claim_message_code: {
          hide: true,
        },
        claim_message_type: {
          hide: true,
        },
        condition_id: {
          hide: true,
          overrideHeader: "Condition Id"
        },
      },
    };
  }

  clearFormData() {
    this.setState({
      form: {
        doc_name: "",
        effective_start_date: "",
        questionnaire_name: "",
        version_effective_date: "",
        questionnaire_link: "",
        include_inactive_drugs: "Y",
        ql_reason: "",
        doc_id: "",
        module: "QUANTITY_LIMIT",
        version: "1.0",
        status: "DRAFT",
      },
    });
  }

  componentDidMount() {
    const { actions, history } = this.props
    const { search, state } = history.location
    actions.clearQuantityLimit()
    actions.clearQualifiers()
    if (!search) {
      // user is creating a new document
      this.clearFormData()
      actions.rpmGetMf2ValData()
      this.setState({
        showCriteria: [true]
      })
      return
    }

    if (search === "?import") {
      const newQlConditionLevels = state.qlConditionLevels.map((conditionLevel, levelIndex) => {
        const newConditionLevel = conditionLevel.map((row, index) => {
          if ((row?.condition_id || '' === '') || (row?.condition_id === undefined)) {
            row.condition_id = this.generateConditionId('QL', state?.form?.doc_name || "", state?.form?.version || "1.0", levelIndex + 1, index + 1)
          }
          if (row?.include_in_grid !== true) {
            row.include_in_grid = true;
          }
          return row
        })
        return newConditionLevel
      })
      this.setState({
        qlConditionLevels: newQlConditionLevels,
        showCriteria: state.showCriteria,
        form: state?.form,
      });
      actions.setEntireQualifierRows(state?.qlCriteriaRows)
      return;
    }

    const payload = parseQueryString(history.location.search)
    setTimeout(
      () => {
        actions.getQuantityLimitData(payload)
        actions.rpmGetMf2ValData()
      },
      Config.COMPONENT_DATA_LOAD_TIME
    );
  }
  _onCheckboxChange = (el, name, levelIndex, rowIndex) => {
    const { state } = this;
    let ConditionLevels = state.qlConditionLevels;
    const rowToUpdate = ConditionLevels[levelIndex][rowIndex];
    const {
      target: { checked: value },
    } = el;
    if ((state?.form?.status || "DRAFT") === "PUBLISHED") {
      let condition_grid_version = (parseInt(state.form.version) + 1).toString() + ".0"
      rowToUpdate["condition_id"] = this.generateConditionId("QL", state.form.doc_name, condition_grid_version, levelIndex + 1, rowIndex + 1)
    } else {
      rowToUpdate["condition_id"] = this.generateConditionId("QL", state.form.doc_name, state.form.version, levelIndex + 1, rowIndex + 1)
    }
    rowToUpdate[name] = value;
    ConditionLevels[levelIndex][rowIndex][name] = value
    this.setState({
      qlConditionLevels: ConditionLevels,
      rowUpdated: rowIndex,
      columnUpdated: name,
      columnValue: value,
    });
  };

  componentDidUpdate(prevProps) {
    const { props } = this;
    const prevState = prevProps.state;
    const currState = props.state;
    if (prevState === currState) return;
    const hasConditionsChanged =
      prevState.quantityLimitCondData.length !==
      currState.quantityLimitCondData.length &&
      !!currState.quantityLimitCondData.length;
    const hasQLDataChanged =
      prevState.quantityLimitData !== currState.quantityLimitData;
    if (hasConditionsChanged) {
      this._updateState();
    } else if (hasQLDataChanged && currState.quantityLimitData?.doc_id) {
      const { quantityLimitData } = currState;
      // following same practice as _updateState function (not spreading the quantityLimitData)
      const form = {
        ...this.state.form,
        doc_id: quantityLimitData.doc_id,
        doc_name: quantityLimitData.doc_name,
        effective_start_date: quantityLimitData.effective_start_date,
        version_effective_date: quantityLimitData.version_effective_date,
        hierarchy: quantityLimitData.hierarchy,
        is_hierarchy_global: quantityLimitData.is_hierarchy_global,
        hideHierarchy: quantityLimitData.hideHierarchy,
        max_no_of_fills: quantityLimitData.max_no_of_fills,
        version: quantityLimitData.version,
        status: quantityLimitData.status,
      };
      this.setState({
        ...this.state,
        form,
      });
    }
    if (!_.isEqual(prevState.autoSuggestData, currState.autoSuggestData))
      this._updateAutoSuggestions();
  }

  componentWillUnmount() {
    const { clearQualifiers } = this.props.actions
    clearQualifiers()
  }

  sanitizeString(text) {
    // Keep only alphanumeric characters and spaces, convert to uppercase
    const sanitizedText = text.replace(/[^a-zA-Z0-9 ]/g, '-').toUpperCase();

    // Replace spaces with underscores
    return sanitizedText.replace(/ /g, '-');
  }

  generateConditionId(module, name, version, level, rowNumber) {
    return `${this.sanitizeString(module)}:::${this.sanitizeString(name)}:::${version}:::L${level.toString().padStart(5, '0')}:::${rowNumber.toString().padStart(7, '0')}`
  }

  // eslint-disable-next-line react/destructuring-assignment
  _constructDropdownOptions = () =>
    this.state.dropdownValues.map((dropdownValueLevel) =>
      dropdownValueLevel.map((row) => this._mapValueToOption(row))
    );

  _updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget;

    // Check if name is 'effective_start_date'
    if (name === "effective_start_date") {
      // Update effective_start_date in qlConditionLevels
      this.setState((prevState) => {
        let updatedQlConditionLevels = prevState.qlConditionLevels.map(
          (level) => {
            return level.map((condition) => {
              return {
                ...condition,
                effective_start_date: value,
              }
            })
          }
        )
        // in current behavior, FliptDatePicker/SemanticDatepicker fires onChange event on first render
        // this trigger this function (_updateFields) and causes effective_start_date for the whole grid to
        // change without any use action. below is the fix for that - we only change grid date if effective_start_date
        // is a date object. please consider this behavior before making any changes.
        if (name === 'effective_start_date' && typeof value !== typeof prevState.form.effective_start_date) {
          updatedQlConditionLevels = prevState.qlConditionLevels
        }
        return {
          ...prevState,
          form: {
            ...prevState.form,
            [name]: value,
          },
          qlConditionLevels: updatedQlConditionLevels,
        }
      })
    } else {
      this.setState((prevState) => {
        let updatedQlConditionLevels = prevState.qlConditionLevels
        if ((name === 'doc_name')) {
          updatedQlConditionLevels = updatedQlConditionLevels.map(
            (level, level_index) => {
              return level.map((condition, condition_index) => {

                return {
                  ...condition,
                  condition_id: this.generateConditionId('QL', value, prevState?.form?.version || "1.0", level_index + 1, condition_index + 1),
                }
              })
            }
          )
        }
        if ((name === 'version')) {
          updatedQlConditionLevels = updatedQlConditionLevels.map(
            (level, level_index) => {
              return level.map((condition, condition_index) => {

                return {
                  ...condition,
                  condition_id: this.generateConditionId('QL', prevState?.form?.doc_name || "", value, level_index + 1, condition_index + 1),
                }
              })
            }
          )
        }
        return {
          ...prevState,
          form: {
            ...prevState.form,
            [name]: value,
          },
          qlConditionLevels: updatedQlConditionLevels,
        }
      });
    }
  };

  setForm = (newForm) => {
    this.setState((prevState) => ({
      ...prevState,
      form: newForm,
    }));
  };

  _handleChange = (
    el,
    dropdown,
    rowIndex,
    gridApi,
    qlConditionLevel,
    setAutoSuggestionValues
  ) => {
    const { props, state } = this;
    const { actions } = props;
    const qlConditionLevels = [...state.qlConditionLevels];
    const dropdownValues = [...state.dropdownValues];
    const autoSuggestionValues = [...state.autoSuggestionValues];
    const rowToUpdate = qlConditionLevels[qlConditionLevel][rowIndex];
    const dropDownRowToUpdate = dropdownValues[qlConditionLevel][rowIndex];
    const { name, value } = dropdown || el.currentTarget;
    rowToUpdate[name] = value;

    if ((state?.form?.status || "DRAFT") === "PUBLISHED") {
      let condition_grid_version = (parseInt(state.form.version) + 1).toString() + ".0"
      rowToUpdate["condition_id"] = this.generateConditionId("QL", state.form.doc_name, condition_grid_version, qlConditionLevel + 1, rowIndex + 1)
    } else {
      rowToUpdate["condition_id"] = this.generateConditionId("QL", state.form.doc_name, state.form.version, qlConditionLevel + 1, rowIndex + 1)
    }

    if (state.rowUpdated != rowIndex || state.levelUpdated != qlConditionLevel)
      actions.clearAutoSuggestData();

    switch (name) {
      case "gpi": {
        this._onGpiChange(value, rowToUpdate, dropDownRowToUpdate);
        break;
      }
      case "drug_group":
        this._onDrugGroupChange(rowToUpdate, dropDownRowToUpdate);
        break;
      case "drug_class":
        this._onDrugClassChange(rowToUpdate, dropDownRowToUpdate);
        break;
      case "drug_subclass":
        break;
      case "manufacturer":
        if (value.length >= state.autoSuggestionMinChars) {
          autoSuggestionValues[qlConditionLevel][
            rowIndex
          ].manufacturer.loading = true;
          actions.getAutoSuggestData({
            search_string: value,
            search_in: "fts_ndc_drugs",
            search_for: "mfg",
          });
          this.setAutoSuggestionValues = setAutoSuggestionValues;
        }
        break;
      case "drug_name":
        if (value.length >= state.autoSuggestionMinChars) {
          autoSuggestionValues[qlConditionLevel][
            rowIndex
          ].drug_name.loading = true;
          actions.getAutoSuggestData({
            search_string: value,
            search_in: "fts_ndc_drugs",
            search_for: "drug_name",
          });
          this.setAutoSuggestionValues = setAutoSuggestionValues;
        }
        break;
      default:
        break;
    }
    qlConditionLevels[qlConditionLevel][rowIndex] = rowToUpdate;
    dropdownValues[qlConditionLevel][rowIndex] = dropDownRowToUpdate;

    const activeColumns = gridApi.columnModel.columnApi
      .getAllGridColumns()
      .filter((col) => {
        if (col.visible) return col.colId;
      });
    let cellRenderers = { ...state.cellRenderers };
    const getColumn = Object.keys(cellRenderers);

    activeColumns.forEach((col) => {
      if (getColumn.includes(col.colId) && col.colId != "action") {
        cellRenderers[col.colId]["hide"] = false;
      }
    });

    this.setState({
      qlConditionLevels,
      dropdownValues,
      rowUpdated: rowIndex,
      columnUpdated: name,
      levelUpdated: qlConditionLevel,
      cellRenderers: cellRenderers,
    });
    gridApi.refreshCells();
  };

  _refreshDrugDemographicDropdowns = (rowToUpdate, dropDownRowToUpdate) => {
    rowToUpdate.drug_group = "";
    rowToUpdate.drug_class = "";
    rowToUpdate.drug_subclass = "";
    dropDownRowToUpdate.drug_group = drugGroupValues;
    dropDownRowToUpdate.drug_class = [];
    dropDownRowToUpdate.drug_subclass = [];
  };

  _onGpiChange = (value, rowToUpdate, dropDownRowToUpdate) => {
    const gpiCode = value;
    const gpiLen = gpiCode.length;
    if (gpiLen === 0) {
      this._refreshDrugDemographicDropdowns(rowToUpdate, dropDownRowToUpdate);
      return;
    }
    const drugGroupCode = gpiCode.slice(0, 2);
    const drugClassCode = gpiCode.slice(2, 4);
    const drugSubClassCode = gpiCode.slice(4, 6);
    this._selectDrugGroup(drugGroupCode, rowToUpdate, dropDownRowToUpdate);
    this._selectDrugClass(
      drugGroupCode,
      drugClassCode,
      rowToUpdate,
      dropDownRowToUpdate
    );
    this._selectDrugSubclass(
      drugGroupCode,
      drugClassCode,
      drugSubClassCode,
      rowToUpdate
    );
  };

  _selectDrugGroup = (gpiCode, rowToUpdate, dropDownRowToUpdate) => {
    if (gpiCode in drugMappingData) {
      rowToUpdate.drug_group = drugMappingData[gpiCode].drug_group;
      this._onDrugGroupChange(rowToUpdate, dropDownRowToUpdate);
    }
  };

  _selectDrugClass = (
    drugGroupCode,
    drugClassCode,
    rowToUpdate,
    dropDownRowToUpdate
  ) => {
    const validDrugClassCode =
      drugGroupCode in drugMappingData &&
      drugClassCode in drugMappingData[drugGroupCode].drug_classes;
    if (validDrugClassCode) {
      rowToUpdate.drug_class =
        drugMappingData[drugGroupCode].drug_classes[drugClassCode].drug_class;
      this._onDrugClassChange(rowToUpdate, dropDownRowToUpdate);
    }
  };

  _selectDrugSubclass = (
    drugGroupCode,
    drugClassCode,
    drugSubClassCode,
    rowToUpdate
  ) => {
    const validDrugClassCode =
      drugGroupCode in drugMappingData &&
      drugClassCode in drugMappingData[drugGroupCode].drug_classes;
    const validDrugSubClassCode =
      validDrugClassCode &&
      drugSubClassCode in
      drugMappingData[drugGroupCode].drug_classes[drugClassCode]
        .drug_subclasses;
    if (validDrugSubClassCode) {
      rowToUpdate.drug_subclass =
        drugMappingData[drugGroupCode].drug_classes[
          drugClassCode
        ].drug_subclasses[drugSubClassCode].drug_subclass;
    }
  };

  _onDrugGroupChange = (rowToUpdate, dropDownRowToUpdate) => {
    rowToUpdate.drug_class = "";
    rowToUpdate.drug_subclass = "";
    dropDownRowToUpdate.drug_class = this._extractDrugDemographics(rowToUpdate);
    dropDownRowToUpdate.drug_subclass = [];
  };

  _onDrugClassChange = (rowToUpdate, dropDownRowToUpdate) => {
    rowToUpdate.drug_subclass = "";
    dropDownRowToUpdate.drug_subclass =
      this._extractDrugDemographics(rowToUpdate);
  };

  _autoSuggestionResultSelect = (data, rowIndex) => {
    const { state } = this;
    const qlConditionLevels = [...state.qlConditionLevels];
    const rowToUpdate =
      qlConditionLevels[state.activeQLConditionLevel][rowIndex];
    const { name, result } = data;
    const value = result.title;
    rowToUpdate[name] = value;
    qlConditionLevels[state.activeQLConditionLevel][rowIndex] = rowToUpdate;
    this.setState({
      qlConditionLevels,
    });
  };

  _delQLCondition = (rowIndex, qlConditionLevel) => {
    const { qlConditionLevels, dropdownValues, autoSuggestionValues } =
      this.state;
    const qlConditionLevelToUpdate = [...qlConditionLevels[qlConditionLevel]];
    const dropdownToUpdate = [...dropdownValues[qlConditionLevel]];
    const autoSuggestionToUpdate = [...autoSuggestionValues[qlConditionLevel]];

    //if (qlConditionLevelToUpdate.length <= 1) return;

    const newQLConditionLevel = qlConditionLevelToUpdate.filter(
      (cond) => qlConditionLevelToUpdate.indexOf(cond) !== rowIndex
    );
    qlConditionLevels[qlConditionLevel] = newQLConditionLevel;

    const newdropdown = dropdownToUpdate.filter(
      (cond) => dropdownToUpdate.indexOf(cond) !== rowIndex
    );
    dropdownValues[qlConditionLevel] = newdropdown;

    const newAutoSuggestion = autoSuggestionToUpdate.filter(
      (cond) => autoSuggestionToUpdate.indexOf(cond) !== rowIndex
    );
    autoSuggestionValues[qlConditionLevel] = newAutoSuggestion;

    this.setState({
      qlConditionLevels,
      dropdownValues,
      autoSuggestionValues,
    });
  };

  _addQLCondition = (level) => {
    const { qlConditionLevels, dropdownValues, autoSuggestionValues, ql_condition, form } =
      this.state;
    let ql_condition_length = qlConditionLevels?.[0]?.length || 0
    let ql_condition_object = ql_condition
    if (ql_condition_length > 0) {
      ql_condition_object = qlConditionLevels[0][0]
    }
    let no_of_conditions = qlConditionLevels[level].length + 1
    let new_id = ""
    if ((form?.status || "DRAFT") === "PUBLISHED") {
      let condition_grid_version = (parseInt(form?.version || "1.0") + 1).toString() + ".0"
      new_id = this.generateConditionId("QL", form?.doc_name || "", condition_grid_version, level + 1, no_of_conditions)
    } else {
      new_id = this.generateConditionId("QL", form?.doc_name || "", form?.version || "1.0", level + 1, no_of_conditions)
    }
    const updatedQLConditionLevels = qlConditionLevels[level]?.concat(
      Object.keys(ql_condition_object).reduce((acc, header) => {
        acc[header] = "";
        if (header === "include") acc[header] = true;
        acc["condition_id"] = new_id
        acc["effective_start_date"] = form.effective_start_date || "";
        return acc;
      }, {})
    );
    const updatedDropdownValues = dropdownValues[level]?.concat(
      Object.keys(dropdownValues[0][0]).reduce((acc, header) => {
        if (header === "drug_group") {
          acc[header] = drugGroupValues;
        } else {
          acc[header] = [];
        }
        return acc;
      }, {})
    );
    const updatedAutoSuggestionValues = autoSuggestionValues[level].concat(
      Object.keys(autoSuggestionValues[0][0]).reduce((acc, header) => {
        acc[header] = { values: [], loading: false };
        return acc;
      }, {})
    );
    qlConditionLevels[level] = updatedQLConditionLevels;
    dropdownValues[level] = updatedDropdownValues;
    autoSuggestionValues[level] = updatedAutoSuggestionValues;

    this.setState({
      qlConditionLevels,
      dropdownValues,
      autoSuggestionValues,
    });
  };

  _deleteQLConditionLevel = (e, qlConditionLevel) => {
    const { state } = this;
    const qlConditionLevels = [...state.qlConditionLevels];
    const qlCriteriaLevels = [...state.qlCriteriaLevels];
    const dropdownValues = [...state.dropdownValues];
    const autoSuggestionValues = [...state.autoSuggestionValues];
    const showCriteria = [...state.showCriteria];

    //if (qlConditionLevels.length <= 1) return;
    showCriteria.splice(qlConditionLevel, 1);
    this.setState({
      activeQLConditionLevel: qlConditionLevels.length === 1 ? null : qlConditionLevels.length - 1,
      qlConditionLevels: qlConditionLevels.filter(
        (cond) => qlConditionLevels.indexOf(cond) !== qlConditionLevel
      ),
      qlCriteriaLevels: qlCriteriaLevels.filter(
        (cond) => qlCriteriaLevels.indexOf(cond) !== qlConditionLevel
      ),
      dropdownValues: dropdownValues.filter(
        (cond) => dropdownValues.indexOf(cond) !== qlConditionLevel
      ),
      autoSuggestionValues: autoSuggestionValues.filter(
        (cond) => autoSuggestionValues.indexOf(cond) !== qlConditionLevel
      ),
      showCriteria,
    });
  };

  checkDateValidation = (tierConditions) => {
    const { actions } = this.props;
    let invalidDate = false;
    let formattedQlConditions = tierConditions.map((tierData) => {
      if (!tierData.effective_start_date) {
        invalidDate = true;
      }
      return {
        ...tierData,
        effective_start_date: convertDateToStartTimeObj(
          tierData.effective_start_date
        ),
        effective_end_date: convertDateToEndTimeObj(
          tierData.effective_end_date
        ),
      };
    });
    if (invalidDate) {
      const transitionalPortal = {
        header: "Missing Effective Start Date in conditions",
        copy: `Please Add effective Start Date before saving`,
      };
      actions.displayTransitionalPortal(transitionalPortal);
    }
    return { formattedQlConditions, invalidDate };
  };

  _addNewQLConditionLevel = () => {
    const { state } = this;
    const qlConditionLevels = [...state.qlConditionLevels];
    const qlCriteriaLevels = [...state.qlCriteriaLevels];
    const dropdownValues = [...state.dropdownValues];
    const autoSuggestionValues = [...state.autoSuggestionValues];
    const showCriteria = [...state.showCriteria];

    qlConditionLevels.push([

    ]);
    qlCriteriaLevels.push(
      // [...qlCriteria],
      cloneDeep(qlCriteria)
    );
    dropdownValues.push([
      { drug_group: drugGroupValues, drug_class: [], drug_subclass: [] },
    ]);
    autoSuggestionValues.push([
      {
        manufacturer: { values: [], loading: false },
        drug_name: { values: [], loading: false },
      },
    ]);
    showCriteria.push(true);
    this.setState({
      activeQLConditionLevel: qlConditionLevels.length - 1,
      qlConditionLevels,
      qlCriteriaLevels,
      dropdownValues,
      autoSuggestionValues,
      showCriteria,
    });
  };

  _handleQLConditionLevelClick = (e, titleProps) => {
    const { state } = this;
    const { activeQLConditionLevel } = state;
    const { index } = titleProps;
    const newIndex = activeQLConditionLevel === index ? -1 : index;
    this.setState({ activeQLConditionLevel: newIndex });
  };

  _handleCriteriaChange = (
    el,
    dropdown,
    rowIndex,
    gridApi,
    qlConditionLevel,
    rowData
  ) => {
    const { state } = this;
    const qlCriteriaLevels = [...state.qlCriteriaLevels];
    const { ql_criteria, ql_qualifier } = rowData;
    switch (ql_criteria) {
      case "Per Day":
        rowIndex = 0;
        break;
      case "Per Prescription":
        if (ql_qualifier === "Max Quantity") {
          rowIndex = 1;
        } else if (ql_qualifier === "Max Days of Supply") {
          rowIndex = 2;
        }
        break;
      case "Per Period":
        if (ql_qualifier === "Lookback Period") {
          rowIndex = 3;
        } else if (ql_qualifier === "Max Quantity") {
          rowIndex = 4;
        } else if (ql_qualifier === "Max Days of Supply") {
          rowIndex = 5;
        } else if (ql_qualifier === "Max Number of Fills") {
          rowIndex = 6;
        }
        break;
      default:
        break;
    }
    const dd = [...qlCriteriaLevels[qlConditionLevel]];
    const rowToUpdate = { ...dd[rowIndex] };
    const { name, value } = dropdown || el.currentTarget;
    rowToUpdate[name] = value;
    qlCriteriaLevels[qlConditionLevel][rowIndex] = { ...rowToUpdate };
    this.setState({
      qlCriteriaLevels,
    });
  };

  _delCriteriaRow = (rowIndex, qlConditionLevel) => {
    const { qlCriteriaLevels } = this.state;
    const qlCriteriaLevelToUpdate = [...qlCriteriaLevels[qlConditionLevel]];
    if (qlCriteriaLevelToUpdate.length <= 1) return;

    const newQLCriteriaLevel = qlCriteriaLevelToUpdate.filter(
      (cond) => qlCriteriaLevelToUpdate.indexOf(cond) !== rowIndex
    );
    qlCriteriaLevels[qlConditionLevel] = newQLCriteriaLevel;

    this.setState({
      qlCriteriaLevels,
    });
  };

  _addCriteriaRow = (level) => {
    const { qlCriteriaLevels } = this.state;
    const updatedQLCriteriaLevels = qlCriteriaLevels[level].concat(
      Object.keys(qlCriteriaLevels[0][0]).reduce((acc, header) => {
        acc[header] = "";
        return acc;
      }, {})
    );
    qlCriteriaLevels[level] = updatedQLCriteriaLevels;
    this.setState({
      qlCriteriaLevels,
    });
  };

  _download_drugs_to_csv = (el, qlConditionLevel) => {

    const { props, state } = this;
    const { qlConditionLevels, form } = state;
    const { doc_name, version } = form;
    let filename = `quantity_limit_${doc_name}_${version}_level_${qlConditionLevel + 1}_${Date.now()}.csv`
    let qlConditions = qlConditionLevels[qlConditionLevel]
    const { formattedQlConditions, invalidDate } =
      this.checkDateValidation(qlConditions);
    if (invalidDate) return;
    props.actions.generateQuantityLimitLevelExport({
      drugConditions: formattedQlConditions,
      paConditionLevel: qlConditionLevel,
      filename: filename
    }, downloadCSV)
  }


  _sendForReview = () => {
    const {
      props: { actions },
      state,
    } = this;
    const {
      form,
      form: { doc_id },
    } = state;

    if (!doc_id) {
      const transitionalPortal = {
        header: "Quantity Limit For Approval",
        copy: "Please save Quantity Limit Data first",
      };
      actions.displayTransitionalPortal(transitionalPortal);
    } else {
      const message = "Send Quantity Limit For Review?";

      actions.setModalComponent({
        modalProperties: {
          size: "tiny",
        },
        contents: SaveTierModal({
          form,
          message,
          onSaveClick: actions.rpmSendForReview,
          toggleModal: actions.toggleModal,
        }),
      });
      actions.toggleModal();
    }
  };

  _saveQuantityLimit = () => {
    const {
      props: {
        actions,
        state: { criteriaIds },
      },
      state,
    } = this;
    const { form, qlConditionLevels, qlCriteriaLevels } = state;
    const qualifierIndex =
      qlCriteriaLevels.length &&
      qlCriteriaLevels[0].length &&
      qlCriteriaLevels[0].findIndex(
        (data) => data.ql_qualifier === "Lookback Period"
      );
    const maxQuantityIndex =
      qlCriteriaLevels.length &&
      qlCriteriaLevels[0].length &&
      qlCriteriaLevels[0].findIndex(
        (data) =>
          data.ql_qualifier === "Max Quantity" &&
          data.ql_criteria === "Per Period"
      );
    const maxDaySupplyIndex =
      qlCriteriaLevels.length &&
      qlCriteriaLevels[0].length &&
      qlCriteriaLevels[0].findIndex(
        (data) =>
          data.ql_qualifier === "Max Days of Supply" &&
          data.ql_criteria === "Per Period"
      );
    const maxFillsIndex =
      qlCriteriaLevels.length &&
      qlCriteriaLevels[0].length &&
      qlCriteriaLevels[0].findIndex(
        (data) =>
          data.ql_qualifier === "Max Number of Fills" &&
          data.ql_criteria === "Per Period"
      );
    const maxQuantityValue =
      qlCriteriaLevels.length &&
      qlCriteriaLevels[0].length &&
      qlCriteriaLevels[0][maxQuantityIndex].ql_value;
    const maxFillsValue =
      qlCriteriaLevels.length &&
      qlCriteriaLevels[0].length &&
      maxFillsIndex > -1 &&
      qlCriteriaLevels[0][maxFillsIndex].ql_value;
    const maxDaySupplyValue =
      qlCriteriaLevels.length &&
      qlCriteriaLevels[0].length &&
      qlCriteriaLevels[0][maxDaySupplyIndex].ql_value;
    const hierarchyOrganizationLevel = form?.hierarchy?.[0]?.children || [];
    if (
      !form.doc_name ||
      !form.effective_start_date ||
      hierarchyOrganizationLevel?.length == 0
    ) {
      let message = "";
      if (!form.doc_name) message = "enter a Quantity Limit Name";
      else if (!form.effective_start_date) message = "select a Start Date";
      else if (hierarchyOrganizationLevel?.length == 0)
        message = "assign a Hierarchy";
      const transitionalPortal = {
        header: "Quantity Limit Save Failed",
        copy: `Please ${message} before saving`,
      };
      actions.displayTransitionalPortal(transitionalPortal);
      return;
    }
    if (
      qlCriteriaLevels.length &&
      qlCriteriaLevels[0].length &&
      !qlCriteriaLevels[0][qualifierIndex].ql_value &&
      (maxQuantityValue || maxFillsValue || maxDaySupplyValue)
    ) {
      const transitionalPortal = {
        header: "Quantity Limit Save Failed",
        copy: "Please Add Lookback Period before saving",
      };
      actions.displayTransitionalPortal(transitionalPortal);
      return;
    }
    if (
      qlCriteriaLevels.length &&
      qlCriteriaLevels[0].length &&
      ((maxQuantityValue && maxFillsValue && maxDaySupplyValue) ||
        (maxQuantityValue && maxFillsValue) ||
        (maxFillsValue && maxDaySupplyValue) ||
        (maxQuantityValue && maxDaySupplyValue))
    ) {
      const transitionalPortal = {
        header: "Quantity Limit Save Failed",
        copy: "Please Add One of  Max Quantity, Max Days of Supply and Max Number of Fills for Saving",
      };
      actions.displayTransitionalPortal(transitionalPortal);
      return;
    }
    const formattedQlConditionLevels = [];
    for (const i in qlConditionLevels) {
      const { formattedQlConditions, invalidDate } = this.checkDateValidation(
        qlConditionLevels[i]
      );
      if (invalidDate) return;
      else formattedQlConditionLevels.push(formattedQlConditions);
    }

    const message =
      "You are about to save the Quantity Limit. Do you wish to save?";
    actions.setModalComponent({
      modalProperties: {
        size: "tiny",
      },
      contents: SaveTierModal({
        form,
        conditions: {
          qlConditions: formattedQlConditionLevels,
          criteriaConditions: qlCriteriaLevels,
          criteriaIds,
          umConditions: formattedQlConditionLevels,
        },
        onSaveClick: actions.saveQuantityLimitData,
        toggleModal: actions.toggleModal,
        message,
      }),
    });
    actions.toggleModal();
  };

  _mapValueToOption = (row) => {
    const data = {
      ...row,
    };
    Object.keys(data).forEach((col) => {
      if (Array.isArray(data[col])) {
        data[col] = data[col].map((group, index) => ({
          key: index,
          text: group,
          value: group,
        }));
      }
    });
    return data;
  };

  _extractDrugDemographics = (selectedValues) => {
    const validKeys = ["drug_group", "drug_class"];
    const extractedKeys = validKeys.filter((key) => !!selectedValues[key]);
    const extractedData = extractedKeys.reduce(
      (obj, key) => ({
        ...obj[selectedValues[key]],
      }),
      drugDemographicData
    );
    return Object.keys(extractedData);
  };

  _updateDropdownFromCond(initDropdownValues) {
    const { state } = this.props;
    const { quantityLimitCondData } = state;
    const dropdownValues = [];
    quantityLimitCondData.forEach((cond) => {
      const dropdownRowValues = [];
      cond?.forEach(() => {
        dropdownRowValues.push({ ...initDropdownValues[0][0] });
      });
      dropdownValues.push(dropdownRowValues);
    });
    // const dropdownValues = Array(quantityLimitCondData.length).fill([{ ...initDropdownValues[0][0] }])

    quantityLimitCondData.forEach((quantityLimitCondLevelData, level) => {
      quantityLimitCondLevelData?.forEach((cond, index) => {
        const values = {};
        if (cond.drug_group) {
          values.drug_group = cond.drug_group;
          dropdownValues[level][index].drug_class =
            this._extractDrugDemographics(values);
        }
        if (cond.drug_class) {
          values.drug_class = cond.drug_class;
          dropdownValues[level][index].drug_subclass =
            this._extractDrugDemographics(values);
        }
        if (cond.drug_subclass) {
          values.drug_subclass = cond.drug_subclass;
        }
      });
    });
    return dropdownValues;
  }

  _updateAutoSuggestionFromCond(initAutoSuggestionValues) {
    const { state } = this.props;
    const { quantityLimitCondData } = state;
    const autoSuggestionValues = [];
    quantityLimitCondData.forEach((cond) => {
      const autoSuggestionRowValues = [];
      cond?.forEach(() => {
        autoSuggestionRowValues.push({ ...initAutoSuggestionValues[0][0] });
      });
      autoSuggestionValues.push(autoSuggestionRowValues);
    });
    return autoSuggestionValues;
  }

  _updateState() {
    const { props, state } = this;
    const { dropdownValues, autoSuggestionValues } = state;
    const {
      quantityLimitData,
      quantityLimitCriteriaCondData,
      quantityLimitCondData,
    } = props.state;
    const updatedDropdownValues = this._updateDropdownFromCond(dropdownValues);
    const updatedAutoSuggestionValues =
      this._updateAutoSuggestionFromCond(autoSuggestionValues);
    // const updatedAutoSuggestionValues = Array(quantityLimitCondData.length).fill([{ ...autoSuggestionValues[0][0] }])
    const updatedShowCriteria = Array(quantityLimitCondData.length).fill(true);

    const form = {
      ...state.form,
      doc_id: quantityLimitData.doc_id,
      doc_name: quantityLimitData.doc_name,
      effective_start_date: quantityLimitData.effective_start_date,
      hierarchy: quantityLimitData.hierarchy,
      is_hierarchy_global: quantityLimitData.is_hierarchy_global,
      hideHierarchy: quantityLimitData.hideHierarchy,
      max_no_of_fills: quantityLimitData.max_no_of_fills,
      version: quantityLimitData.version,
      status: quantityLimitData.status,
      version_effective_date: quantityLimitData.version_effective_date,
    };
    if (quantityLimitCondData?.length) {
      quantityLimitCondData.map((outerData) => {
        if (outerData?.length) {
          outerData.map((innerData) => {
            if (
              !innerData.effective_start_date &&
              quantityLimitData.effective_start_date
            )
              innerData.effective_start_date = convertDateToStartTimeObj(
                quantityLimitData.effective_start_date
              );
          });
        }
      });
    }
    this.setState({
      form,
      qlConditionLevels: quantityLimitCondData,
      // qlConditionLevels: quantityLimitCondData.map((level) =>
      //   level.map((row) => {
      //     if (row?.include_in_grid !== true) {
      //       row.include_in_grid = true;
      //     }
      //     return row;
      //   })
      // ),
      qlCriteriaLevels: quantityLimitCriteriaCondData,
      dropdownValues: updatedDropdownValues,
      autoSuggestionValues: updatedAutoSuggestionValues,
      showCriteria: updatedShowCriteria,
    });
  }

  _updateAutoSuggestions() {
    const { props, state } = this;
    const { autoSuggestData } = props.state;
    const { activeQLConditionLevel, rowUpdated, columnUpdated } = state;
    const autoSuggestionValues = [...state.autoSuggestionValues];
    if (columnUpdated === "manufacturer") {
      autoSuggestionValues[activeQLConditionLevel][
        rowUpdated
      ].manufacturer.values = autoSuggestData["mfg"].map((val) => ({
        title: val,
      }));
      autoSuggestionValues[activeQLConditionLevel][
        rowUpdated
      ].manufacturer.loading = false;
      if (this.setAutoSuggestionValues)
        this.setAutoSuggestionValues(autoSuggestionValues);
    } else if (columnUpdated === "drug_name") {
      autoSuggestionValues[activeQLConditionLevel][
        rowUpdated
      ].drug_name.values = autoSuggestData["drug_name"].map((val) => ({
        title: val,
      }));
      autoSuggestionValues[activeQLConditionLevel][
        rowUpdated
      ].drug_name.loading = false;
      if (this.setAutoSuggestionValues)
        this.setAutoSuggestionValues(autoSuggestionValues);
    }
    this.setState(autoSuggestionValues);
  }

  approvalOptionsCallback = () => {
    const { state, props } = this;
    props.actions.getQuantityLimitData({ doc_id: state.form.doc_id });
  };

  render() {
    const { props, state } = this;
    const {
      form,
      form: { hideHierarchy },
      activeQLConditionLevel,
      qlConditionLevels,
      qlCriteriaLevels,
      autoSuggestionValues,
      autoSuggestionMinChars,
      showCriteria,
      cellRenderers,
    } = state;
    let editMode = true;

    if ("state" in props.history.location) {
      editMode =
        props.history.location.state &&
          "editMode" in props.history.location.state
          ? props.history.location.state.editMode
          : true;

      if (parseQueryString(props.history.location.search).editMode === "false")
        editMode = false;
    }
    const dropdownOptions = this._constructDropdownOptions();

    if (hideHierarchy) editMode = false;
    let headerText = editMode ? "Create Quantity Limit" : "View Quantity Limit";
    if (form.doc_id && editMode) headerText = "Edit Quantity Limit";

    return (
      <div id="createQuantityLimit">
        <div className="breadCrumbContainer">
          <BreadCrumb {...this.props} />{" "}
        </div>
        <div className="header">{headerText}</div>
        {
          <ApprovalOptions
            docData={this.state.form}
            actionCallback={this.approvalOptionsCallback}
          />
        }
        <div className="content">
          <QLConditions
            activeQLConditionLevel={activeQLConditionLevel}
            addCriteriaRow={this._addCriteriaRow}
            addNewQLConditionLevel={this._addNewQLConditionLevel}
            addQLCondition={this._addQLCondition}
            autoSuggestionMinChars={autoSuggestionMinChars}
            autoSuggestionResultSelect={this._autoSuggestionResultSelect}
            autoSuggestionValues={autoSuggestionValues}
            allCriteriaIDs={this.props.state.criteriaIds}
            delCriteriaRow={this._delCriteriaRow}
            delQLCondition={this._delQLCondition}
            deleteQLConditionLevel={this._deleteQLConditionLevel}
            doc_id={form.doc_id}
            dropdownOptions={dropdownOptions}
            editMode={editMode}
            form={form}
            setForm={this.setForm}
            exportConditionDrugs={this._download_drugs_to_csv}
            // generateQuantityLimit={this._generateQuantityLimit}
            handleChange={this._handleChange}
            handleCriteriaChange={this._handleCriteriaChange}
            handleQLConditionLevelClick={this._handleQLConditionLevelClick}
            qlConditionLevels={qlConditionLevels}
            qlCriteriaLevels={qlCriteriaLevels}
            saveQuantityLimit={this._saveQuantityLimit}
            onCheckboxChange={this._onCheckboxChange}
            sendForReview={this._sendForReview}
            showCriteria={showCriteria}
            updateFields={this._updateFields}
            cellRenderers={cellRenderers}
          />
          {/* <QLDrugs
            qlConditions={qlConditions}
            editMode={editMode}
            saveQuantityLimit={this._saveQuantityLimit}
          /> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: {
    allQuantityLimitData: state.quantityLimitCreation.allQuantityLimitData,
    autoSuggestData: state.autoSuggest.autoSuggestData,
    autoSuggestSearchTerm: state.autoSuggest.autoSuggestSearchTerm,
    quantityLimitData: state.quantityLimitCreation.quantityLimitData,
    quantityLimitCriteriaCondData:
      state.quantityLimitCreation.quantityLimitCriteriaCondData,
    quantityLimitCondData: state.quantityLimitCreation.quantityLimitCondData,
    criteriaIds: state.quantityLimitCreation.quantityLimitCriteriaIdData || [],
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...AutoSuggestActions,
    ...QuantityLimitCreationActions,
    ...RPMActions,
    ...QualifierActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateQuantityLimit);
