import '../styles.scss'
import React, { useState, useRef } from 'react'
import FliptInput from '../../../../../../components/form/fliptInput'
import FliptDatePicker from '../../../../../../components/form/fliptDatePicker'
import FliptGrid from '../../../../../../components/fliptGrid'
import FliptButton from '../../../../../../components/form/fliptButton'
import FliptCheckbox from '../../../../../../components/form/fliptCheckbox'
import { convertStrToDateObj } from '../../../../../../utils/utilities'
import _ from 'lodash'
import CustomTooltip from './customTooltip.js'

export default function RoleFeatures(props) {
  const {
    createRole, form, functionHierarchy, updateFields, handleCheckboxChange, editMode, submitForm, saveForm, goBack, handleSelectionChange, descriptions
  } = props
  const {
    name, description, effective_start_date, effective_end_date, id,
  } = form

  let gridData = functionHierarchy ? [...functionHierarchy] : []
  const headers = [
    'application',
    'module',
    'screen',
    'subsection',
    'feature',
    'function',
    'read',
    'write',
    'approve',
  ]

  const [ignoreCount, setIgnoreCount] = useState(false)
  function onRowSelected(e) {
    if ((e?.node?.field === 'application') && e?.node?.selected === false) setIgnoreCount(true)
  }

  const ignoreCountRef = useRef()
  ignoreCountRef.current = ignoreCount

  const [prevSelectedRows, setPrevSelectedRows] = useState()
  function onSelectionChanged(e) {
    const selectedRows = e.api.getSelectedRows()

    if (selectedRows && prevSelectedRows && _.isEqual(selectedRows.map(f => ({ ...f, read: '', write: '', approve: '' })), prevSelectedRows.map(f => ({ ...f, read: '', write: '', approve: '' })))) return

    let enableList = []
    let disableList = []
    if (prevSelectedRows) {
      enableList = selectedRows.filter((row) => !prevSelectedRows.find(r => r?.function === row?.function
        && r?.application === row?.application
        && r?.screen === row?.screen
        && r?.feature === row?.feature
        && r?.module === row?.module
        && r?.subsection === row?.subsection
      ))
      disableList = prevSelectedRows.filter((row) => !selectedRows.find(r => r?.function === row?.function
        && r?.application === row?.application
        && r?.screen === row?.screen
        && r?.feature === row?.feature
        && r?.module === row?.module
        && r?.subsection === row?.subsection
      ))
    } else {
      enableList = selectedRows
    }
    handleSelectionChange(enableList, disableList, ignoreCountRef.current)
    setIgnoreCount(false)

    setPrevSelectedRows(selectedRows)
    const expandedRowsData = e.api.getModel().rowsToDisplay.filter(row => row.expanded).map(row => ({
      field: row.field,
      key: row.key,
    }))
    setActiveGroups(expandedRowsData)

    const lastVisibleRowIndex = Math.round(e.api.getVerticalPixelRange().bottom / e.api.getSizesForCurrentTheme().rowHeight)
    setBottomNode(lastVisibleRowIndex - 1)
    return
  }

  const [activeGroups, setActiveGroups] = useState()
  const [bottomNode, setBottomNode] = useState()

  const handleCheckbox = (el, params) => {
    const expandedRowsData = params.api.getModel().rowsToDisplay.filter(row => row.expanded).map(row => ({
      field: row.field,
      key: row.key,
    }))
    setActiveGroups(expandedRowsData)

    handleCheckboxChange(el, params?.data?.function, params?.column?.colId, params?.data?.application, params)
    const lastVisibleRowIndex = params.api.getVerticalPixelRange().bottom / params.api.getSizesForCurrentTheme().rowHeight
    setBottomNode(Math.round(lastVisibleRowIndex) - 1)
    return
  }

  const activeGroupsRef = useRef()
  activeGroupsRef.current = activeGroups

  const bottomNodeRef = useRef()
  bottomNodeRef.current = bottomNode

  const prevSelectedRowsRef = useRef()
  prevSelectedRowsRef.current = prevSelectedRows

  const onComponentStateChanged = (params) => {
    if (bottomNodeRef?.current) params.api.ensureIndexVisible(bottomNodeRef.current)
    if (prevSelectedRowsRef?.current) {
      params.api.forEachNode(function (node) { // TODO: this needs to be sped up since it is too slow
        if (node?.data && prevSelectedRowsRef.current) {
          node.setSelected(!!prevSelectedRowsRef.current.some(r => r.function === node.data.function
            && r?.application === node?.data?.application
            && r?.feature === node?.data?.feature
            && r?.module === node?.data?.module
            && r?.screen === node?.data?.screen
            && r?.subsection === node?.data?.subsection
          ))
        }
      })
    } else {
      params.api.forEachNode(function (node) {
        if (node?.data) {
          const shouldBeSelected = (node.data.read.value && !node.data.write.available) || (node.data.write.value && !node.data.approve.available) || node.data.approve.value
          node.setSelected(shouldBeSelected)
        }
      })
    }
  }

  const cellRendererParams = {
    application: {
      rowGroup: true,
      hide: true,
      tooltipField: 'application',
    },
    module: {
      rowGroup: true,
      hide: true,
      tooltipField: 'module',
    },
    screen: {
      rowGroup: true,
      hide: true,
      tooltipField: 'screen',
    },
    subsection: {
      rowGroup: true,
      hide: true,
      tooltipField: 'subsection',
    },
    feature: {
      rowGroup: true,
      hide: true,
      tooltipField: 'feature',
    },
    function: {
      tooltipField: 'function',
    },
    read: {
      cellRenderer: (params) => {
        return params?.data?.read?.available ? <FliptCheckbox checked={params?.data?.read?.value} onClick={(el) => handleCheckbox(el, params)} onChange={e => { }} disabled={!editMode} /> : ''
      },
    },
    write: {
      cellRenderer: (params) => {
        return params?.data?.write?.available ? <FliptCheckbox checked={params?.data?.write?.value} onClick={(el) => handleCheckbox(el, params)} onChange={e => { }} disabled={!editMode} /> : ''
      },
    },
    approve: {
      cellRenderer: (params) => {
        return params?.data?.approve?.available ? <FliptCheckbox checked={params?.data?.approve?.value} onClick={(el) => handleCheckbox(el, params)} onChange={e => { }} disabled={!editMode} /> : ''
      },
    },
  }

  const isGroupOpenByDefault = (params) => {
    const expandedGroups = activeGroupsRef.current
    if (!!expandedGroups) return expandedGroups.some((row) => (row.field === params.field && row.key === params.key))
    return false
  }

  const getDescription = (descriptions, field, name) => {
    if (!descriptions || !field || !name || !descriptions[field + 's']) return ''
    return descriptions[field + 's'][name] ?? ''
  }

  const autoGroupColumnDef = {
    minWidth: 400,
    cellRendererParams: {
      suppressCount: true,
      checkbox: !!editMode,
    },
    headerTooltip: 'Group',
    tooltipValueGetter: (params) => {
      const count = params.node.allChildrenCount;
      const description = getDescription(params?.colDef?.state?.descriptions, params?.node?.field, params?.value)
      let header = params.value || ''
      if (count) header = header + ' (' + count + ')'
      return header + "_" + description
    },
    state: {
      descriptions: descriptions,
    },
  }

  return (
    <div className="section">
      <section className="role-inputs-container spacing border-line shadow">
        <div className="role-inputs-wrap">
          <div className="role-inputs">
            <span>Role Name</span>
            <FliptInput placeholder="Role Name" name="name" defaultValue={name} onChange={updateFields} readOnly={!editMode} validation="text" required />
          </div>
          <div className="role-inputs">
            <span>Description</span>
            <FliptInput placeholder="Description" name="description" defaultValue={description} onChange={updateFields} readOnly={!editMode} validation="text" required />
          </div>
          <div className="role-inputs">
            <span>Effective Start Date</span>
            <FliptDatePicker className="create-role-start-date" name="effective_start_date" value={convertStrToDateObj(effective_start_date)} onChange={updateFields} readOnly={!editMode} validation="date" required />
          </div>
          <div className="role-inputs">
            <span>Effective End Date</span>
            <FliptDatePicker className="create-role-end-date" name="effective_end_date" value={convertStrToDateObj(effective_end_date)} onChange={updateFields} readOnly={!editMode} />
          </div>
        </div>
        <div className="grid-container spacing border-line shadow">
          <div className="role-features">
            <FliptGrid
              autoGroupColumnDef={autoGroupColumnDef}
              cellRendererParams={cellRendererParams}
              data={gridData}
              frameworkComponents={{ customTooltip: CustomTooltip }}
              groupDisplayType={'multipleColumns'}
              groupSelectsChildren={true}
              headers={headers}
              isGroupOpenByDefault={isGroupOpenByDefault}
              onComponentStateChanged={onComponentStateChanged}
              onRowSelected={onRowSelected}
              onSelectionChanged={onSelectionChanged}
              rowSelection={'multiple'}
              suppressRowClickSelection={true}
              tooltipComponent='customTooltip'
              tooltipShowDelay={0}
            />
          </div>
        </div>
      </section>
      <div className="buttonContainer">
        <FliptButton name="Back" className="secondary" onClick={goBack} />
        {(createRole || editMode) && <FliptButton name="Save" className="primary" onClick={saveForm} />}
        {/* {editMode && <FliptButton name="Submit" className="primary" onClick={submitForm} />} */}
      </div>
    </div>
  )
}
