import React, { useEffect, useState } from 'react'
import '../styles.scss'
import '../../../../../../components/form/formRendererStyles.scss'
import { Tab, Accordion, Icon } from 'semantic-ui-react'
import rowCellInfo from '../data/rowCellInfo'
import FliptGrid from '../../../../../../components/fliptGrid'
import FliptInput from '../../../../../../components/form/fliptInput'
import FliptDatePicker from '../../../../../../components/form/fliptDatePicker'
import FliptButton from '../../../../../../components/form/fliptButton'
import { addRemoveDaysToDate, capitalizeStr, convertStrToDateObj } from '../../../../../../utils/utilities'
import FliptDropdown from '../../../../../../components/form/fliptDropdown'
import {
  NestedRowRenderer,
} from '../../../../../../components/fliptGrid/cellRenderers'
import { DeleteRowRenderer } from '../../../../../../components/fliptGrid/cellRenderers'
import FlattenAssignPharmacies from '../../../assignPharmaciesFlatten'
import FliptHierarchyDropdown from '../../../../../../components/form/fliptHierarchyDropdown'
// import FliptCustomTab from '../../../../../../components/form/fliptCustomTab'

const minTierStartDate = addRemoveDaysToDate(1, false)

const cellRendererParams = {
  remove: {
    cellRenderer: DeleteRowRenderer,
    state: {
      onClick: (rowIndex, paConditionLevel) => { console.log(rowIndex, paConditionLevel) },
    },
    width: 95,
  },
  claim_processor: {
    overrideHeader: 'Contract',
  },
  price_type: {
    overrideHeader: 'Price Type',
  },
  drug_type: {
    overrideHeader: 'Drug Type',
  },
  specialty_drug_list: {
    overrideHeader: 'Specialty Drug Listing',
  },
  min_days_supply: {
    overrideHeader: 'Min Days Supply',
  },
  max_days_supply: {
    overrideHeader: 'Max Days Supply',
  },
  fixed_variable: {
    overrideHeader: 'Fixed/Variable',
  },
  discount: {
    overrideHeader: 'Discount',
  },
  dispensing_fee: {
    overrideHeader: 'Dispensing Fee',
  },
  fee_type: {
    cellRenderer: NestedRowRenderer,
    searchArgs: {
      key: 'fee_value',
      parseData: (e) => Object.keys(e),
    },
    overrideHeader: 'Fee Type',
    autoHeight: true,
  },
  fee_value: {
    cellRenderer: NestedRowRenderer,
    searchArgs: {
      key: 'fee_value',
      parseData: (e) => Object.values(e),
    },
    overrideHeader: 'Fee Value',
    autoHeight: true,
  },
  mac_list_id: {
    overrideHeader: 'MACLISTID',
  },
  price_floor: {
    overrideHeader: 'Floor',
  },
}

export default function TierConditions(props) {
  const {
    form, updateFields, networkTierDetail,
    generateNetwork, editMode, dropdownOptions, networkTierOptions,
    allNetworkTiersData,
    actions, setForm
  } = props
  const {
    name, version, tiers, effective_start_date,
  } = form
  const [activeConditionLevel, setActiveConditionLevel] = useState(0)
  // delete rowCellInfo.action
  Object.keys(rowCellInfo).forEach((key) => { rowCellInfo[key].disabled = !editMode })

  return (
    <div className="section">
      <FliptHierarchyDropdown form={form} setForm={setForm} showHierarchyGlobal={true} />
      <section className="tier-inputs-container spacing border-line shadow">
        <div className="tier-inputs">
          <span>Network Name</span>
          <FliptInput placeholder="Network Name" name="name" value={name} disabled={!editMode} onChange={updateFields} />
        </div>
        <div className="tier-inputs">
          <span>Version</span>
          <FliptInput name="version" value={version} disabled={!editMode} onChange={updateFields} />
        </div>
        <div className="tier-inputs">
          <span>Network Tier</span>
          <FliptDropdown name="tiers" options={networkTierOptions || [""]} value={tiers} onChange={updateFields} multiple />
        </div>
        <div className="tier-inputs">
          <span>Start Date</span>
          <FliptDatePicker className="create-tier-start-date" name="effective_start_date" value={convertStrToDateObj(effective_start_date)} disabled={!editMode} onChange={updateFields} />
        </div>
      </section>
      {
        <TierDetails
          actions={actions}
          tiers={tiers}
          networkTierOptions={allNetworkTiersData}
          networkTierDetail={networkTierDetail}
          dropdownOptions={dropdownOptions}
          editMode={editMode}
        />
      }
      <div className="buttonContainer">
        <FliptButton name="Generate Network" className="primary searchButton" onClick={generateNetwork} />
      </div>
    </div>
  )
}

const TierDetails = (props) => {
  const { actions, tiers, networkTierOptions, networkTierDetail, dropdownOptions, editMode } = props
  const [activeConditionLevel, setActiveConditionLevel] = useState(0)
  const [assignedActiveLevel, setAssignedActiveLevel] = useState({})
  const [networkTier, setNetworkTier] = useState({})
  const panes = ['Rates', 'Assigned Pharmacies']
  const handleConditionLevelClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeConditionLevel === index ? -1 : index
    setActiveConditionLevel(newIndex)
  }
  const handleAssignConditionLevel = (e, titleProps) => {
    const { index } = titleProps
    assignedActiveLevel[activeConditionLevel]
    const newIndex = assignedActiveLevel[activeConditionLevel] === index ? -1 : index
    setAssignedActiveLevel({ ...assignedActiveLevel, [activeConditionLevel]: newIndex })
  }
  useEffect(() => {
    const tier_name = !_.isEmpty(tiers) ? tiers[activeConditionLevel] : null
    const tier = networkTierOptions.find(e => e.doc_name === tier_name)
    if (tier)
      actions.getNetworkTierData({ doc_id: tier?.doc_id, doc_version: tier.doc_version, doc_type: 'global_network_tier' })
  }, [activeConditionLevel, networkTierOptions, tiers])

  useEffect(() => {
    if (!_.isEmpty(networkTierDetail) && !_.isEqual(networkTierDetail, [networkTier])) {
      const tier_name = !_.isEmpty(tiers) ? tiers[activeConditionLevel] : null
      const tier = networkTierDetail.find(e => e.doc_name === tier_name)
      for (let index = 0; index < tier?.pricing_conditions.length; index++) {
        const rate = tier.pricing_conditions[index];
        const pharmacy = tier.pharmacy_conditions[index];
        let doc_name = tier_name
        if (tier?.pricing_conditions.length > 1) {
          let level = index + 1
          doc_name = tier_name + ' ' + `${level}`
        }
        let detail = {
          doc_name: doc_name,
          pricing_conditions: rate,
          pharmacy_conditions: pharmacy
        }
        setNetworkTier(detail)

      }

    }

  }, [networkTierDetail])

  const headers = Object.keys(rowCellInfo)
  if (!editMode && headers.includes('action')) {
    headers.splice(headers.indexOf('action'), 1)
    headers.unshift('action')
  }
  editMode && headers.unshift('remove')
  const _renderSection = (conditions, pharmacyConditions, idx) => (
    <div className="section">
      <div className="tabContebt">
        {
          idx === 0 ? (
            <FliptGrid
              data={conditions}
              headers={headers}
              cellRendererParams={cellRendererParams}
              rowCellInfo={rowCellInfo}
              dropdownOptions={dropdownOptions}
            />
          ) : (
            <div>
              {<FlattenAssignPharmacies
                editMode={false}
                showPharmacyList={false}
                conditionLevels={pharmacyConditions}
                chainCode={[]}
                corpName={[]}
                activeConditionLevel={assignedActiveLevel[activeConditionLevel]}
                handleChange={() => null}
                handleConditionLevelClick={handleAssignConditionLevel}
                addPharmacyCondition={() => null}
                removePharmacyConditionRow={() => null}
                removePharmacyCondition={() => null}
                generateAssignedPharmacy={() => null}
              />}
            </div>
          )
        }
      </div>
    </div>
  )

  return (<Accordion className="pa-condition-levels-accordion" styled>
    {tiers.map((tier, idx) => (
      <div>
        <Accordion.Title
          active={activeConditionLevel === idx}
          index={idx}
          onClick={handleConditionLevelClick}>
          <Icon name="dropdown" />
          {tier}
        </Accordion.Title>
        <Accordion.Content
          active={activeConditionLevel === idx}
        >
          <section className="grid-container spacing border-line shadow">
            {
              <Tab
                panes={panes.map((pane, idx) => ({
                  menuItem: capitalizeStr(pane),
                  render: () => _renderSection(networkTier?.pricing_conditions, networkTier?.pharmacy_conditions, idx),
                }))}
                defaultActiveIndex={0}
              />
            }
          </section>

        </Accordion.Content>
      </div>
    ))
    }
  </Accordion>)
}
